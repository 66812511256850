import {
  Div,
  Flex,
  LoadingSpinner,
  Separator,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Button } from 'src/design_system/components/Button/Button'
import {
  useCheckout,
  useDeleteCheckoutLines,
} from 'src/react_query/queries/hooks'
import { ProductCard } from '../product_card/ProductCard'
import { ShoppingCartButton } from './ShoppingCartButton'
import { useEffect, useSelector, useState } from 'src/hooks'
import { useIsMutating } from '@tanstack/react-query'
import { SuggestedPlanAddon } from './SuggestedPlanAddon'

type PlanAddonType = {
  sku: string
  variantId: string
  plan?: string
}
type ShoppingCartProps = {
  onProceed: () => void
  planAddon?: PlanAddonType
}

export const ShoppingCart = (props: ShoppingCartProps) => {
  const { onProceed, planAddon } = props
  const isMobile = useSelector(state => state.window.isMobile)
  const { data, isFetching } = useCheckout()
  const { mutate } = useDeleteCheckoutLines()
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false)

  // TODO this wipes out the optimistic mutations but adding products has significant lag
  // Check promptings mono repo we added properties to context that can then be accessed
  // via mutation state to control what mutation will cause loading state on a more granular level
  const mutateCount = useIsMutating()
  const isMutating = mutateCount > 0

  useEffect(() => {
    if (data?.lines) {
      setIsShoppingCartOpen(data?.lines.length > 0)
    }
  }, [data?.lines])
  return (
    <Flex position="relative" width="fit-content" justifyContent="flex-end">
      <Div position="absolute">
        <ShoppingCartButton
          cartQuantity={data?.lines.length}
          onClick={() => setIsShoppingCartOpen(!isShoppingCartOpen)}
          isOpen={isShoppingCartOpen}
        />
      </Div>
      {isShoppingCartOpen && (
        <Div position="absolute" top={'50px'} width="fit-content">
          <Flex
            width="100%"
            backgroundColor="#fff"
            flexDirection="column"
            maxWidth="564px"
            maxHeight="700px"
            minWidth="364px"
            zIndex={100}
            style={{
              boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)',
              borderRadius: '.75rem',
              fontFamily: 'Montserrat, san serif',
              overflow: 'auto',
            }}
          >
            {isFetching || isMutating ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  padding: '16px 0px',
                }}
              >
                <LoadingSpinner size="large" />
              </div>
            ) : (
              <Div maxHeight="370px" overflowY="auto">
                {data?.lines.map((product, index) => {
                  const position = index + 1
                  return (
                    <React.Fragment key={product.id}>
                      <ProductCard
                        key={product.id}
                        product={product}
                        onDelete={() => mutate({ linesIds: product.id })}
                      />
                      {position < data.lines.length && (
                        <Div outset={{ horizontal: 'x2_5' }}>
                          <Separator orientation="horizontal" />
                        </Div>
                      )}
                    </React.Fragment>
                  )
                })}
              </Div>
            )}
            <Flex
              backgroundColor="#F9FAFB"
              inset="x3"
              flexDirection="column"
              rowGap="x3"
              style={{
                borderRadius: 'inherit',
              }}
            >
              <Flex
                inset="x2"
                width="100%"
                height="fit-content"
                justifyContent="space-between"
                alignItems="center"
                backgroundColor="#fff"
                style={{
                  borderRadius: '1rem',
                }}
              >
                <Text
                  type="body"
                  content="Total"
                  style={{
                    fontSize: '.875rem',
                    fontWeight: 700,
                    color: '#000',
                  }}
                />
                <Text
                  type="body"
                  content={`$${
                    data?.totalPrice.net.amount.toFixed(2) ?? '0.00'
                  }`}
                  style={{
                    fontSize: '.875rem',
                    fontWeight: 700,
                    color: '#000',
                  }}
                />
              </Flex>
              {!!planAddon && (
                <Flex width="100%" flexWrap="wrap">
                  <Text
                    content="Suggested Addons"
                    type="body"
                    weight="bold"
                    color="primaryHeading"
                  />
                  <Spacer space="x1" />
                  <SuggestedPlanAddon
                    planSku={planAddon.sku}
                    planVariantId={planAddon.variantId}
                  />
                </Flex>
              )}

              <Flex
                flexDirection={isMobile ? 'column' : 'row'}
                columnGap="x2"
                rowGap="x2"
                justifyContent="flex-end"
              >
                <Button
                  title={'Continue Shopping'}
                  border="2px solid #D4D4D8"
                  textSize="1rem"
                  textWeight={500}
                  width={isMobile ? '100%' : undefined}
                  padding=".75rem 1rem"
                  backgroundColor="transparent"
                  textColor="#000"
                  borderRadius=".75rem"
                  onClick={() => setIsShoppingCartOpen(false)}
                />
                <Button
                  title={'Proceed to Checkout'}
                  textColor="#fff"
                  textSize="1rem"
                  width={isMobile ? '100%' : undefined}
                  textWeight={500}
                  padding=".75rem 1rem"
                  backgroundColor="#000"
                  borderRadius=".75rem"
                  onClick={onProceed}
                />
              </Flex>
            </Flex>
          </Flex>
        </Div>
      )}
    </Flex>
  )
}
