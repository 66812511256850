import React, { useRef } from 'react'
// @src imports
import { PaymentInfo, PurchaseTypes } from 'src/payments/containers/Payment'
import { Icon, Transition } from 'src/chrome'
import {
  formatAmount,
  getPointsExpenseCreditsNeeded,
} from 'src/helpers/payments'
import { willUsePoints } from 'src/helpers'
// relative imports
import { PaymentTab } from '..'
import { creditCardBrand } from '../CardLogo/CreditCardLogo'

import styles from './paymentMethod.module.scss'
import { FlexRow } from 'src/styled'
import { Checkbox, Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import useSubmitCard from 'src/hooks/useSubmitCard'
import CreditCardForm from '../CreditCardForm/CreditCardForm'
import { useState } from 'src/hooks'
import { Button } from 'src/design_system/molecules/button/Button'
import {
  AccountFragment,
  AddressFragment,
  Amount,
  Currency,
} from 'src/graphql/generated/graphql'
import { useShouldVerifyEmail } from 'src/react_query'

interface PaymentMethodProps {
  account: AccountFragment
  isMixedOrder: boolean
  credits?: Amount
  shouldNotUseExpense?: boolean
  canUseCredits: boolean
  isUsingCredits: boolean
  setIsUsingCredits: (value: boolean) => void
  paymentInfo: PaymentInfo
  onError: (error: string | Error | undefined) => void
  isBulk?: boolean
  returnAddress?: AddressFragment
}

const PaymentMethod: React.FC<PaymentMethodProps> = props => {
  const {
    account,
    account: { stripeSource, points, expense },
    credits: referralCredits,
    isMixedOrder,
    isUsingCredits,
    shouldNotUseExpense = false,
    canUseCredits,
    paymentInfo,
    setIsUsingCredits,
    onError,
    isBulk,
    returnAddress,
  } = props

  const submitCard = useSubmitCard()
  const submitCardRef = useRef<HTMLButtonElement>(null)

  const { data: shouldVerifyEmail } = useShouldVerifyEmail()

  const credits: Amount =
    canUseCredits && referralCredits
      ? referralCredits
      : {
          amount: 0,
          currency: Currency.Unavailable,
          description: 'Unavailable',
          __typename: 'Amount',
        }

  const isUsingPoints =
    paymentInfo.purchaseType === PurchaseTypes.ORDER &&
    willUsePoints(paymentInfo.items[0])

  const neededCurrency =
    paymentInfo.purchaseType === PurchaseTypes.ORDER
      ? getPointsExpenseCreditsNeeded(
          paymentInfo.items[0],
          account,
          credits,
          canUseCredits && isUsingCredits,
        )
      : null

  const isPurchasingPlan = paymentInfo.purchaseType === PurchaseTypes.PLAN

  const isUsingExpense =
    !shouldNotUseExpense &&
    (points.amount === 0 || !!isBulk) &&
    expense.amount > 0

  const [isFormComplete, setIsFormComplete] = useState(false)
  const addCard = () => {
    submitCardRef.current?.click()
  }

  return (
    <div className={styles.paymentMethodWrapper}>
      <div className={styles.iconBar}>
        {(isUsingPoints || isUsingExpense || isUsingCredits) &&
        !isPurchasingPlan ? (
          <div className={styles.bar}>
            {isMixedOrder && (
              <div style={{ marginBottom: 35 }}>
                <div css={{ display: 'flex', marginBottom: '30px' }}>
                  <PaymentTab paymentType={'points'} />
                  <span style={{ fontSize: 30, margin: '0 20px' }}>+</span>
                  {stripeSource?.__typename === 'CreditCard' ? (
                    <PaymentTab
                      paymentType={'card'}
                      brand={creditCardBrand(stripeSource.brand)}
                    />
                  ) : (
                    <PaymentTab paymentType={'card'} />
                  )}
                </div>
                {!stripeSource && (
                  <>
                    <CreditCardForm
                      returnAddress={returnAddress}
                      onSuccess={submitCard.submitCard}
                      onError={error => {
                        submitCard.onError(error)
                        onError(error)
                      }}
                      isCCFormComplete={isComplete => {
                        setIsFormComplete(isComplete)
                      }}
                      isLoading={submitCard.isAddingCard}
                      shouldValidateEmail={shouldVerifyEmail}
                    >
                      <Button
                        fill="#f5f6f7"
                        gap="x_5"
                        padding={'12px 16px'}
                        borderRadius="small"
                        onClick={addCard}
                        isDisabled={!isFormComplete}
                        title={{ content: 'Add Card', fontSize: '12px' }}
                      />
                    </CreditCardForm>
                  </>
                )}
              </div>
            )}
            {!isMixedOrder && <PaymentTab paymentType={'points'} />}
            {/*******************************************
              Your current points/expense/credits totals:
            *********************************************/}
            <div className={styles.sectionTitle}>
              <Text type="body">
                Your Current Point &amp; Expense &amp; Credits Totals
              </Text>
            </div>
            {isUsingPoints && (
              <Div display="flex">
                <Text type="caption" weight="bold">
                  Current Points:
                </Text>
                <Spacer space="x1" orientation="horizontal" />
                <Icon icon={'TAG'} size={18} color={'#F36CBF'} />
                <Spacer space="x1" orientation="horizontal" />
                <Text type="body" weight="bold">
                  {points.amount}
                </Text>
              </Div>
            )}
            {isUsingExpense && (
              <Div display="flex">
                <Text type="caption" weight="bold">
                  Current Expense:{' '}
                </Text>
                <Spacer space="x1" orientation="horizontal" />
                <Icon
                  icon={'MONEY'}
                  viewBox={'0 0 32 32'}
                  size={18}
                  color={'#F36CBF'}
                />
                <Spacer space="x1" orientation="horizontal" />
                <Text type="caption" weight="bold">
                  {formatAmount(expense.amount)}
                </Text>
              </Div>
            )}
            {isUsingCredits && (
              <div>
                <Div display="flex">
                  <Text type="caption" weight="bold">
                    Current Credits:{' '}
                  </Text>
                  <Spacer space="x1" orientation="horizontal" />
                  <Icon
                    icon={'MONEY'}
                    viewBox={'0 0 32 32'}
                    size={18}
                    color={'#F36CBF'}
                  />
                  <Spacer space="x1" orientation="horizontal" />
                  <Text type="body" weight="bold">
                    {formatAmount(credits.amount)}
                  </Text>
                </Div>
                <FlexRow>
                  <Checkbox
                    onChange={setIsUsingCredits}
                    isChecked={isUsingCredits}
                    label="Use Credits"
                    type="success"
                  />
                </FlexRow>
              </div>
            )}
            {isMixedOrder &&
              neededCurrency &&
              (neededCurrency.points > 0 ||
                neededCurrency.expense > 0 ||
                neededCurrency.credits > 0) && (
                <div className={styles.isMixedOrder}>
                  <div className={styles.sectionTitle}>
                    <Text type="body">
                      Warning: Not Enough Points or Expense or Credits
                    </Text>
                  </div>
                  <Spacer space="x4" />
                  <Text type="caption" color="primaryBrand">
                    You do not have enough points and/or expense to complete
                    this order. We will need to charge your default payment
                    method to purchase the remaining points and/or expense.
                  </Text>
                  <Spacer space="x4" />
                  <div className={styles.sectionTitle}>
                    <Text type="body">Default Payment</Text>
                  </div>
                  <Spacer space="x4" />
                  <Text type="caption">
                    {stripeSource?.__typename === 'CreditCard'
                      ? `Card Type: ${stripeSource.brand}`
                      : stripeSource?.__typename === 'BankAccount'
                      ? stripeSource.bankName
                      : 'No Default Payment Selected'}
                  </Text>
                  <Text type="caption">
                    {stripeSource?.__typename === 'CreditCard'
                      ? `**** **** **** ${stripeSource.last4}`
                      : stripeSource?.__typename === 'BankAccount'
                      ? `Account: ...${stripeSource.last4}`
                      : ''}
                  </Text>
                </div>
              )}
          </div>
        ) : stripeSource?.__typename === 'CreditCard' ? (
          <div className={styles.bar}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <PaymentTab
                paymentType={'card'}
                brand={creditCardBrand(stripeSource.brand)}
              />
            </div>
            <div className={styles.sectionTitle}>
              <Text type="body">Credit Card Information</Text>
            </div>
            <Spacer space="x2" />
            <Text type="caption">
              Card Number: **** **** **** {stripeSource.last4}
            </Text>
            <Text type="caption">
              Exp: {stripeSource.expMonth}/{stripeSource.expYear}
            </Text>
          </div>
        ) : stripeSource?.__typename === 'BankAccount' && !isPurchasingPlan ? (
          <div className={styles.bar}>
            <PaymentTab paymentType={'bank'} />
            <div className={styles.sectionTitle}>
              <Text type="body">Bank Information: {stripeSource.bankName}</Text>
            </div>
            <Spacer space="x4" />
            <Text type="caption">
              Account Holder: {stripeSource.accountHolderName}
            </Text>
            <Text type="caption">
              Account Number: {`...${stripeSource.last4}`}
            </Text>
          </div>
        ) : (
          <>
            <CreditCardForm
              returnAddress={returnAddress}
              onSuccess={submitCard.submitCard}
              onError={error => {
                submitCard.onError(error)
                onError(error)
              }}
              isCCFormComplete={isComplete => {
                setIsFormComplete(isComplete)
              }}
              isLoading={submitCard.isAddingCard}
            >
              <Button
                fill="#f5f6f7"
                gap="x_5"
                padding={'12px 16px'}
                borderRadius="small"
                onClick={addCard}
                isDisabled={!isFormComplete}
                title={{ content: 'Add Card', fontSize: '12px' }}
              />
            </CreditCardForm>
          </>
        )}
        {!isBulk && canUseCredits && !isUsingCredits && !isPurchasingPlan && (
          <div>
            <div className={styles.sectionTitle}>
              <Text type="body">Your Current Credits Total</Text>
            </div>
            <Div display="flex">
              <Text type="caption" weight="bold">
                Current Credits:{' '}
              </Text>
              <Spacer space="x1" orientation="horizontal" />
              <Icon
                icon={'MONEY'}
                viewBox={'0 0 32 32'}
                size={18}
                color={'#F36CBF'}
              />
              <Spacer space="x1" orientation="horizontal" />
              <Text type="body" weight="bold">
                {formatAmount(credits.amount)}
              </Text>
            </Div>
            <FlexRow>
              <Checkbox
                onChange={setIsUsingCredits}
                isChecked={isUsingCredits}
                label="Use Credits"
                type="success"
              />
            </FlexRow>
          </div>
        )}
        <button
          form="addCreditCardForm"
          type="submit"
          ref={submitCardRef}
          style={{ display: 'none' }}
        />
        {submitCard.isAddingCard && <Transition message={'Adding card...'} />}
        {submitCard.addCCardError !== undefined && (
          <Text type="caption" color="danger">
            {submitCard.addCCardError}
          </Text>
        )}
      </div>
    </div>
  )
}

export default PaymentMethod
