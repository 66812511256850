import { Flex, Text } from '@sendoutcards/quantum-design-ui'

import React, { FC } from 'react'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { useCheckout } from 'src/react_query'

type SupportedPromoType = 'FreeCard' | 'CardSampler'
type PromoPlanTileType = {
  type: SupportedPromoType
  item?: { variantId: string; sku: string }
  title: string
  description?: string
  caption?: string
  onClick?: (id?: string) => void
}

export const PromoPlanTile: FC<PromoPlanTileType> = ({
  type,
  item,
  title,
  description,
  caption,
  onClick,
}) => {
  const { addToCart, checkIsInCart } = useHandleProductActions(item?.sku)
  const { data: checkout } = useCheckout()
  const isOnCart = checkIsInCart(checkout, item?.sku)
  return (
    <Flex
      position="relative"
      flexDirection="column"
      backgroundColor="#fff"
      width="300px"
      borderRadius={'medium'}
      style={{
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
      }}
      inset="16px"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      overflow="hidden"
    >
      <Flex
        backgroundColor="#fff"
        borderRadius="circle"
        boxShadow="light"
        height="fit-content"
        inset={{ horizontal: 'x1_5' }}
        outset={{ bottom: 'x1' }}
        minWidth="fit-content"
      >
        <Text
          type="body"
          alignment="center"
          content={type === 'FreeCard' ? 'Free Card' : 'Card Sampler'}
          lineHeight={2}
          whiteSpace="nowrap"
          style={{
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#5ACDDB',
          }}
        />
      </Flex>

      <Flex
        flexDirection="column"
        width="100%"
        rowGap="x3"
        columnGap="x3"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Flex
          flexDirection="column"
          width="100%"
          justifyContent="center"
          rowGap="6px"
        >
          <Flex flexDirection="column" width="100%" justifyContent="center">
            <Text
              content={title}
              type="title"
              color="primaryBody"
              alignment="center"
              weight="semiBold"
              style={{
                lineHeight: 1.2,
                fontWeight: 500,
                fontSize: '26px',
              }}
            />
          </Flex>
          <Flex flexDirection="column" width="100%" justifyContent="center">
            <Text
              content={description}
              type="caption"
              alignment="center"
              style={{ fontSize: '12px', lineHeight: 1.2, fontWeight: 600 }}
            />
          </Flex>
          <Flex flexDirection="column" width="100%" justifyContent="center">
            <Text
              content={caption}
              type="caption"
              alignment="center"
              style={{
                fontSize: '10px',
                fontWeight: 600,
                color: '#333',
                lineHeight: 1.2,
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      {type === 'FreeCard' ? (
        <>
          <Flex
            position="relative"
            bottom="-26px"
            transform={'rotate(-6deg)'}
            borderRadius="6px"
            width="60%"
            height="100px"
            backgroundImage="https://www.sendoutcards.com/thumbnail/Sd4KK5Qy_K8ms572AqSysfbhxtQ=/fit-in/700x0/system_images/2022/2/18/c7105cf9-fd46-46cb-a1aa-e2895150ce1a.png"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          />
        </>
      ) : (
        <>
          <Flex
            position="relative"
            bottom="-16px"
            borderRadius="default"
            width="100%"
            height="100px"
            backgroundImage="https://www.sendoutcards.com/media/images/image.original.png"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          />
        </>
      )}
      <Flex
        position="absolute"
        justifyContent="center"
        alignItems="center"
        zIndex={1}
        cursor="pointer"
        bottom="24px"
      >
        <Flex
          position="absolute"
          borderRadius="8px"
          style={{
            backdropFilter: 'blur(24px)',
            marginBottom: '12px',
            WebkitBackdropFilter: 'blur(24px)',
          }}
          flexDirection="column"
          backgroundColor="rgba(255, 255, 255, 0.10)"
          justifyContent="center"
          width="188px"
          height="44px"
          inset="12px 40px"
          alignItems="center"
          onClick={() =>
            type === 'CardSampler' && item
              ? addToCart(item?.variantId)
              : onClick
              ? onClick()
              : undefined
          }
        >
          <Text
            style={{ fontSize: '12px', fontWeight: 700 }}
            content={
              type === 'FreeCard'
                ? 'Send a Free Card'
                : isOnCart
                ? 'Added to Cart'
                : 'For 3 Cards'
            }
            lineHeight={1}
            type={'title'}
          />
        </Flex>
      </Flex>
      {type === 'FreeCard' ? (
        <>
          <Flex
            position="absolute"
            bottom="0"
            transform={'rotate(-6deg)'}
            borderRadius="6px"
            width="60%"
            height="100px"
            backgroundImage="https://www.sendoutcards.com/thumbnail/Sd4KK5Qy_K8ms572AqSysfbhxtQ=/fit-in/700x0/system_images/2022/2/18/c7105cf9-fd46-46cb-a1aa-e2895150ce1a.png"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          />
        </>
      ) : (
        <>
          <Flex
            position="absolute"
            bottom="-16px"
            borderRadius="default"
            width="100%"
            height="120px"
            backgroundImage="https://www.sendoutcards.com/soc-media/images/image.original.png"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          />
        </>
      )}
    </Flex>
  )
}
