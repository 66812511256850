import React from 'react'
import { useSelector } from 'src/hooks'
import { Div } from '@sendoutcards/quantum-design-ui'

type CardPreviewType = {
  previewUrl: string
  isHorizontal: boolean
}

type GreetingCardRibbonProps = {
  cards: CardPreviewType[]
  cardWidth: string
  cardHeight: string
  shouldDisableTransforms?: boolean
  shouldReverseArch?: boolean
}

export const GreetingCardRibbon: React.FC<GreetingCardRibbonProps> = ({
  cards,
  cardWidth,
  cardHeight,
  shouldDisableTransforms,
  shouldReverseArch,
}) => {
  const isTablet = useSelector(state => state.window.width < 950)
  const isMobile = useSelector(state => state.window.width <= 520)

  const containerWidth = isMobile ? '720px' : isTablet ? '920px' : '1220px'
  const cardSpacing = isMobile ? 20 : isTablet ? 30 : 40

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: containerWidth,
        position: 'relative',
        height: cardHeight,
      }}
    >
      {cards.map((card, index) => {
        const isEven = index % 2 === 0
        const height = card.isHorizontal ? cardWidth : cardHeight
        const imageHeight = isMobile ? '60%' : isTablet ? '80%' : '100%'
        const aspectRatio = card.isHorizontal ? '1.4' : '0.7'
        const midpoint = Math.floor(cards.length / 2)
        const distanceFromMidpoint = Math.abs(index - midpoint)
        const translateY = `${distanceFromMidpoint * cardSpacing}px`

        const adjustedTranslateY = shouldReverseArch
          ? `-${translateY}`
          : translateY
        const rotationDeg = isEven ? '-6deg' : '6deg'
        const leftPosition = `${(100 / cards.length) * index}%`

        return (
          <Div
            key={index}
            width={`${100 / cards.length}%`}
            height={height}
            transform={
              shouldDisableTransforms ? 'initial' : `rotate(${rotationDeg})`
            }
            position="absolute"
            top={adjustedTranslateY}
            left={leftPosition}
            display="flex"
            justifyContent="center"
          >
            <div style={{ height: imageHeight, aspectRatio }}>
              <Div
                backgroundImage={card.previewUrl}
                width="100%"
                height="100%"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
              />
            </div>
          </Div>
        )
      })}
    </div>
  )
}
