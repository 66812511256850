import React from 'react'
// import Collapsible from 'react-collapsible'

// @src imports
import { EditOrderCardRoute } from 'src/orders/routes/EditOrderCardRoute'
import { useRevertBulkOrder } from 'src/react_query'

// relative imports
import ReturnAddress from '../ReturnAddress/ReturnAddress'
import SendDelayForm from '../SendDelayForm/SendDelayForm'
import { OrderWithPartialLines } from '../../../redux/reducers/orders'
import { Recipients } from 'src/contacts/components'

import {
  useAccount,
  useActions,
  useEffect,
  useRef,
  useSelector,
  useState,
} from 'src/hooks'
import BulkOrder from '../BulkOrder/BulkOrder'
import NewEditorOptionModal from 'src/chrome/NewEditorOptionModal/NewEditorOptionModal'
import { ORDER_DETAIL_RETURN_ADDRESS_ID } from 'src/orders/constants'
import { BasicCreateAccountForm } from 'src/gift_store/components/BasicCreateAccountForm'
import { OrderDetailsSection } from './OrderDetailsSection'
import { OrdersSection } from '../OrdersSection/OrdersSection'
import { ConfirmDialog, Flex } from '@sendoutcards/quantum-design-ui'
import { OrderApiType } from '../../api'
import { OrderPersonalDetails } from '../OrderPersonalDetails/OrderPersonalDetails'
import OrderPaymentMethod, {
  PaymentFormDisplay,
} from '../OrderPaymentMethod/OrderPaymentMethod'
import OrderWarningBanner from '../OrderWarningBanner/OrderWarningBanner'
import { isOrderFree } from 'src/helpers/payments'
import useHeartfeltWarningBannerInfo from 'src/hooks/useHeartfeltWarningBannerInfo'
import { CardFragment, CardPaperType } from 'src/graphql/generated/graphql'
interface OrderDetailProps {
  shouldBlink: boolean
  order: OrderWithPartialLines
  shouldBlinkAddRecipients?: boolean
  openImportManager: () => void
  saveAs?: () => void
  isSaving?: boolean
  api: OrderApiType
  shouldShowEditorChoiceModal: boolean
  paymentFormDisplay: PaymentFormDisplay
}

const OrderDetail: React.FC<OrderDetailProps> = props => {
  const {
    shouldBlink,
    order,
    shouldBlinkAddRecipients,
    openImportManager,
    saveAs,
    isSaving,
    api,
    shouldShowEditorChoiceModal,
    paymentFormDisplay,
  } = props

  // Redux State
  const { isCardAdded, activeLine } = useSelector(state => state.orders)

  // Redux Actions
  const actions = useActions()

  const account = useAccount()

  const revertOrder = useRevertBulkOrder()

  const {
    shouldDisplayWarningBanner,
    warningBannerCap,
    setShouldDisplayWarningBanner,
  } = useHeartfeltWarningBannerInfo()

  // State
  const mutableModifyingRef = useRef(false)
  const card =
    (activeLine && activeLine.card) || (order.lines[0] && order.lines[0].card)

  const [shouldShowBulkRevertModal, setShouldShowBulkRevertModal] = useState(
    false,
  )

  const isSparse = !account.username

  const {
    handleUpdatePaperType,
    handleRemoveCardFromLine,
    handleAddGiftToOrderLine,
    handleRemoveGiftFromOrderLine,
    handleAddCardToOrderLine,
    handleConvertCardAndUpdateOrder,
    handleReplaceCard,
    toggleSendDelayModal,
    isSendDelayFormOpen,
    toggleAddressBlinker,
    handleAddCard: onAddCard,
    handleOpenDrawer: openAddressBook,
    canShowNewCardEditor,
    saveReturnAddress,
    canShowBulkOrderOptions,
    setShouldShowEditorChoiceModal,
  } = api

  const shouldShowEditorOptionModal =
    (activeLine || order.lines[0]) &&
    canShowNewCardEditor &&
    shouldShowEditorChoiceModal

  const [isAccCreationSuccessful, setIsAccCreationSuccessful] = useState(false)

  const onCloseWarningBanner = () => {
    setShouldDisplayWarningBanner(false)
  }

  // Callbacks
  const modifyOrder = (callback: () => void) => {
    mutableModifyingRef.current = true
    // WARNING: This used to be called asynchronously in the callback of
    // setState, now that we're using `useState` we don't have
    // this functionality anymore.
    callback()
  }

  const handleEditCardOnCurrentEditor = (card: CardFragment) =>
    modifyOrder(() => {
      actions.openOrder(order.id, EditOrderCardRoute(card.id))
    })

  const handleEditCard = (card: CardFragment) => {
    if (canShowNewCardEditor && !card.isNewEditorCard) {
      setShouldShowEditorChoiceModal(true)
    } else {
      handleEditCardOnCurrentEditor(card)
    }
  }

  const handleSuccessfulAccCreation = () => {
    setIsAccCreationSuccessful(true)
  }

  const handleAddNewCardEligibility = () => {
    if (order && order.isBulk) {
      setShouldShowBulkRevertModal(true)
    } else {
      onAddCard()
    }
  }

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!mutableModifyingRef.current) {
        actions.clearOrder()
      }
    }
  }, [mutableModifyingRef, actions])

  return (
    <Flex
      id="mainContainer"
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor="foreground"
      style={{
        marginTop: isSparse ? '65px' : 'unset',
      }}
    >
      <Flex
        id="VStackWrapper"
        width="100%"
        maxWidth="1180px"
        justifyContent="center"
        alignItems="center"
        inset={{ top: 'x2', bottom: '140px', horizontal: 'x1' }}
        flexDirection="column"
        rowGap="x3"
        style={{ height: isSparse ? 'calc(100% - 65px)' : 'unset' }}
      >
        {shouldDisplayWarningBanner && (
          <OrderWarningBanner
            cap={warningBannerCap}
            onClose={onCloseWarningBanner}
          />
        )}
        {canShowBulkOrderOptions && isCardAdded && (
          <BulkOrder
            api={api}
            order={order}
            onRemoveCard={handleRemoveCardFromLine}
            onChangeCard={handleEditCard}
          />
        )}
        {!order.isBulk && (
          <OrdersSection
            api={api}
            onAddAnotherCard={handleAddNewCardEligibility}
            cardItem={{
              onUpdatePaperType: (
                paperType: CardPaperType,
                card: CardFragment,
              ) => handleUpdatePaperType(card, paperType),
              onToggleSendDelay: toggleSendDelayModal,
              onRemoveCard: handleRemoveCardFromLine,
              onReplaceCard: handleReplaceCard,
            }}
            giftItem={{
              onAddGift: (line, index) =>
                handleAddGiftToOrderLine(line, index, modifyOrder),
              onChangeGift: (line, index) =>
                handleAddGiftToOrderLine(line, index, modifyOrder),
              onRemoveGift: line =>
                handleRemoveGiftFromOrderLine(line, modifyOrder),
            }}
            onAddCard={(line, index) =>
              handleAddCardToOrderLine(line, index, modifyOrder)
            }
            onRemoveCard={handleRemoveCardFromLine}
            onRemoveLine={actions.removeLine}
            onChangeCard={handleEditCard}
            saveAs={saveAs}
            isSaving={isSaving}
            inset="x2"
          />
        )}
        {card && shouldShowEditorOptionModal && (
          <NewEditorOptionModal
            title={
              'Try it out! Would you like to edit\nyour card in the new editor'
            }
            description={
              'Since this card was created in the current card\neditor we can copy it and allow you to try editing it\nin the new editor. Would you like to try?'
            }
            tryNewEditor={{
              title: 'Copy Card to New Editor',
              onClick: (card, prevCardId) => {
                handleConvertCardAndUpdateOrder?.(card, prevCardId)
                setShouldShowEditorChoiceModal(false)
              },
            }}
            useCurrentEditor={{
              title: 'Edit in Current Editor',
              onClick: () => {
                handleEditCardOnCurrentEditor(card)
                setShouldShowEditorChoiceModal(false)
              },
            }}
            isOpen={shouldShowEditorChoiceModal}
            onClose={() => {
              setShouldShowEditorChoiceModal(false)
            }}
            cardId={card.id}
          />
        )}
        {!order.isBulk && (
          <OrderDetailsSection inset="x2" borderRadius="28px">
            <Recipients
              order={order}
              shouldBlinkIfEmpty={shouldBlinkAddRecipients}
              openAddressBook={openAddressBook}
              openImportManager={openImportManager}
            />
          </OrderDetailsSection>
        )}
        {!order.isBulk && (
          <OrderDetailsSection inset="x2" borderRadius="28px">
            <Flex
              width="100%"
              backgroundColor="background"
              borderRadius={'40px'}
            >
              <OrderPersonalDetails
                returnAddress={
                  <ReturnAddress
                    heading={{
                      title: 'Return Address',
                      subtitle: !order.returnAddress
                        ? 'Enter the return address you would like this order to be sent from.'
                        : '',
                    }}
                    toggleAddressBlinker={toggleAddressBlinker}
                    showRequired={shouldBlink}
                    order={order}
                    onSave={saveReturnAddress}
                    id={ORDER_DETAIL_RETURN_ADDRESS_ID}
                  />
                }
                isAccCreationSuccessful={isAccCreationSuccessful}
                basicCreateAccountForm={
                  !account.username && (
                    <BasicCreateAccountForm
                      shouldNotAutoComplete={true}
                      handleSuccessfulAccCreation={handleSuccessfulAccCreation}
                    />
                  )
                }
              />
            </Flex>
          </OrderDetailsSection>
        )}
        {!isOrderFree(order) && (
          <OrderDetailsSection inset="x2" borderRadius="28px">
            <OrderPaymentMethod
              returnAddress={order.returnAddress ?? undefined}
              paymentFormDisplay={paymentFormDisplay}
            />
          </OrderDetailsSection>
        )}
      </Flex>
      {activeLine && isSendDelayFormOpen && (
        <SendDelayForm
          isOpen={isSendDelayFormOpen}
          close={toggleSendDelayModal}
          onSubmit={actions.updateSendDelay}
          isMultiLine={order.lines.length > 1}
          sendDelay={activeLine.sendDelay}
        />
      )}
      {shouldShowBulkRevertModal && (
        <ConfirmDialog
          title={'Change your order?'}
          description={
            'Bulk ordering is only available for one card at a time. Would you like to convert your bulk order to something else?'
          }
          isOpen={shouldShowBulkRevertModal}
          accept={{
            title: 'Yes',
            onClick: () => {
              revertOrder.mutateAsync({ id: order.id })
              setShouldShowBulkRevertModal(false)
              onAddCard()
            },
          }}
          decline={{
            title: 'No',
            onClick: () => setShouldShowBulkRevertModal(false),
          }}
        />
      )}
    </Flex>
  )
}

export default OrderDetail
