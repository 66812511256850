import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { useSelector } from 'src/hooks'

type ContentBlockProps = {
  caption?: string
  title: { title: string; bolded?: string }
  body: string[]
  color?: string
  maxWidth?: string
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  caption,
  title,
  body,
  color = '#6B7280',
  maxWidth = '1050px',
}) => {
  const isTablet = useSelector(state => state.window.width <= 1050)
  const isMobile = useSelector(state => state.window.width <= 626)
  const isMobileTitleCap = title.title.length >= 20

  return (
    <Flex
      maxWidth={maxWidth}
      position="relative"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowGap="x1"
    >
      {caption && (
        <Flex justifyContent="center" alignItems="baseline">
          <Text
            type="title"
            weight="semiBold"
            alignment="center"
            style={{ color }}
          >
            &middot;
          </Text>
          <Text
            type="subtitle"
            weight="regular"
            content={caption}
            style={{ fontSize: isMobile ? 18 : 24, color }}
            alignment="center"
            outset={{ horizontal: 'x1' }}
          />
          <Text
            type="title"
            weight="semiBold"
            alignment="center"
            style={{ color }}
          >
            &middot;
          </Text>
        </Flex>
      )}

      {title && (
        <Flex flexWrap="wrap" justifyContent={isTablet ? 'center' : undefined}>
          <Text
            type="title"
            weight="regular"
            content={title.title}
            alignment="center"
            style={{
              color,
              fontSize: isMobileTitleCap
                ? 28
                : isMobile
                ? 30
                : isTablet
                ? 36
                : 40,
              whiteSpace: 'nowrap',
            }}
          />
          {title.bolded && (
            <Text
              type="title"
              weight="extraBold"
              content={title.bolded}
              alignment="center"
              style={{
                color,
                fontSize: isMobileTitleCap
                  ? 26
                  : isMobile
                  ? 28
                  : isTablet
                  ? 34
                  : 38,
                marginLeft: '10px',
              }}
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}
