import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Tile } from './components/Tile/Tile'
import {
  useActions,
  usePlansSubscriptions,
  useSelector,
  useState,
} from 'src/hooks'
import { StorytellingModal } from '../../../upsale/components/Storytelling/StorytellingModal'
import HeartFeltStepOne from '../../../upsale/components/Storytelling/SVGs/HeartFeltStepOne'
import HeartFeltStepTwo from '../../../upsale/components/Storytelling/SVGs/HeartFeltStepTwo'
import HeartFeltStepThree from '../../../upsale/components/Storytelling/SVGs/HeartFeltStepThree'
import { getPlanPillDescription } from './getPlanPill'
import { PlanDivider } from './components/PlanDivider'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
import { useCheckout } from 'src/react_query/queries/hooks'
import { usePlanManagement } from 'src/saleor/utils/usePlanManagement'
import { PlanFragment } from 'src/graphql/generated/graphql'
import getRecurrence from 'src/helpers/getRecurrence'

export type PlanDetail = {
  detail: string
  innerDetails?: PlanDetail[]
}

export type PlanIdType = {
  id: string
}

export type PlanTilesProps = {
  planIds: PlanIdType[]
  layout?: 'scroll' | 'grid'
  onSelect: (plan: PlanFragment, isKeepButton?: boolean) => void
  onProceedToCheckout: () => void
  title?: string
  description?: string
  featuredPlanId?: string
  initialMobileElement?: number
}

// TODO: Make stories a prop using planId to launch with correct plan
const heartfeltStorySteps = [
  {
    children: <HeartFeltStepOne />,
    content: [
      'In a world where genuine connections often get lost in the rush of everyday life, SendOutCards offers you the chance to be a beacon of kindness with our Heartfelt Subscription for just $37.',
      'Imagine touching lives, one heartfelt card at a time, effortlessly and affordably.',
    ],
  },
  {
    children: <HeartFeltStepTwo />,
    content: [
      'With the Heartfelt Subscription, you can send out 20 personalized cards in 30 days, brightening birthdays, saying thank you, offering encouragement, or simply sending warm greetings. Your subscription doesn`t just make a difference – it creates a ripple effect of positivity.',
    ],
  },
  {
    children: <HeartFeltStepThree />,
    content: [
      'Don`t miss your chance to make the world a better place, one heartfelt prompting card at a time. Get the Heartfelt Subscription today, be a difference-maker, and let your heart shine through your cards. Subscribe now and start the wave of kindness! 💌',
    ],
  },
]
const unlimitedHeartFeltStorySteps = [
  {
    children: <HeartFeltStepOne />,
    content: [
      "In a world where connections matter more than ever, there's a subscription that lets you spread kindness limitlessly. Introducing the Unlimited Heartfelt Subscription for just $97. With this extraordinary offer you can send up to 25 personalized cards every day, making a world of difference with each heartfelt message you send.",
    ],
  },
  {
    children: <HeartFeltStepTwo />,
    content: [
      "Imagine the impact of touching lives every single day. With the Unlimited Heartfelt Subscription, you're not just sending cards; you're sending love, support, and joy to those who matter most to you. Whether it's birthdays, anniversaries, or simply reaching out to brighten someone's day, you have the power to strengthen relationships and foster deeper connections.",
    ],
  },
  {
    children: <HeartFeltStepThree />,
    content: [
      'By joining this subscription, you become a catalyst for positivity. Your daily acts of kindness will inspire those around you to do the same, creating a wave of love and goodwill.',
      "Don't miss the opportunity to make a monumental difference in the lives of others. Embrace the Unlimited Heartfelt Subscription today and let your heart shine through your limitless cards. Subscribe now and become a beacon of kindness in a world that needs it.",
    ],
  },
]

export const PlanTiles = (props: PlanTilesProps) => {
  const {
    title,
    description,
    onSelect,
    onProceedToCheckout,
    planIds,
    layout,
  } = props

  const isTablet = useSelector(state => state.window.width < 950)
  const isMobile = useSelector(state => state.window.width <= 520)
  const account = useSelector(state => state.user.account)
  const shouldStackDivider = useSelector(state => state.window.width <= 718)
  const tileMinWidth = isMobile ? 270 : isTablet ? 330 : 350

  const actions = useActions()

  const { plans, planDescriptions, activePlans } = usePlanManagement(planIds)

  const { currentPlan, newPlan, currentSubscription } = usePlansSubscriptions()
  const [selectedLearnMoreId, setSelectedLearnMoreId] = useState('')
  const { data: checkout } = useCheckout()

  const getOrderForPlan = (planId: string) => {
    const description = planDescriptions[planId]
    if (!description) {
      return 0
    }
    try {
      return description.order ?? 0
    } catch (error) {
      return 0
    }
  }

  const isDowngraded =
    newPlan &&
    currentPlan &&
    getOrderForPlan(newPlan.id) < getOrderForPlan(currentPlan.id)

  const basicPlan = plans.find(plan => plan.title === 'Basic')

  const [selectedPlan, setSelectedPlan] = useState<string | null>('6')
  const [shouldShowBasicPlan, setShouldShowBasicPlan] = useState<boolean>(false)
  const hasBasicPlan = activePlans.some(plan => plan.id === '1')
  const shouldShowPlanDivider = hasBasicPlan && !shouldShowBasicPlan

  const activePlansWithVariants = activePlans
    .filter(plan => plan.id !== '1' || shouldShowBasicPlan)
    .map(plan => {
      const variantId = planDescriptions[plan.id]?.saleorVariantId
      if (variantId) {
        return {
          plan,
          variantId,
        }
      }
      return { plan }
    })

  return (
    <Div width="100%">
      {title && (
        <Text
          type="body"
          content={title}
          style={{ fontSize: '2rem', fontWeight: 700 }}
          alignment="center"
          color="primaryHeading"
          inset={{ bottom: 'x1_5' }}
          lineHeight={1.6}
        />
      )}
      {description && (
        <Text
          type="body"
          content={description}
          style={{ fontSize: '.875rem', fontWeight: 500 }}
          alignment="center"
          color="primaryBody"
          lineHeight={1.5}
        />
      )}
      <Div inset={{ horizontal: 'x4' }} width="100%">
        <Flex
          alignItems="center"
          justifyContent="center"
          inset={{ vertical: 'x1' }}
          flexDirection={shouldStackDivider ? 'column' : 'row'}
          columnGap="x2"
          overflowY={layout === 'grid' ? 'auto' : 'scroll'}
          flexWrap={layout === 'grid' ? 'wrap' : undefined}
          rowGap="x2"
          style={{ alignItems: 'stretch' }}
        >
          {shouldShowPlanDivider && (
            <Flex
              height={shouldStackDivider ? '40px' : 'auto'}
              inset={'x2'}
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              position="sticky"
            >
              <PlanDivider
                text="Show Basic Plan"
                backgroundColor="#C9C9C9"
                onClick={() => setShouldShowBasicPlan(!shouldShowBasicPlan)}
                orientation={shouldStackDivider ? 'horizontal' : 'vertical'}
              />
            </Flex>
          )}

          {activePlansWithVariants.map(planWithVariant => {
            const { plan, variantId } = planWithVariant
            if (!plan.description) return null
            const description = planDescriptions[plan.id]
            const isCurrentPlan = currentPlan?.id === plan.id
            const isBasic = plan.id === '1'
            const isPlanSetToCancel =
              isCurrentPlan &&
              currentSubscription &&
              currentSubscription.isSetToCancel
            const isNewPlan = newPlan?.id === plan.id
            const isPreviousPlan = isDowngraded && isCurrentPlan
            const shouldShowPurchaseNow = !isNewPlan && !isCurrentPlan
            const shouldShowKeep = isPreviousPlan || isPlanSetToCancel
            const shouldShowCancel =
              isCurrentPlan &&
              currentSubscription &&
              !currentSubscription.isSetToCancel

            const handlePlanUpgrade = () => {
              setSelectedPlan(plan.id)
              if (shouldShowPurchaseNow) {
                return {
                  text: 'Purchase Now',
                  onClick: () => onSelect(plan),
                }
              } else if (shouldShowKeep) {
                return {
                  text: 'Keep',
                  onClick: () => onSelect(plan, true),
                }
              } else if (shouldShowCancel) {
                return {
                  text: 'Cancel',
                  onClick: () => basicPlan && onSelect(basicPlan),
                }
              } else {
                return undefined
              }
            }
            const isActivePlan = selectedPlan === plan.id
            const largeSubPlans = ['130', '131', '132']

            const isDuplicateItem = checkout?.lines.some(
              lineItem => lineItem.variant.id === variantId,
            )
            const planGradient = [
              plan.backgroundStartColor,
              plan.backgroundEndColor,
            ]
            const isPromptingSeminarPlan = plan.id === '144'
            return (
              <Div
                justifyContent="center"
                height="auto"
                style={{ alignItems: 'stretch' }}
                display="flex"
              >
                <Flex
                  width="100%"
                  maxWidth="400px"
                  justifyContent="center"
                  style={{ alignItems: 'stretch' }}
                  outset="x1"
                >
                  <Tile
                    customGradientTheme={planGradient}
                    plan={plan}
                    isCurrentPlan={isCurrentPlan}
                    onSelectTile={() => setSelectedPlan(plan.id)}
                    isAdded={isDuplicateItem}
                    key={plan.id}
                    itemId={plan.id}
                    title={plan.title}
                    description={plan.subtitle ?? ''}
                    hasOptions={!!plan.relatedPackage}
                    isDescriptionLarge={largeSubPlans.some(
                      id => id === plan.id,
                    )}
                    price={plan.price.description}
                    priceSubtext={getRecurrence(plan.id, plan.isAddon)}
                    noAuthActionOverride={
                      isBasic && account === undefined
                        ? () => actions.openAccount({ join: '' })
                        : undefined
                    }
                    isPromptingsSeminarButtonOverride={isPromptingSeminarPlan}
                    onChangePlan={() => {
                      if (!isCurrentPlan) onSelect(plan)
                    }}
                    onPurchaseNow={onProceedToCheckout}
                    onRemoveFromCart={() => {
                      console.log('placeholder for remove from cart')
                    }}
                    isActive={isActivePlan}
                    background={
                      isActivePlan
                        ? `linear-gradient(156deg, ${plan.backgroundStartColor} -8.15%, ${plan.backgroundEndColor} 129.98%), #FFF`
                        : '#FFF'
                    }
                    textColor={plan.color ?? '#535F66'}
                    icon={
                      <Icon
                        name={plan.icon.toLowerCase() as IconType}
                        color={
                          isActivePlan ? '#fff' : plan.backgroundStartColor
                        }
                        size={50}
                      />
                    }
                    pillTitle={getPlanPillDescription(plan.id)}
                    minWidth={tileMinWidth}
                    currentPlanId={
                      !plan.isAddon && currentPlan && currentPlan.id !== plan.id
                        ? currentPlan.id
                        : undefined
                    }
                    canBundlePlan={
                      !currentPlan || (currentPlan && currentPlan.id === '1')
                    }
                  />
                </Flex>
                {/* TODO: Wire storytellingModal up to planStories Prop */}
                {!isBasic && selectedLearnMoreId === plan.id && (
                  <StorytellingModal
                    onClose={() => setSelectedLearnMoreId('')}
                    action={{
                      title: description.plan,
                      onClick: () => handlePlanUpgrade(),
                    }}
                    selectedItem={{
                      id: plan.id,
                      title: description.plan,
                      price: plan.price.description,
                      icon:
                        plan.id === '6'
                          ? 'smile_card_w_heart'
                          : 'smile_card_w_hearts',
                      backgroundColor: plan.id === '6' ? '#4AB4D8' : '#F862A6',
                      recurrence: 'month',
                    }}
                    steps={
                      plan.id === '6'
                        ? heartfeltStorySteps
                        : unlimitedHeartFeltStorySteps
                    }
                  />
                )}
              </Div>
            )
          })}
        </Flex>
      </Div>
    </Div>
  )
}
