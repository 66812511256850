import React, { FC } from 'react'
import { SVGProps } from './types'

export const Envelopes: FC<SVGProps> = ({ width, height, color, onClick }) => {
  return (
    <svg
      width={width ?? 34}
      height={height ?? 29}
      viewBox="0 0 34 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5685 9.48426C33.5658 9.44341 33.5604 9.40391 33.5483 9.36306C33.5255 9.29224 33.4879 9.23232 33.4409 9.18194C33.3415 9.0226 33.2005 8.89187 33.0287 8.80607L15.6475 0.154335C15.4729 0.0671777 15.2863 0.0372173 15.1063 0.0549212C15.0379 0.0467502 14.9667 0.0535594 14.8969 0.0794342C14.8566 0.0944144 14.8217 0.114842 14.7894 0.137993C14.5759 0.233322 14.3933 0.400827 14.2832 0.628254L12.1576 5.02017C12.0434 5.25577 12.1388 5.54175 12.3724 5.65751C12.6047 5.77326 12.8867 5.67657 13.0008 5.43961L14.9049 1.50527L15.8932 4.28478C15.9617 4.47952 16.143 4.59936 16.335 4.59936C16.3874 4.59936 16.4411 4.58983 16.4948 4.57076C16.7392 4.48088 16.8654 4.20852 16.7781 3.96066L15.8355 1.30781L32.0175 9.36306L25.9065 11.3636C25.6594 11.4439 25.5238 11.7136 25.603 11.9642C25.6674 12.1657 25.8514 12.2951 26.0501 12.2951C26.0985 12.2951 26.1468 12.2883 26.1952 12.2719L32.4311 10.2305L27.73 19.9431C27.6158 20.1787 27.7112 20.4647 27.9448 20.5805C28.012 20.6132 28.0818 20.6295 28.1516 20.6295C28.3248 20.6295 28.4927 20.5315 28.5733 20.3626L33.496 10.1911C33.6034 9.96908 33.6276 9.71986 33.5685 9.4829V9.48426Z"
        fill="white"
      />
      <path
        d="M33.5685 9.48426C33.5658 9.44341 33.5604 9.40391 33.5483 9.36306C33.5255 9.29224 33.4879 9.23232 33.4409 9.18194C33.3415 9.0226 33.2005 8.89187 33.0287 8.80607L15.6475 0.154335C15.4729 0.0671777 15.2863 0.0372173 15.1063 0.0549212C15.0379 0.0467502 14.9667 0.0535594 14.8969 0.0794342C14.8566 0.0944144 14.8217 0.114842 14.7894 0.137993C14.5759 0.233322 14.3933 0.400827 14.2832 0.628254L12.1576 5.02017C12.0434 5.25577 12.1388 5.54175 12.3724 5.65751C12.6047 5.77326 12.8867 5.67657 13.0008 5.43961L14.9049 1.50527L15.8932 4.28478C15.9617 4.47952 16.143 4.59936 16.335 4.59936C16.3874 4.59936 16.4411 4.58983 16.4948 4.57076C16.7392 4.48088 16.8654 4.20852 16.7781 3.96066L15.8355 1.30781L32.0175 9.36306L25.9065 11.3636C25.6594 11.4439 25.5238 11.7136 25.603 11.9642C25.6674 12.1657 25.8514 12.2951 26.0501 12.2951C26.0985 12.2951 26.1468 12.2883 26.1952 12.2719L32.4311 10.2305L27.73 19.9431C27.6158 20.1787 27.7112 20.4647 27.9448 20.5805C28.012 20.6132 28.0818 20.6295 28.1516 20.6295C28.3248 20.6295 28.4927 20.5315 28.5733 20.3626L33.496 10.1911C33.6034 9.96908 33.6276 9.71986 33.5685 9.4829V9.48426Z"
        fill={color}
      />
      <path
        d="M4.34139 13.1081L1.62759 11.8144L18.6865 5.90538L15.8116 11.6019C15.6934 11.8362 15.7847 12.1235 16.0157 12.2434C16.0841 12.2788 16.158 12.2965 16.2292 12.2965C16.3997 12.2965 16.5649 12.2011 16.6481 12.0364L19.5835 6.22133L21.3143 11.3636C21.3976 11.6128 21.6648 11.7449 21.9105 11.6605C22.1563 11.5761 22.2879 11.3051 22.2033 11.0558L20.3086 5.4301C20.228 5.19042 20.0669 4.99976 19.8682 4.87856C19.8386 4.85132 19.8064 4.82545 19.7688 4.80638C19.703 4.77234 19.6332 4.75736 19.5647 4.75599C19.3874 4.7165 19.1981 4.72195 19.0141 4.78595L0.690321 11.1307C0.509043 11.1934 0.353279 11.3051 0.235113 11.4508C0.182743 11.4944 0.137088 11.5488 0.106204 11.6156C0.0887477 11.6537 0.0780053 11.6932 0.0712913 11.7327C-0.0173333 11.9601 -0.0240473 12.2093 0.0551777 12.4435L3.57062 22.8847C3.63776 23.0822 3.82038 23.2075 4.01509 23.2075C4.06611 23.2075 4.11714 23.1993 4.16682 23.1816C4.41255 23.0972 4.54415 22.8262 4.45955 22.577L1.10793 12.6192L3.94258 13.9702C4.00703 14.0015 4.07551 14.0151 4.14265 14.0151C4.31856 14.0151 4.48775 13.9143 4.56832 13.7414C4.67843 13.5031 4.57772 13.2198 4.34273 13.1068L4.34139 13.1081Z"
        fill="white"
      />
      <path
        d="M4.34139 13.1081L1.62759 11.8144L18.6865 5.90538L15.8116 11.6019C15.6934 11.8362 15.7847 12.1235 16.0157 12.2434C16.0841 12.2788 16.158 12.2965 16.2292 12.2965C16.3997 12.2965 16.5649 12.2011 16.6481 12.0364L19.5835 6.22133L21.3143 11.3636C21.3976 11.6128 21.6648 11.7449 21.9105 11.6605C22.1563 11.5761 22.2879 11.3051 22.2033 11.0558L20.3086 5.4301C20.228 5.19042 20.0669 4.99976 19.8682 4.87856C19.8386 4.85132 19.8064 4.82545 19.7688 4.80638C19.703 4.77234 19.6332 4.75736 19.5647 4.75599C19.3874 4.7165 19.1981 4.72195 19.0141 4.78595L0.690321 11.1307C0.509043 11.1934 0.353279 11.3051 0.235113 11.4508C0.182743 11.4944 0.137088 11.5488 0.106204 11.6156C0.0887477 11.6537 0.0780053 11.6932 0.0712913 11.7327C-0.0173333 11.9601 -0.0240473 12.2093 0.0551777 12.4435L3.57062 22.8847C3.63776 23.0822 3.82038 23.2075 4.01509 23.2075C4.06611 23.2075 4.11714 23.1993 4.16682 23.1816C4.41255 23.0972 4.54415 22.8262 4.45955 22.577L1.10793 12.6192L3.94258 13.9702C4.00703 14.0015 4.07551 14.0151 4.14265 14.0151C4.31856 14.0151 4.48775 13.9143 4.56832 13.7414C4.67843 13.5031 4.57772 13.2198 4.34273 13.1068L4.34139 13.1081Z"
        fill={color}
      />
      <path
        d="M26.4185 13.33C26.3674 13.2769 26.3057 13.2387 26.2412 13.2156C26.0868 13.1189 25.9055 13.063 25.7108 13.063H6.34767C6.15297 13.063 5.97169 13.1189 5.81727 13.2156C5.75282 13.2401 5.69105 13.2769 5.64002 13.33C5.61048 13.3613 5.58765 13.3953 5.56885 13.4307C5.41846 13.6105 5.32715 13.8434 5.32715 14.0967V27.015C5.32715 27.2357 5.39563 27.4399 5.51245 27.6074C5.52991 27.6701 5.55945 27.73 5.60242 27.7831C5.68299 27.8812 5.79444 27.9356 5.90992 27.9493C6.04286 28.0133 6.19057 28.05 6.34633 28.05H25.7095C25.8652 28.05 26.0129 28.0133 26.1459 27.9493C26.2614 27.9356 26.3728 27.8812 26.4534 27.7831C26.4963 27.73 26.5259 27.6701 26.5433 27.6074C26.6602 27.4399 26.7287 27.2357 26.7287 27.015V14.0967C26.7287 13.8434 26.6373 13.6105 26.4869 13.4294C26.4668 13.394 26.4453 13.3599 26.4158 13.3286L26.4185 13.33ZM20.4121 22.0539C20.2134 21.885 19.9166 21.9122 19.7501 22.1152C19.5836 22.3167 19.6105 22.6177 19.8106 22.7865L24.9092 27.0968H7.15067L12.2493 22.7865C12.448 22.6177 12.4762 22.3181 12.3097 22.1152C12.1432 21.9136 11.8478 21.885 11.6477 22.0539L6.26845 26.6024V14.6087L14.3279 22.5101C14.806 22.9786 15.4183 23.2128 16.0306 23.2128C16.6429 23.2128 17.2566 22.9786 17.7333 22.5101L25.7914 14.6087V26.6024L20.4121 22.0539ZM7.01639 14.015H25.0434L17.0807 21.8237C16.4925 22.4011 15.57 22.4011 14.9805 21.8237L7.01639 14.015Z"
        fill="white"
      />
      <path
        d="M26.4185 13.33C26.3674 13.2769 26.3057 13.2387 26.2412 13.2156C26.0868 13.1189 25.9055 13.063 25.7108 13.063H6.34767C6.15297 13.063 5.97169 13.1189 5.81727 13.2156C5.75282 13.2401 5.69105 13.2769 5.64002 13.33C5.61048 13.3613 5.58765 13.3953 5.56885 13.4307C5.41846 13.6105 5.32715 13.8434 5.32715 14.0967V27.015C5.32715 27.2357 5.39563 27.4399 5.51245 27.6074C5.52991 27.6701 5.55945 27.73 5.60242 27.7831C5.68299 27.8812 5.79444 27.9356 5.90992 27.9493C6.04286 28.0133 6.19057 28.05 6.34633 28.05H25.7095C25.8652 28.05 26.0129 28.0133 26.1459 27.9493C26.2614 27.9356 26.3728 27.8812 26.4534 27.7831C26.4963 27.73 26.5259 27.6701 26.5433 27.6074C26.6602 27.4399 26.7287 27.2357 26.7287 27.015V14.0967C26.7287 13.8434 26.6373 13.6105 26.4869 13.4294C26.4668 13.394 26.4453 13.3599 26.4158 13.3286L26.4185 13.33ZM20.4121 22.0539C20.2134 21.885 19.9166 21.9122 19.7501 22.1152C19.5836 22.3167 19.6105 22.6177 19.8106 22.7865L24.9092 27.0968H7.15067L12.2493 22.7865C12.448 22.6177 12.4762 22.3181 12.3097 22.1152C12.1432 21.9136 11.8478 21.885 11.6477 22.0539L6.26845 26.6024V14.6087L14.3279 22.5101C14.806 22.9786 15.4183 23.2128 16.0306 23.2128C16.6429 23.2128 17.2566 22.9786 17.7333 22.5101L25.7914 14.6087V26.6024L20.4121 22.0539ZM7.01639 14.015H25.0434L17.0807 21.8237C16.4925 22.4011 15.57 22.4011 14.9805 21.8237L7.01639 14.015Z"
        fill={color}
      />
      <defs></defs>
    </svg>
  )
}
