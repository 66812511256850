import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type PlanDividerProps = {
  text: string
  textColor?: string
  backgroundColor?: string
  onClick: () => void
  orientation?: 'horizontal' | 'vertical'
}

export const PlanDivider = (props: PlanDividerProps) => {
  const { text, textColor, backgroundColor, onClick, orientation } = props
  return (
    <Flex
      width="fit-content"
      height="fit-content"
      position="relative"
      transform={orientation === 'horizontal' ? 'rotate(90deg)' : ''}
    >
      <Flex
        position="relative"
        minHeight="400px"
        width="4px"
        backgroundColor="#C9C9C9"
        borderRadius="8px"
        justifyContent="center"
      >
        <Flex
          position="absolute"
          right="-88px"
          top="180px"
          color={textColor ?? 'black'}
          backgroundColor={backgroundColor ?? '#C9C9C9'}
          justifyContent="center"
          borderRadius="24px"
          alignItems="center"
          cursor="pointer"
          onClick={onClick}
          inset={{ vertical: 'x_5', horizontal: 'x4' }}
          transform="rotate(270deg)"
        >
          <Text
            color="inverseHeading"
            type="caption"
            whiteSpace="nowrap"
            weight="semiBold"
            content={text}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
