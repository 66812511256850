import React from 'react'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useEffect, useState } from 'src/hooks'
import { useCheckout, usePlans } from 'src/react_query'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'

type SuggestedPlanAddonProps = {
  planVariantId: string
  planSku: string
}

export const SuggestedPlanAddon = ({
  planVariantId,
  planSku,
}: SuggestedPlanAddonProps) => {
  const plansQuery = usePlans({ suspense: true })
  const plans = plansQuery.data

  const affiliatePlanSkus = ['GS00017', 'GS00018']
  const isAffiliatePlan = affiliatePlanSkus.some(sku => sku === planSku)

  const mockAffiliatePlan = {
    title: 'Promptings Consultant',
    price: planSku === 'GS00017' ? '$14' : planSku === 'GS00018' ? '$99' : '0',
  }

  const plan = isAffiliatePlan
    ? mockAffiliatePlan
    : plans
    ? plans?.find(plan => plan.stripeId === planSku)
    : undefined

  const { data } = useCheckout()
  const { addToCart, checkIsInCart } = useHandleProductActions(planSku)
  const isOnCart = checkIsInCart(data, planSku)
  const [isSelected, setIsSelected] = useState(isOnCart)

  useEffect(() => {
    setIsSelected(isOnCart)
  }, [isOnCart])

  const textColor = isSelected ? 'inverseHeading' : 'primaryHeading'

  const handleSelectAddon = () => {
    if (isOnCart) return
    addToCart(planVariantId)
    setIsSelected(true)
  }
  // Fix this mess by moving affiliate cart logic to a hook
  const isAffiliateTrialOnCart = checkIsInCart(data, '15545')
  const isMonthlyAffiliateOnCart = checkIsInCart(data, 'GS00017')
  const isYearlyAffiliateCart = checkIsInCart(data, 'GS00018')
  const isAffiliateFailsafe =
    isAffiliateTrialOnCart || isMonthlyAffiliateOnCart || isYearlyAffiliateCart

  return isAffiliateFailsafe ? (
    <></>
  ) : (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      style={{
        background: isSelected ? 'black' : 'white',
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
        borderTop: isSelected ? '4px solid #6b7280' : '',
      }}
      inset="x1_5"
      outset={{ bottom: 'x1' }}
      borderRadius="medium"
      onClick={handleSelectAddon}
      cursor="pointer"
    >
      <Flex alignItems="center">
        <Div
          minWidth="3px"
          height="35px"
          style={{
            background: isSelected
              ? 'linear-gradient(161deg, #4ace9e 0%, #67e5dd 50%, #31d3a0 100%)'
              : 'linear-gradient(161deg, rgb(196, 113, 245), rgb(43 215 251) 100%)',
            borderRadius: '2px',
          }}
          outset={{ right: 'x1_5' }}
        />
        <Flex
          id="check-circle"
          minWidth="16px"
          borderColor="background"
          borderStyle="solid"
          borderWidth="bold"
          style={{ border: isSelected ? 'none' : '2px solid #D4D4D8' }}
          minHeight="16px"
          borderRadius="circle"
          inset="2px"
          justifyContent="center"
          alignItems="center"
          backgroundColor={isSelected ? 'foreground' : 'transparent'}
          outset={{ right: '12px' }}
        >
          {isSelected && <Icon name="check" size={12} fill="primaryBodyText" />}
        </Flex>
        <Flex flexDirection="row" alignItems="center" columnGap="x_5">
          <Icon
            name="promptings_logo"
            size={32}
            color={'#FFF'}
            gradientTransform="rotate(90)"
            gradient={[
              { offset: '-56%', color: '#277AD4' },
              { offset: '58.3%', color: '#4AB5D9' },
              { offset: '152%', color: '#67E5DD' },
            ]}
          />
          <Text
            content={plan?.title ?? ''}
            type={'caption'}
            weight="semiBold"
            color={textColor}
          />
        </Flex>
      </Flex>
      <Flex
        style={{
          marginLeft: 'auto',
          boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
        }}
        alignItems="flex-end"
        justifyContent="center"
      >
        {isOnCart ? (
          <Text
            type="footnote"
            weight="semiBold"
            color={textColor}
            content="Added to Cart"
          />
        ) : (
          <>
            <Text
              content={`${plan?.price}`}
              type={'largeBody'}
              color={textColor}
              weight="bold"
              outset={{ right: 'x_25' }}
            />
            <Text color={textColor} type="footnote" content={'/mo'} />
          </>
        )}
      </Flex>
    </Flex>
  )
}
