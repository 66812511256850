import React from 'react'

// @src imports
import * as SendDelayOptions from 'src/orders/sendDelayOptions'
import { Confirm, DefaultError, Transition } from 'src/chrome'
import { CardPanelView } from 'src/orders/components'
import {
  CampaignDrawer,
  CampaignFooter,
  CampaignHeader,
  CampaignHeaderActions,
  CampaignLineCard,
  CampaignOrders,
  CampaignRecipients,
  InvoiceModal,
} from 'src/campaigns/components'

import { getFlags } from 'src/legacy_graphql'
import {
  CampaignFragment,
  CardFragment,
  DetailedOrderFragment,
} from 'src/graphql/generated/graphql'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'

import {
  useActions,
  useAppcues,
  useCallback,
  useFlag,
  useQueries,
  useState,
} from 'src/hooks'
import CampaignLineAddCard from '../CampaignLine/CampaignLineAddCard'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { EditCampaignCardRoute } from '../../routes/EditCampaignCardRoute'
import { ReplaceCampaignCardRoute } from '../../routes/ReplaceCampaignCardRoute'
import { AddCampaignCardRoute } from '../../routes/AddCampaignCardRoute'
import AddCampaignCard from '../AddCampaignCard/AddCampaignCard'
import EditCampaignCard from '../EditCampaignCard/EditCampaignCard'
import ReplaceCampaignCard from '../ReplaceCampaignCard/ReplaceCampaignCard'
import { Dialog, LoadingSpinner, Text } from '@sendoutcards/quantum-design-ui'
import { cssStyles } from 'src/styled'
import NewEditorOptionModal from 'src/chrome/NewEditorOptionModal/NewEditorOptionModal'
import { buildInput } from 'src/campaigns/utils'
import {
  queryKeyStore,
  useCampaign,
  useCreateOrder,
  useUpdateCampaign,
} from 'src/react_query'
import { useQueryClient } from '@tanstack/react-query'
import omit from 'lodash/omit'

const styles = cssStyles({
  campaign: {
    minHeight: '100vh',
    paddingBottom: '50px',
    display: 'flex',
    flexDirection: 'column',
  },
  ordersContainer: {
    margin: 'auto auto 0',
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #b4b3b4',
    width: '100%',
    padding: '15px 35px 75px',
    backgroundColor: 'white',
  },
  ordersHeader: {
    margin: '15px 0 0 15px',
  },
  campaignGrid: {
    width: '100%',
    maxWidth: '972px',
    margin: '0 auto',
    paddingBottom: '65px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 324px)',
    justifyContent: 'center',
  },
})

export interface CardActionsProps {
  editableCampaign: CampaignFragment
  campaignRoute: CampaignRoute
  resetRoute: () => void
}

interface Props {
  route: CampaignRoute
}

const Campaign: React.FC<Props> = props => {
  const { route } = props
  const resetRoute = () =>
    actions.openCampaigns(CampaignRoute(route.campaignId))

  const { mutateAsync: updateCampaign } = useUpdateCampaign()
  const [flags] = useQueries(getFlags())
  const { data: editableCampaign, isLoading } = useCampaign({
    id: route.campaignId,
  })
  const createOrderMutation = useCreateOrder()
  // Drawer visibility control
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const queryClient = useQueryClient()
  // Modal visibility control
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false)
  const [isCardPreviewModalOpen, setIsCardPreviewModalOpen] = useState(false)

  const [activeCampaignLine, setActiveCampaignLine] = useState(0)
  const [activeOrder, setActiveOrder] = useState<DetailedOrderFragment>()
  const [recipientsOrder, setRecipientsOrder] = useState<
    DetailedOrderFragment
  >()

  const actions = useActions()

  // TODO: React Query Refactor not sure if we want to maintain this convention, just implemented
  // as the old cache was setup.
  const reloadCampaignQuery = () => {
    if (editableCampaign) {
      queryClient.invalidateQueries(
        queryKeyStore.campaign.detail({ id: editableCampaign?.id }),
      )
    }
  }

  const { newCardEditor: canShowNewCardEditor } = useFlag(flags)

  const [
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
  ] = useState<boolean>(false)

  const handleEditCard = (cardId: string, isNewEditorCard: boolean) => {
    if (canShowNewCardEditor && !isNewEditorCard) {
      setShouldShowEditorChoiceModal(true)
    } else {
      actions.openCampaigns(
        CampaignRoute(route.campaignId, EditCampaignCardRoute(cardId)),
      )
    }
  }

  const handleEditCardOnNewEditor = async (card: CardFragment) => {
    try {
      if (selectedCard && editableCampaign) {
        const campaignInput = buildInput(editableCampaign)
        await updateCampaign({
          campaign: {
            ...campaignInput,
            lines: (campaignInput.lines || []).map(line => ({
              ...line,
              card: line.card === selectedCard.id ? card.id : line.card,
            })),
          },
        })
        reloadCampaignQuery()
        resetRoute()
        actions.openCampaigns(
          CampaignRoute(route.campaignId, EditCampaignCardRoute(card.id)),
        )
        setShouldShowEditorChoiceModal(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const selectedCard = editableCampaign?.lines[activeCampaignLine]?.card

  useAppcues('-L89ap8oSHf53PF86-N7')

  const handleDrawer = (index: number) => {
    setIsDrawerOpen(true)
    setActiveCampaignLine(index)
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
    setActiveCampaignLine(0)
  }

  const handleUpdateCampaign = async (campaign: CampaignFragment) => {
    await updateCampaign({
      campaign: {
        id: campaign.id,
        name: campaign.name,
        lines: campaign.lines?.map(line => {
          return {
            card: line.card?.id,
            giftVariation: line.giftVariation?.id,
            sendDelay: line.sendDelay
              ? {
                  ...line.sendDelay,
                  __typename: undefined,
                }
              : null,
          }
        }),
      },
    })
  }

  const handleResend = useCallback(
    async (order: DetailedOrderFragment) => {
      if (order.lines.length > 0) {
        const {
          createOrder: { order: createdOrder },
        } = await createOrderMutation.mutateAsync({
          order: {
            lines: order.lines?.map(line => ({
              card: line.card?.id,
              giftVariation: line.giftVariation?.id,
              sendDelay: omit(line.sendDelay, '__typename'),
            })),
          },
        })
        actions.openOrder(createdOrder.id)
      }
    },
    [createOrderMutation, actions],
  )

  const handleUpdatePaperType = () => {
    reloadCampaignQuery()
  }

  const handleShowCardPreview = (index: number) => {
    setActiveCampaignLine(index)
    setIsCardPreviewModalOpen(true)
  }

  const handleCloseCardPreview = () => {
    setActiveCampaignLine(0)
    setIsCardPreviewModalOpen(false)
  }

  const showInvoice = (order: DetailedOrderFragment) => {
    setActiveOrder(order)
    setIsInvoiceModalOpen(true)
  }

  const closeInvoiceModal = () => {
    setActiveOrder(undefined)
    setIsInvoiceModalOpen(false)
  }

  const onViewRecipients = (order: DetailedOrderFragment | undefined) => {
    setRecipientsOrder(order)
  }

  if (isLoading) {
    return <LoadingSpinner size="medium" />
  }
  // TODO: React Query Refactor, should we throw if there's no campaign?
  if (!editableCampaign) {
    return null
  }

  const cardActionProps: CardActionsProps = {
    editableCampaign: editableCampaign,
    campaignRoute: route,
    resetRoute: resetRoute,
  }

  const handleAddCard = () => {
    actions.openCampaigns(
      CampaignRoute(route.campaignId, AddCampaignCardRoute()),
    )
  }

  const handlePickReplacementCard = (cardId: string) => {
    actions.openCampaigns(
      CampaignRoute(route.campaignId, ReplaceCampaignCardRoute(cardId)),
    )
  }

  switch (route.subroute?.path) {
    // pick a new card to add
    case AddCampaignCardRoute.path:
      return <AddCampaignCard {...cardActionProps} route={route.subroute} />

    // edit an existing card
    case EditCampaignCardRoute.path:
      return <EditCampaignCard {...cardActionProps} route={route.subroute} />

    // pick a card to replace another one
    case ReplaceCampaignCardRoute.path:
      return (
        <ReplaceCampaignCard
          {...cardActionProps}
          reload={() => reloadCampaignQuery()}
          route={route.subroute}
        />
      )

    case undefined:
      return (
        <div>
          {canShowNewCardEditor && shouldShowEditorChoiceModal && selectedCard && (
            <NewEditorOptionModal
              title={
                'Try it out! Would you like to edit\nyour card in the new editor'
              }
              description={
                'Since this card was created in the current card\neditor we can copy it and allow you to try editing it\nin the new editor. Would you like to try?'
              }
              tryNewEditor={{
                title: 'Copy Card to New Editor',
                onClick: card => {
                  handleEditCardOnNewEditor(card)
                  setShouldShowEditorChoiceModal(false)
                },
              }}
              useCurrentEditor={{
                title: 'Edit in Current Editor',
                onClick: () => {
                  actions.openCampaigns(
                    CampaignRoute(
                      route.campaignId,
                      EditCampaignCardRoute(selectedCard.id),
                    ),
                  )
                  setShouldShowEditorChoiceModal(false)
                },
              }}
              isOpen={shouldShowEditorChoiceModal}
              onClose={() => {
                setShouldShowEditorChoiceModal(false)
              }}
              cardId={selectedCard.id}
            />
          )}
          {editableCampaign && (
            <div css={styles.campaign}>
              <CampaignHeader
                campaignId={editableCampaign.id}
                name={editableCampaign.name}
              />
              <CampaignHeaderActions
                hasValidSequence={SendDelayOptions.validateSequence(
                  editableCampaign.lines,
                )}
                campaignId={editableCampaign.id}
                campaignLines={editableCampaign.lines}
                campaignName={editableCampaign.name}
                isCampaignShareable={editableCampaign.isShareable}
              />
              <div css={styles.campaignGrid}>
                {editableCampaign.lines.map((line, index) => {
                  return (
                    <CampaignLineCard
                      key={line.card?.id}
                      isActive={activeCampaignLine === index}
                      line={line}
                      title={line.card?.sendableCard?.title}
                      buttons={{
                        primaryButton: {
                          label: 'View Card',
                          onClick: () => handleShowCardPreview(index),
                        },
                        secondaryButton: {
                          label: 'Edit',
                          onClick: () => handleDrawer(index),
                        },
                      }}
                    />
                  )
                })}
                <CampaignLineAddCard onAddCard={handleAddCard} />
                {isDrawerOpen && editableCampaign && (
                  <CampaignDrawer
                    campaign={editableCampaign}
                    lineIndex={activeCampaignLine}
                    onClose={closeDrawer}
                    onEditCard={handleEditCard}
                    onReplaceCard={handlePickReplacementCard}
                    onUpdatePaperType={handleUpdatePaperType}
                    onUpdateCampaign={handleUpdateCampaign}
                  />
                )}
                {isCardPreviewModalOpen && selectedCard && (
                  <Confirm
                    title={'Card Preview'}
                    confirmTitle={'Edit'}
                    declineTitle={'Close'}
                    onConfirm={() =>
                      handleEditCard(
                        selectedCard.id,
                        selectedCard.isNewEditorCard,
                      )
                    }
                    onDecline={() => handleCloseCardPreview()}
                    childType={'image'}
                    isModalEnabled={true}
                  >
                    <CardPanelView
                      card={{
                        ...selectedCard,
                        panels: selectedCard.minimalPanels,
                      }}
                    />
                  </Confirm>
                )}
              </div>
              <div css={styles.ordersContainer}>
                <div>
                  <div css={styles.ordersHeader}>
                    <Text type="subtitle">Orders</Text>
                  </div>
                  <CampaignOrders
                    campaignId={route.campaignId}
                    onResend={handleResend}
                    onViewInvoice={showInvoice}
                    openOrder={actions.openOrder}
                    onViewRecipients={onViewRecipients}
                  />
                  <Dialog
                    isOpen={!!recipientsOrder}
                    onClose={() => setRecipientsOrder(undefined)}
                  >
                    <CampaignRecipients order={recipientsOrder} />
                  </Dialog>
                </div>
              </div>
              <CampaignFooter
                cards={editableCampaign.lines.length}
                isSaved={true}
                isSaving={false}
                isInvalid={editableCampaign.lines.length < 1}
                editableCampaignId={editableCampaign.id}
                name={editableCampaign.name}
                onSave={() => handleUpdateCampaign(editableCampaign)}
              />
              <InvoiceModal
                isOpen={isInvoiceModalOpen}
                onClose={closeInvoiceModal}
                order={activeOrder}
              />
            </div>
          )}
        </div>
      )
  }
}

export default suspenseBoundary({
  component: Campaign,
  unresolved: <Transition />,
  failure: DefaultError,
})
