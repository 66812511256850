import React from 'react'
// @src imports
import { useEffect, useSelector, useState } from 'src/hooks'

import {
  Flex,
  // PromotionWidget,
  Text,
} from '@sendoutcards/quantum-design-ui'

// import { isCountryAffiliaetEligible } from 'src/helpers/isCountryAffiliateEligible'

// import AffiliateUpsale from '../../../components/AffiliateUpsale'
import PromptingsUpcomingEvents from '../../../components/PromptingsUpcomingEvents'
import {
  CollapsibleBanner,
  CollapsibleBannerProps,
} from 'src/saleor/components/collapsable_banner/CollapsableBanner'
import LeaderBoard from '../../../components/Leaderboard/LeaderBoard'
// import { navigateAndScroll } from 'src/pricing_page/utils'
import { AccountSubscriptionChanger } from 'src/SubscriptionChanger/AccountSubscriptionChanger'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { AffiliateCreationForm } from 'src/saleor/components/affiliate_creation_form/AffiliateCreationForm'
import { SendingMetricsWidget } from 'src/dashboard/components/Widgets/SendingMetrics/SendingMetricsWidget'
import { CardFeatureWidget } from 'src/dashboard/components/Widgets/CardFeature/CardFeatureWidget'
import { SubscriptionWidget } from 'src/dashboard/components/Widgets/Subscription/SubscriptionWidget'
import { CardHistoryWidget } from 'src/dashboard/components/Widgets/History/CardHistoryWidget'
import { AccountSettingsWidget } from 'src/dashboard/components/Widgets/Settings/AccountSettingsWidget'
import { DraftWidget } from 'src/dashboard/components/Widgets/Drafts/DraftWidget'
import { RemindersListWidget } from 'src/dashboard/components/Widgets/Reminders/RemindersListWidget'
import { FullAccessFeatureType, Label } from 'src/graphql/generated/graphql'
import {
  useAccountQuery,
  useLeaderBoards,
  useMarketingContent,
} from 'src/react_query'
import {
  defaultSubscriptionIds,
  premiereSubscriptionIds,
} from 'src/helpers/supportedUpsaleProducts'
import { AFFILIATE_BRANDING } from 'src/app/constants'
import { GratitudeMovementWidget } from 'src/dashboard/components/Widgets/GratitudeMovement/GratitudeMovementWidget'
import { useAccount } from 'src/hooks'

type DashboardHomeViewProps = {
  toggleIsAccountDrawerOpen: () => void
}

const DashboardHomeView: React.FC<DashboardHomeViewProps> = ({
  toggleIsAccountDrawerOpen,
}) => {
  const windowWidth = useSelector(state => state.window.width)

  const isLeaderboardMobile = useSelector(state => state.window.width <= 1500)
  const shouldColumnWrap = windowWidth <= 1260
  const shouldWelcomeWrap = windowWidth >= 854 && windowWidth <= 1358
  const isTablet = useSelector(state => state.window.width <= 854)
  const isMobile = useSelector(state => state.window.width <= 516)

  const { data: account } = useAccountQuery()
  const [isSelectingSubscription, setIsSelectingSubscription] = useState(false)
  const [shouldShowAffiliateForm, setShouldShowAffiliateForm] = useState(false)
  const [shouldShowAffiliateBanner, setShouldShowAffiliateBanner] = useState<
    boolean | undefined
  >()

  const accountType = useAccount()

  useEffect(() => {
    if (account && shouldShowAffiliateBanner === undefined) {
      setShouldShowAffiliateBanner(
        account.labels.includes(Label.NeedsAffiliateAccInfo),
      )
    }
  }, [account, shouldShowAffiliateBanner])

  // const isSparseOrSample =
  //   account.type === UserType.SP123 || account.type === UserType.S

  const { data: marketingContent } = useMarketingContent()

  const getBannerData = (): CollapsibleBannerProps | undefined => {
    if (!account || !marketingContent) {
      return undefined
    }
    const profileWarningTitle =
      marketingContent.affiliateProfileWarningTitle.content
    const profileWarningBody =
      marketingContent.affiliateProfileWarningBody.content

    const profileCompleteTitle =
      marketingContent.affiliateProfileCompleteTitle.content

    const bannerBodyStyles = {
      fontSize: '14px',
      color: '#6B7280',
    }

    return account.labels.includes(Label.NeedsAffiliateAccInfo)
      ? {
          type: 'warning',
          title: profileWarningTitle,
          bannerActions: {
            primaryAction: {
              title: `Complete ${AFFILIATE_BRANDING.capitalized} Profile`,
              onClick: () => {
                setShouldShowAffiliateForm(true)
                setShouldShowAffiliateBanner(false)
              },
            },
          },
          children: (
            <Text
              type="body"
              content={profileWarningBody}
              style={{ ...bannerBodyStyles }}
            />
          ),
        }
      : {
          type: 'success',
          title: profileCompleteTitle,
          onClose: () => setShouldShowAffiliateBanner(false),
        }
  }

  const bannerData = getBannerData()

  // Get leaderboard from backend
  const { data: leaderBoards } = useLeaderBoards()
  const [currentLeaderBoard] = leaderBoards ?? [undefined]

  const shouldShowLeaderBoard =
    currentLeaderBoard && currentLeaderBoard.challenges.length > 0
  const LeaderBoardWrapper = () =>
    currentLeaderBoard ? <LeaderBoard {...currentLeaderBoard} /> : <></>

  const isGsa = accountType?.type === 'GSA'

  // const [affiliateCountries] = useQueryFutures(getAffiliateCountries())
  // const isAffiliateEligible =
  //   affiliateCountries.value &&
  //   account.shippingAddress !== null &&
  //   account.shippingAddress.address1.trim() !== ''
  //     ? isCountryAffiliaetEligible(
  //         affiliateCountries.value,
  //         account.shippingAddress.country,
  //       )
  //     : false

  // const handleNavigation = (hash: string) => {
  //   actions.openPricing()
  //   navigateAndScroll(hash)
  // }

  const { hasFeatureAccess } = useFeatureAccess([
    FullAccessFeatureType.PremiumSubscriptionOptions,
  ])

  const subscriptionIds = hasFeatureAccess
    ? [...defaultSubscriptionIds, ...premiereSubscriptionIds]
    : defaultSubscriptionIds

  return (
    <Flex
      width="100%"
      // height="100%"
      columnGap="24px"
      rowGap="24px"
      flexDirection="column"
    >
      {shouldShowAffiliateBanner && bannerData && (
        <Flex width="100%">
          <CollapsibleBanner
            outset="0px"
            onClose={() => {
              setShouldShowAffiliateBanner(false)
            }}
            type={'success'}
            title={''}
          />
        </Flex>
      )}
      {shouldShowAffiliateForm && (
        <AffiliateCreationForm
          onSuccess={() => {
            setShouldShowAffiliateForm(false)
            setShouldShowAffiliateBanner(true)
          }}
          onCancel={() => {
            setShouldShowAffiliateForm(false)
            setShouldShowAffiliateBanner(true)
          }}
        />
      )}
      <Flex
        width="100%"
        height="100%"
        columnGap="24px"
        rowGap="24px"
        flexDirection={shouldColumnWrap ? 'column' : 'row'}
      >
        <Flex
          flexGrow={1}
          width="100%"
          height="100%"
          columnGap="24px"
          rowGap="24px"
          flexDirection="column"
        >
          <Flex
            width="100%"
            columnGap="24px"
            height={isTablet ? 'auto' : undefined}
            rowGap="24px"
            flexDirection={isTablet ? 'column' : 'row'}
          >
            <Flex
              columnGap="24px"
              rowGap="24px"
              width={isTablet ? '100%' : '65%'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <GratitudeMovementWidget
                isMobile={isMobile}
                isTablet={isTablet}
                title={
                  isGsa
                    ? 'Gratitude Movement Presentation'
                    : 'Learn about the Gratitude Movement'
                }
              />
              <CardFeatureWidget />
            </Flex>
            <Flex width={isTablet ? '100%' : undefined} flexGrow={1}>
              <SubscriptionWidget
                maxWidth="100%"
                onClick={() =>
                  setIsSelectingSubscription(!isSelectingSubscription)
                }
              />
            </Flex>
          </Flex>
          {isSelectingSubscription && (
            <AccountSubscriptionChanger
              subscriptionPlanIds={subscriptionIds}
              isOpen={true}
              onClose={() => setIsSelectingSubscription(false)}
            />
          )}
          {shouldColumnWrap && (
            <RemindersListWidget isLandscape={shouldWelcomeWrap} />
          )}
          <Flex
            width="100%"
            columnGap="24px"
            rowGap="24px"
            flexDirection={isTablet ? 'column' : 'row'}
          >
            <Flex width="100%" minWidth="380px">
              <CardHistoryWidget />
            </Flex>
            <SendingMetricsWidget />
          </Flex>
          <Flex
            width="100%"
            columnGap="24px"
            rowGap="24px"
            flexDirection={isTablet ? 'column' : 'row'}
          >
            <Flex width="100%" minWidth="380px">
              <DraftWidget />
            </Flex>
            <AccountSettingsWidget
              onToggleAccountDrawer={toggleIsAccountDrawerOpen}
            />
            {!shouldShowLeaderBoard && isLeaderboardMobile && (
              <LeaderBoardWrapper />
            )}

            {shouldShowLeaderBoard && isLeaderboardMobile && (
              <LeaderBoardWrapper />
            )}
            {/* TODO: Need Designs for sales content
          
          <Spacer space="x4" />
          <Text
            type="largeBody"
            weight="bold"
            color="primaryHeading"
            content="Account Add-ons"
            style={{ letterSpacing: '.3px', marginLeft: 4 }}
          />
          <Spacer space="x2_5" /> */}
            {/* {!isSparseOrSample && isAffiliateEligible && <AffiliateUpsale />}
          {!hasStreamVASubscription && (
            <Div outset={{ bottom: 'x1_5' }} data-mktcontent="streamVaPromo">
              <PromotionWidget
                title={marketingContent.streamVaPromo.title}
                description={marketingContent.streamVaPromo.content}
                primaryAction={{
                  title: 'Shop Now',
                  onClick: () => handleNavigation('Blocks'),
                }}
                secondaryAction={{
                  title: 'Learn More',
                  href: 'https://streamvirtualassistant.com/',
                }}
                capsuleTitle="New"
                width="100%"
                minWidth="320px"
                type="success"
              />
            </Div>
          )} */}
          </Flex>
          {shouldColumnWrap && <PromptingsUpcomingEvents />}
        </Flex>
        <Flex
          flexDirection="column"
          minWidth="420px"
          columnGap="24px"
          rowGap="24px"
          flexGrow={1}
          width={shouldColumnWrap ? '100%' : '30%'}
          height="100%"
        >
          {!shouldColumnWrap && <RemindersListWidget />}
          {!shouldColumnWrap && <PromptingsUpcomingEvents />}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DashboardHomeView
