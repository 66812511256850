import React from 'react'
import { SVGProps } from './types'

export const PromptingsPoweredBySocLogo: React.FC<SVGProps> = ({
  size,
  color,
  onClick,
}) => {
  return (
    <svg
      width={598}
      height={131}
      viewBox="0 0 598 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7013_30211)">
        <path
          d="M255.14 126.746C254.379 129.809 254.174 129.809 253.896 129.809H253.822C253.562 129.753 253.265 129.697 253.562 126.411C254.044 121.213 254.973 116.85 255.734 113.341C256.811 108.403 257.442 105.414 255.882 104.69C253.116 103.372 240.937 108.087 233.418 112.06C231.729 113.23 231.432 113.174 231.321 113.156H231.228L231.116 113.026C230.931 112.747 230.931 112.562 233.084 111.076C237.261 108.422 242.775 105.748 247.862 103.947C250.276 103.093 256.068 101.218 257.553 102.369C259.484 103.855 255.882 122.94 255.14 126.746ZM241.049 124.573H241.104C242.534 124.573 243.685 123.33 244.335 122.643C244.799 122.141 245.913 120.935 246.21 120.656C248.902 118.187 252.856 113.193 255.79 106.881C255.92 106.602 255.864 106.379 255.697 106.249C255.437 106.064 255.066 106.249 254.75 106.454L254.694 106.509C248.753 116.683 244.929 120.025 243.629 121.213C242.831 121.937 242.887 121.9 242.645 122.123C242.367 122.383 241.142 123.385 240.288 123.33C238.877 123.255 236.76 121.232 234.013 117.314C234.013 117.314 233.326 116.609 232.954 116.906C232.62 117.166 232.861 117.63 233.511 118.521C236.482 122.977 238.747 124.536 241.067 124.536L241.049 124.573ZM251.185 128.658C243.072 126.783 239.007 127.618 236.333 128.194C234.142 128.658 233.047 128.881 231.376 127.377C230.207 126.356 230.318 125.093 230.337 124.926C230.355 124.314 230.299 124.054 230.077 123.942C229.947 123.868 229.798 123.887 229.687 123.979C229.445 124.165 229.315 124.685 229.371 124.963C229.371 125.056 229.371 127.303 231.265 128.714H231.283C233.474 129.976 234.18 129.92 236.463 129.753C238.747 129.586 243.017 129.27 252.652 130.069C252.949 130.069 253.116 129.957 253.153 129.753C253.19 129.493 253.079 129.27 251.185 128.658Z"
          fill="#808089"
        />
        <path
          d="M280.648 118.391C279.794 117.964 278.866 117.611 277.882 117.333C276.88 117.054 275.877 116.813 274.856 116.572C273.835 116.33 272.907 116.033 272.053 115.699C271.199 115.346 270.53 114.882 270.011 114.288C269.491 113.694 269.249 112.914 269.249 111.93C269.249 111.076 269.472 110.315 269.936 109.628C270.4 108.941 271.106 108.403 272.053 107.976C273.018 107.567 274.225 107.363 275.673 107.363C276.694 107.363 277.752 107.53 278.848 107.827C279.943 108.143 280.983 108.626 281.929 109.275L282.542 107.939C281.67 107.307 280.63 106.806 279.386 106.435C278.161 106.064 276.917 105.878 275.654 105.878C273.779 105.878 272.257 106.156 271.05 106.732C269.862 107.289 268.971 108.032 268.414 108.96C267.857 109.869 267.56 110.872 267.56 111.967C267.56 113.156 267.82 114.139 268.321 114.882C268.841 115.625 269.509 116.219 270.363 116.664C271.217 117.091 272.146 117.444 273.167 117.723C274.188 118.001 275.209 118.242 276.193 118.484C277.195 118.725 278.124 119.022 278.959 119.356C279.813 119.709 280.5 120.173 281.02 120.767C281.54 121.361 281.8 122.141 281.8 123.125C281.8 123.961 281.558 124.703 281.094 125.372C280.63 126.04 279.906 126.578 278.94 126.987C277.975 127.395 276.731 127.581 275.209 127.581C273.686 127.581 272.238 127.302 270.883 126.745C269.528 126.188 268.451 125.52 267.671 124.703L266.929 125.947C267.764 126.857 268.915 127.599 270.438 128.175C271.941 128.75 273.557 129.047 275.246 129.047C277.14 129.047 278.681 128.769 279.887 128.212C281.094 127.655 281.985 126.912 282.579 125.984C283.173 125.056 283.452 124.072 283.452 122.995C283.452 121.826 283.192 120.86 282.672 120.118C282.152 119.375 281.465 118.799 280.611 118.354L280.648 118.391Z"
          fill="#808089"
        />
        <path
          d="M290.859 118.02H303.02V116.553H290.859V107.549H304.468V106.045H289.188V128.88H304.95V127.395H290.859V118.02Z"
          fill="#808089"
        />
        <path
          d="M327.618 125.873L312.079 106.045H310.687V128.88H312.339V109.053L327.915 128.88H329.27V106.045H327.618V125.873Z"
          fill="#808089"
        />
        <path
          d="M352.645 107.512C350.825 106.528 348.69 106.045 346.277 106.045H337.235V128.88H346.277C348.69 128.88 350.807 128.398 352.645 127.414C354.483 126.43 355.893 125.093 356.896 123.366C357.899 121.64 358.418 119.672 358.418 117.463C358.418 115.253 357.917 113.267 356.896 111.559C355.893 109.832 354.464 108.496 352.645 107.512ZM355.448 122.605C354.575 124.09 353.35 125.26 351.753 126.114C350.157 126.968 348.3 127.395 346.147 127.395H338.906V107.567H346.147C348.3 107.567 350.175 107.994 351.753 108.848C353.35 109.702 354.575 110.872 355.448 112.357C356.32 113.842 356.748 115.569 356.748 117.5C356.748 119.431 356.32 121.157 355.448 122.642V122.605Z"
          fill="#808089"
        />
        <path
          d="M383.481 109.183C382.423 108.143 381.161 107.326 379.713 106.751C378.265 106.175 376.686 105.878 374.997 105.878C373.308 105.878 371.73 106.175 370.281 106.751C368.833 107.326 367.571 108.143 366.494 109.201C365.417 110.259 364.582 111.485 364.006 112.896C363.412 114.307 363.134 115.829 363.134 117.481C363.134 119.134 363.431 120.656 364.006 122.067C364.6 123.478 365.417 124.703 366.494 125.761C367.571 126.82 368.833 127.636 370.281 128.212C371.73 128.788 373.308 129.085 374.997 129.085C376.686 129.085 378.265 128.788 379.713 128.212C381.161 127.636 382.423 126.82 383.481 125.78C384.54 124.74 385.375 123.515 385.969 122.104C386.563 120.693 386.86 119.171 386.86 117.481C386.86 115.792 386.563 114.269 385.969 112.858C385.375 111.448 384.54 110.241 383.481 109.183ZM384.41 121.454C383.89 122.68 383.184 123.738 382.275 124.647C381.365 125.557 380.288 126.263 379.044 126.764C377.8 127.265 376.464 127.507 374.997 127.507C373.53 127.507 372.194 127.265 370.95 126.764C369.706 126.263 368.629 125.557 367.701 124.647C366.773 123.738 366.067 122.661 365.547 121.454C365.027 120.229 364.786 118.911 364.786 117.481C364.786 116.052 365.046 114.696 365.547 113.49C366.067 112.283 366.773 111.225 367.701 110.315C368.629 109.405 369.706 108.7 370.95 108.199C372.194 107.697 373.53 107.456 374.997 107.456C376.464 107.456 377.8 107.697 379.044 108.199C380.288 108.7 381.365 109.405 382.275 110.315C383.184 111.225 383.908 112.283 384.41 113.49C384.929 114.696 385.171 116.033 385.171 117.481C385.171 118.929 384.911 120.247 384.41 121.454Z"
          fill="#808089"
        />
        <path
          d="M409.548 119.133C409.548 121.974 408.898 124.09 407.598 125.464C406.299 126.838 404.461 127.525 402.084 127.525C399.708 127.525 397.851 126.838 396.533 125.464C395.234 124.09 394.584 121.993 394.584 119.133V106.045H392.913V119.189C392.913 122.457 393.73 124.907 395.364 126.56C396.997 128.212 399.225 129.047 402.047 129.047C404.869 129.047 407.134 128.212 408.768 126.56C410.402 124.907 411.218 122.457 411.218 119.189V106.045H409.548V119.133Z"
          fill="#808089"
        />
        <path
          d="M416.045 107.549H424.269V128.88H425.922V107.549H434.146V106.045H416.045V107.549Z"
          fill="#808089"
        />
        <path
          d="M439.901 110.296C440.829 109.387 441.906 108.681 443.169 108.18C444.412 107.679 445.768 107.437 447.234 107.437C448.552 107.437 449.815 107.642 451.003 108.069C452.191 108.496 453.268 109.183 454.252 110.148L455.292 109.071C454.289 108.013 453.082 107.215 451.69 106.695C450.298 106.175 448.775 105.915 447.179 105.915C445.489 105.915 443.911 106.212 442.482 106.788C441.052 107.363 439.79 108.18 438.713 109.22C437.636 110.259 436.801 111.485 436.225 112.896C435.631 114.307 435.353 115.829 435.353 117.518C435.353 119.208 435.65 120.73 436.225 122.141C436.819 123.552 437.636 124.759 438.713 125.817C439.771 126.857 441.034 127.674 442.482 128.249C443.93 128.825 445.508 129.122 447.197 129.122C448.812 129.122 450.316 128.862 451.709 128.323C453.101 127.785 454.308 126.987 455.31 125.928L454.271 124.852C453.287 125.836 452.21 126.541 451.022 126.95C449.833 127.377 448.571 127.581 447.253 127.581C445.786 127.581 444.45 127.339 443.187 126.838C441.943 126.337 440.848 125.631 439.92 124.722C438.991 123.812 438.286 122.735 437.766 121.528C437.246 120.303 437.005 118.985 437.005 117.555C437.005 116.126 437.265 114.789 437.766 113.583C438.286 112.357 438.991 111.299 439.92 110.389L439.901 110.296Z"
          fill="#808089"
        />
        <path
          d="M469.049 106.045L458.541 128.88H460.342L463.257 122.457H476.494L479.427 128.88H481.228L470.72 106.045H469.049ZM463.888 121.064L469.866 107.883L475.862 121.064H463.888Z"
          fill="#808089"
        />
        <path
          d="M499.18 120.489C500.554 119.876 501.612 118.985 502.336 117.852C503.079 116.701 503.45 115.346 503.45 113.75C503.45 112.153 503.079 110.723 502.336 109.591C501.594 108.44 500.536 107.567 499.18 106.973C497.807 106.361 496.173 106.063 494.261 106.063H486.073V128.899H487.744V121.436H494.261C495.04 121.436 495.764 121.361 496.451 121.268L501.928 128.899H503.822L498.104 120.916C498.493 120.804 498.846 120.656 499.199 120.507L499.18 120.489ZM487.726 119.95V107.549H494.242C496.693 107.549 498.568 108.087 499.83 109.164C501.111 110.241 501.742 111.763 501.742 113.75C501.742 115.736 501.111 117.221 499.83 118.317C498.549 119.412 496.693 119.95 494.242 119.95H487.726Z"
          fill="#808089"
        />
        <path
          d="M525.376 107.512C523.557 106.528 521.422 106.045 519.008 106.045H509.967V128.88H519.008C521.422 128.88 523.538 128.398 525.376 127.414C527.214 126.43 528.625 125.093 529.627 123.366C530.63 121.64 531.15 119.672 531.15 117.463C531.15 115.253 530.649 113.267 529.627 111.559C528.625 109.832 527.195 108.496 525.376 107.512ZM528.179 122.605C527.307 124.09 526.081 125.26 524.485 126.114C522.888 126.968 521.032 127.395 518.878 127.395H511.638V107.567H518.878C521.032 107.567 522.907 107.994 524.485 108.848C526.081 109.702 527.307 110.872 528.179 112.357C529.052 113.842 529.479 115.569 529.479 117.5C529.479 119.431 529.052 121.157 528.179 122.642V122.605Z"
          fill="#808089"
        />
        <path
          d="M548.75 118.391C547.896 117.964 546.968 117.611 545.984 117.333C544.981 117.054 543.979 116.813 542.958 116.572C541.937 116.33 541.008 116.033 540.154 115.699C539.3 115.346 538.632 114.882 538.112 114.288C537.592 113.694 537.351 112.914 537.351 111.93C537.351 111.076 537.574 110.315 538.038 109.628C538.502 108.941 539.207 108.403 540.154 107.976C541.12 107.567 542.326 107.363 543.775 107.363C544.796 107.363 545.854 107.53 546.949 107.827C548.045 108.143 549.084 108.626 550.031 109.275L550.644 107.939C549.771 107.307 548.732 106.806 547.488 106.435C546.262 106.064 545.018 105.878 543.756 105.878C541.881 105.878 540.359 106.156 539.152 106.732C537.964 107.289 537.072 108.032 536.516 108.96C535.959 109.869 535.661 110.872 535.661 111.967C535.661 113.156 535.921 114.139 536.423 114.882C536.942 115.625 537.611 116.219 538.465 116.664C539.319 117.091 540.247 117.444 541.268 117.723C542.289 118.001 543.31 118.242 544.294 118.484C545.297 118.725 546.225 119.022 547.061 119.356C547.915 119.709 548.602 120.173 549.121 120.767C549.641 121.361 549.901 122.141 549.901 123.125C549.901 123.961 549.66 124.703 549.196 125.372C548.731 126.04 548.007 126.578 547.042 126.987C546.077 127.395 544.833 127.581 543.31 127.581C541.788 127.581 540.34 127.302 538.985 126.745C537.629 126.188 536.553 125.52 535.773 124.703L535.03 125.947C535.866 126.857 537.017 127.599 538.539 128.175C540.043 128.75 541.658 129.047 543.348 129.047C545.241 129.047 546.782 128.769 547.989 128.212C549.196 127.655 550.087 126.912 550.681 125.984C551.275 125.056 551.553 124.072 551.553 122.995C551.553 121.826 551.294 120.86 550.774 120.118C550.254 119.375 549.567 118.799 548.713 118.354L548.75 118.391Z"
          fill="#808089"
        />
        <path
          d="M562.433 107.641C562.229 107.177 561.969 106.769 561.616 106.416C561.263 106.063 560.855 105.785 560.372 105.599C559.89 105.395 559.388 105.302 558.831 105.302C558.274 105.302 557.755 105.395 557.29 105.599C556.808 105.804 556.399 106.082 556.047 106.435C555.694 106.787 555.415 107.214 555.211 107.679C555.007 108.143 554.914 108.663 554.914 109.201C554.914 109.739 555.007 110.259 555.211 110.723C555.415 111.187 555.675 111.614 556.028 111.967C556.381 112.32 556.789 112.598 557.272 112.803C557.755 113.007 558.256 113.1 558.813 113.1C559.37 113.1 559.871 113.007 560.354 112.803C560.818 112.598 561.245 112.32 561.598 111.967C561.95 111.614 562.229 111.187 562.433 110.723C562.637 110.259 562.73 109.739 562.73 109.182C562.73 108.625 562.637 108.124 562.433 107.641ZM562.117 110.593C561.932 111.039 561.69 111.41 561.356 111.744C561.022 112.06 560.651 112.32 560.224 112.506C559.797 112.691 559.314 112.784 558.813 112.784C558.311 112.784 557.847 112.691 557.402 112.506C556.975 112.32 556.585 112.06 556.269 111.744C555.954 111.41 555.694 111.039 555.508 110.612C555.322 110.185 555.23 109.721 555.23 109.22C555.23 108.718 555.322 108.254 555.508 107.827C555.694 107.4 555.935 107.01 556.269 106.695C556.603 106.379 556.975 106.119 557.402 105.933C557.847 105.748 558.311 105.655 558.831 105.655C559.351 105.655 559.815 105.748 560.242 105.933C560.669 106.119 561.059 106.36 561.375 106.695C561.69 107.029 561.95 107.4 562.136 107.827C562.322 108.254 562.414 108.718 562.414 109.238C562.414 109.758 562.322 110.203 562.136 110.649L562.117 110.593Z"
          fill="#808089"
        />
        <path
          d="M560.298 109.517C560.613 109.257 560.762 108.885 560.762 108.421C560.762 107.957 560.613 107.604 560.298 107.344C559.982 107.085 559.555 106.936 559.017 106.936H557.309V111.466H557.736V109.906H559.017C559.109 109.906 559.202 109.906 559.295 109.906L560.316 111.485H560.78L559.704 109.832C559.926 109.758 560.112 109.665 560.279 109.535L560.298 109.517ZM558.998 109.535H557.754V107.307H558.998C559.425 107.307 559.759 107.4 560.001 107.604C560.242 107.809 560.353 108.069 560.353 108.421C560.353 108.774 560.242 109.034 560.001 109.238C559.759 109.442 559.425 109.535 558.998 109.535Z"
          fill="#808089"
        />
        <path
          d="M123.178 114.919C123.753 114.715 124.347 114.548 124.923 114.418C125.498 114.288 126.074 114.232 126.649 114.232C129.174 114.232 130.437 115.551 130.437 118.187C130.437 119.115 130.288 120.025 129.973 120.897C129.676 121.77 129.211 122.55 128.636 123.218C128.042 123.886 127.318 124.425 126.464 124.833C125.61 125.242 124.644 125.427 123.549 125.427C123.011 125.427 122.509 125.372 122.045 125.242L121.117 129.085H119.743L123.159 114.938L123.178 114.919ZM122.379 123.979C122.806 124.109 123.252 124.165 123.698 124.165C124.552 124.165 125.313 123.998 125.981 123.664C126.649 123.329 127.206 122.884 127.671 122.327C128.135 121.789 128.469 121.157 128.71 120.47C128.952 119.783 129.063 119.078 129.063 118.391C129.063 117.351 128.84 116.59 128.376 116.108C127.912 115.625 127.225 115.383 126.315 115.383C126 115.383 125.665 115.402 125.35 115.458C125.034 115.513 124.682 115.588 124.347 115.681L122.379 123.961V123.979Z"
          fill="#808089"
        />
        <path
          d="M141.168 117.853C141.168 119.152 140.982 120.285 140.611 121.232C140.239 122.178 139.775 122.977 139.2 123.608C138.624 124.239 137.993 124.703 137.287 125.019C136.582 125.334 135.895 125.483 135.227 125.483C134.744 125.483 134.28 125.409 133.853 125.26C133.407 125.112 133.036 124.87 132.702 124.573C132.368 124.276 132.108 123.886 131.922 123.441C131.736 122.995 131.625 122.475 131.625 121.881C131.625 120.582 131.811 119.449 132.182 118.484C132.553 117.537 133.017 116.739 133.593 116.108C134.168 115.476 134.8 115.012 135.505 114.715C136.211 114.418 136.898 114.251 137.566 114.251C138.049 114.251 138.513 114.325 138.94 114.474C139.367 114.622 139.757 114.864 140.091 115.161C140.425 115.458 140.685 115.848 140.889 116.293C141.075 116.739 141.186 117.259 141.186 117.834L141.168 117.853ZM132.98 121.77C132.98 122.587 133.203 123.199 133.667 123.626C134.131 124.053 134.67 124.258 135.301 124.258C135.802 124.258 136.303 124.128 136.842 123.868C137.362 123.608 137.844 123.218 138.29 122.698C138.736 122.178 139.088 121.529 139.367 120.73C139.645 119.932 139.794 119.004 139.794 117.945C139.794 117.129 139.571 116.516 139.107 116.089C138.643 115.662 138.104 115.458 137.473 115.458C136.972 115.458 136.471 115.588 135.932 115.848C135.412 116.108 134.93 116.497 134.484 117.017C134.038 117.537 133.686 118.187 133.407 118.985C133.129 119.783 132.98 120.712 132.98 121.77Z"
          fill="#808089"
        />
        <path
          d="M145.4 125.223H143.989C143.692 123.701 143.469 122.03 143.283 120.21C143.098 118.391 143.005 116.479 142.986 114.474H144.323C144.323 115.216 144.36 116.015 144.397 116.869C144.434 117.741 144.49 118.595 144.564 119.449C144.639 120.303 144.713 121.12 144.787 121.881C144.861 122.642 144.973 123.274 145.084 123.793C145.53 123.162 145.975 122.475 146.439 121.714C146.904 120.953 147.349 120.155 147.776 119.338C148.203 118.521 148.593 117.685 148.964 116.85C149.336 116.015 149.633 115.216 149.893 114.455H151.285C151.285 115.142 151.285 115.866 151.341 116.683C151.396 117.5 151.415 118.298 151.471 119.134C151.526 119.969 151.601 120.786 151.675 121.584C151.749 122.382 151.842 123.125 151.935 123.793C152.269 123.422 152.677 122.865 153.142 122.104C153.624 121.343 154.107 120.526 154.59 119.635C155.072 118.744 155.536 117.834 155.945 116.924C156.372 116.015 156.688 115.198 156.892 114.474H158.228C157.913 115.495 157.486 116.553 156.985 117.63C156.465 118.707 155.926 119.728 155.351 120.693C154.775 121.658 154.2 122.531 153.643 123.329C153.067 124.128 152.566 124.759 152.139 125.223H150.728C150.58 123.998 150.45 122.68 150.338 121.269C150.208 119.858 150.134 118.465 150.115 117.091C149.837 117.741 149.521 118.447 149.15 119.171C148.779 119.913 148.389 120.637 147.98 121.38C147.572 122.104 147.145 122.809 146.699 123.459C146.254 124.128 145.845 124.703 145.418 125.204L145.4 125.223Z"
          fill="#808089"
        />
        <path
          d="M167.159 116.999C167.159 117.778 166.973 118.41 166.583 118.892C166.193 119.375 165.673 119.765 164.986 120.043C164.3 120.322 163.483 120.526 162.517 120.656C161.552 120.786 160.475 120.879 159.305 120.935V121.38C159.305 121.789 159.343 122.16 159.435 122.494C159.528 122.828 159.677 123.144 159.9 123.404C160.122 123.664 160.438 123.868 160.828 124.016C161.218 124.165 161.738 124.239 162.35 124.239C162.833 124.239 163.316 124.165 163.817 124.035C164.3 123.905 164.782 123.682 165.209 123.404L165.358 124.648C164.894 124.907 164.374 125.112 163.798 125.26C163.223 125.409 162.647 125.483 162.072 125.483C161.311 125.483 160.679 125.39 160.141 125.186C159.621 125 159.194 124.722 158.878 124.369C158.544 124.016 158.321 123.608 158.173 123.125C158.024 122.643 157.969 122.104 157.969 121.529C157.969 120.637 158.099 119.765 158.377 118.892C158.656 118.02 159.046 117.24 159.565 116.535C160.085 115.848 160.735 115.291 161.515 114.864C162.294 114.437 163.167 114.232 164.151 114.232C165.135 114.232 165.859 114.492 166.379 114.994C166.899 115.495 167.159 116.163 167.159 116.999ZM164.021 115.458C163.464 115.458 162.944 115.569 162.462 115.81C161.979 116.033 161.533 116.349 161.143 116.739C160.754 117.129 160.419 117.574 160.141 118.094C159.862 118.614 159.64 119.152 159.51 119.709C160.679 119.691 161.682 119.598 162.48 119.486C163.278 119.356 163.928 119.189 164.429 118.967C164.931 118.744 165.265 118.484 165.488 118.168C165.692 117.853 165.803 117.5 165.803 117.091C165.803 116.553 165.636 116.145 165.321 115.885C164.986 115.606 164.559 115.476 164.04 115.476L164.021 115.458Z"
          fill="#808089"
        />
        <path
          d="M170.055 114.956C170.575 114.752 171.15 114.567 171.782 114.437C172.413 114.288 173.026 114.232 173.638 114.232C173.954 114.232 174.269 114.251 174.548 114.288C174.845 114.325 175.123 114.4 175.383 114.492L175.031 115.718C174.492 115.551 173.935 115.476 173.378 115.476C173.026 115.476 172.654 115.495 172.302 115.551C171.949 115.606 171.577 115.681 171.225 115.792L168.96 125.205H167.604L170.055 114.938V114.956Z"
          fill="#808089"
        />
        <path
          d="M184.498 116.999C184.498 117.778 184.313 118.41 183.923 118.892C183.533 119.375 183.013 119.765 182.326 120.043C181.639 120.322 180.822 120.526 179.857 120.656C178.892 120.786 177.815 120.879 176.645 120.935V121.38C176.645 121.789 176.682 122.16 176.775 122.494C176.868 122.828 177.017 123.144 177.239 123.404C177.462 123.664 177.778 123.868 178.168 124.016C178.558 124.165 179.077 124.239 179.69 124.239C180.173 124.239 180.655 124.165 181.157 124.035C181.639 123.905 182.122 123.682 182.549 123.404L182.698 124.648C182.233 124.907 181.714 125.112 181.138 125.26C180.563 125.409 179.987 125.483 179.412 125.483C178.65 125.483 178.019 125.39 177.481 125.186C176.961 125 176.534 124.722 176.218 124.369C175.884 124.016 175.661 123.608 175.513 123.125C175.364 122.643 175.309 122.104 175.309 121.529C175.309 120.637 175.439 119.765 175.717 118.892C175.995 118.02 176.385 117.24 176.905 116.535C177.425 115.848 178.075 115.291 178.855 114.864C179.634 114.437 180.507 114.232 181.491 114.232C182.475 114.232 183.199 114.492 183.719 114.994C184.239 115.495 184.498 116.163 184.498 116.999ZM181.361 115.458C180.804 115.458 180.284 115.569 179.801 115.81C179.319 116.033 178.873 116.349 178.483 116.739C178.093 117.129 177.759 117.574 177.481 118.094C177.202 118.614 176.979 119.152 176.85 119.709C178.019 119.691 179.022 119.598 179.82 119.486C180.618 119.356 181.268 119.189 181.769 118.967C182.271 118.744 182.605 118.484 182.828 118.168C183.032 117.853 183.143 117.5 183.143 117.091C183.143 116.553 182.976 116.145 182.66 115.885C182.326 115.606 181.899 115.476 181.379 115.476L181.361 115.458Z"
          fill="#808089"
        />
        <path
          d="M192.203 125.353C192.129 125.205 192.055 125.037 191.999 124.796C191.943 124.555 191.888 124.351 191.85 124.128C191.72 124.276 191.553 124.425 191.368 124.573C191.164 124.74 190.922 124.87 190.644 125C190.365 125.13 190.05 125.242 189.715 125.334C189.363 125.427 188.991 125.464 188.583 125.464C187.989 125.464 187.488 125.353 187.061 125.149C186.634 124.926 186.299 124.648 186.021 124.295C185.742 123.942 185.557 123.515 185.427 123.014C185.297 122.531 185.241 122.011 185.241 121.473C185.241 120.6 185.39 119.728 185.668 118.874C185.947 118.02 186.392 117.24 186.968 116.553C187.543 115.866 188.304 115.309 189.196 114.882C190.087 114.455 191.145 114.232 192.37 114.232C192.816 114.232 193.261 114.27 193.726 114.362L194.914 109.35L196.343 109.108L193.354 121.51C193.28 121.751 193.224 121.993 193.206 122.234C193.187 122.475 193.15 122.698 193.15 122.921C193.15 123.329 193.187 123.701 193.261 124.072C193.336 124.425 193.447 124.796 193.633 125.149L192.24 125.353H192.203ZM193.429 115.588C193.243 115.569 193.02 115.532 192.779 115.513C192.537 115.495 192.333 115.476 192.129 115.476C191.164 115.476 190.328 115.643 189.641 116.015C188.936 116.367 188.379 116.832 187.933 117.389C187.488 117.945 187.153 118.577 186.931 119.264C186.708 119.951 186.615 120.619 186.615 121.287C186.615 121.696 186.652 122.086 186.726 122.438C186.801 122.791 186.931 123.107 187.098 123.367C187.265 123.645 187.469 123.849 187.748 123.998C188.007 124.146 188.323 124.221 188.694 124.221C189.047 124.221 189.363 124.165 189.678 124.072C189.994 123.979 190.272 123.849 190.551 123.701C190.829 123.552 191.071 123.385 191.293 123.218C191.516 123.032 191.702 122.865 191.85 122.698C191.85 122.345 191.906 121.937 191.999 121.473C192.092 121.027 192.203 120.563 192.333 120.08L193.447 115.569L193.429 115.588Z"
          fill="#808089"
        />
        <path
          d="M204.159 115.068C204.568 114.789 205.013 114.585 205.496 114.437C205.96 114.288 206.424 114.232 206.888 114.232C208.058 114.232 208.949 114.585 209.506 115.309C210.082 116.033 210.36 116.999 210.36 118.243C210.36 119.134 210.212 120.006 209.896 120.879C209.599 121.751 209.135 122.513 208.541 123.199C207.947 123.886 207.204 124.425 206.313 124.852C205.422 125.279 204.401 125.483 203.231 125.483C202.247 125.483 201.319 125.279 200.428 124.889L204.141 109.368L205.552 109.127L204.122 115.086L204.159 115.068ZM202.043 123.998C202.544 124.146 203.027 124.221 203.51 124.221C204.401 124.221 205.199 124.035 205.886 123.682C206.573 123.329 207.148 122.865 207.594 122.29C208.058 121.714 208.392 121.083 208.634 120.396C208.875 119.709 208.986 119.022 208.986 118.335C208.986 117.333 208.764 116.609 208.337 116.145C207.91 115.681 207.353 115.458 206.684 115.458C206.239 115.458 205.775 115.551 205.273 115.755C204.772 115.959 204.271 116.237 203.788 116.609L202.024 123.961L202.043 123.998Z"
          fill="#808089"
        />
        <path
          d="M208.577 127.636C208.949 127.841 209.376 127.952 209.84 127.952C210.545 127.952 211.251 127.766 211.919 127.377C212.588 126.987 213.237 126.393 213.813 125.613C213.219 123.775 212.773 121.9 212.439 120.006C212.105 118.112 211.901 116.274 211.845 114.492H213.256C213.274 115.161 213.33 115.885 213.386 116.664C213.442 117.463 213.534 118.28 213.664 119.134C213.794 119.988 213.943 120.86 214.128 121.77C214.314 122.661 214.537 123.533 214.797 124.388C215.409 123.404 215.985 122.401 216.468 121.398C216.95 120.396 217.396 119.449 217.767 118.558C218.139 117.667 218.454 116.869 218.696 116.163C218.937 115.458 219.123 114.901 219.234 114.492H220.571C220.496 114.789 220.329 115.272 220.106 115.977C219.884 116.683 219.587 117.5 219.215 118.428C218.844 119.356 218.398 120.359 217.86 121.436C217.322 122.512 216.709 123.552 216.022 124.592C214.945 126.188 213.924 127.339 212.922 128.082C211.919 128.825 210.88 129.177 209.803 129.177C209.561 129.177 209.283 129.159 208.986 129.122C208.689 129.085 208.392 128.973 208.076 128.806L208.559 127.692L208.577 127.636Z"
          fill="#808089"
        />
        <path
          d="M97.6698 4.36338C75.6142 13.6275 70.6387 39.2476 64.9948 59.9108C56.9004 60.672 49.8641 62.807 40.8785 67.1884L40.6929 67.4297C46.838 66.4458 57.086 66.947 62.4328 69.4719C59.0354 83.3402 56.2135 98.0811 48.8245 109.87C40.4515 124.258 18.8972 125.818 8.72341 112.488C-1.15333 100.142 5.92005 78.6989 20.5124 73.3521C4.2863 78.3833 -4.73644 100.643 4.60191 115.31C14.4787 131.462 39.9874 134.692 54.5797 123.237C68.5965 111.244 70.8243 91.7689 74.5931 74.9487C74.9273 73.4078 75.2615 71.8669 75.5956 70.3259C97.6883 69.3605 124.125 71.1614 139.386 51.4079C162.463 21.5363 130.178 -12.1783 97.6698 4.36338ZM122.269 50.6281C108.976 57.9243 92.8242 57.8686 77.9349 59.6509C80.3483 49.18 83.2817 38.7649 88.0344 29.3709C94.6808 16.1895 113.153 7.63087 126.576 15.1313C140.5 23.5599 134.875 43.7404 122.269 50.6281Z"
          fill="#00E0E0"
        />
        <path
          d="M194.635 20.9234C197.457 20.9234 199.908 21.3318 201.95 22.1301C203.992 22.9284 205.682 24.0423 207.018 25.4533C208.337 26.8642 209.339 28.5351 210.007 30.4659C210.676 32.3967 211.01 34.5132 211.01 36.8338C211.01 40.8254 210.397 44.6684 209.191 48.4C207.984 52.1131 206.109 55.4363 203.602 58.3139C201.096 61.1915 197.921 63.5122 194.078 65.2387C190.235 66.9653 185.687 67.8379 180.451 67.8379C179.82 67.8379 179.133 67.8008 178.372 67.7451C177.63 67.6894 176.961 67.5965 176.386 67.4851L172.673 83.0614H164.615L178.818 23.9309C181.25 22.947 183.886 22.2044 186.745 21.6845C189.604 21.1647 192.24 20.9048 194.672 20.9048L194.635 20.9234ZM178.019 60.7459C178.595 60.8573 179.226 60.9316 179.876 60.9687C180.526 61.0058 181.194 61.0058 181.825 61.0058C185.111 61.0058 188.063 60.3746 190.662 59.0936C193.262 57.8312 195.471 56.1046 197.29 53.951C199.11 51.7975 200.502 49.3097 201.486 46.5063C202.47 43.703 202.953 40.7882 202.953 37.725C202.953 36.4625 202.804 35.2186 202.526 33.9933C202.229 32.7866 201.709 31.7098 200.929 30.8001C200.149 29.8718 199.091 29.1478 197.773 28.5908C196.455 28.0524 194.747 27.7739 192.667 27.7739C191.219 27.7739 189.901 27.9039 188.694 28.1638C187.488 28.4237 186.467 28.6651 185.668 28.9064L178.057 60.7645L178.019 60.7459Z"
          fill="#00E0E0"
        />
        <path
          d="M241.253 21.0903C241.94 21.0903 242.72 21.1275 243.592 21.1832C244.465 21.2389 245.3 21.3503 246.154 21.4802C246.99 21.6287 247.751 21.7773 248.456 21.9072C249.143 22.0557 249.663 22.2043 250.016 22.3899L247.677 29.4076C246.526 28.9435 245.189 28.5907 243.704 28.3679C242.2 28.1452 240.752 28.0152 239.378 28.0152C238.171 28.0152 236.964 28.108 235.776 28.2751C234.588 28.4422 233.567 28.6836 232.694 28.962L223.523 67.058H215.466L225.862 23.7637C228.164 22.9469 230.652 22.2971 233.307 21.8144C235.962 21.3317 238.617 21.0718 241.272 21.0718L241.253 21.0903Z"
          fill="#00E0E0"
        />
        <path
          d="M263.976 68.1906C259.242 68.1906 255.529 66.7982 252.856 63.9949C250.164 61.1915 248.827 57.3485 248.827 52.4287C248.827 49.0869 249.31 45.5595 250.257 41.865C251.204 38.1705 252.726 34.7731 254.805 31.6541C256.884 28.5351 259.558 25.9731 262.863 23.9495C266.149 21.9259 270.14 20.9233 274.8 20.9233C279.46 20.9233 283.247 22.3157 285.921 25.1191C288.594 27.9225 289.949 31.7655 289.949 36.6853C289.949 40.027 289.467 43.5545 288.52 47.249C287.573 50.9435 286.051 54.3409 283.971 57.4599C281.892 60.5788 279.2 63.1409 275.914 65.1645C272.628 67.1881 268.636 68.1906 263.958 68.1906H263.976ZM265.35 61.3586C267.949 61.3586 270.27 60.616 272.312 59.1122C274.354 57.6084 276.1 55.6962 277.511 53.3569C278.922 51.0177 279.998 48.4371 280.759 45.6152C281.502 42.7933 281.892 40.0456 281.892 37.3908C281.892 34.4389 281.261 32.1182 279.98 30.3731C278.699 28.6279 276.508 27.7739 273.408 27.7739C270.808 27.7739 268.488 28.5166 266.446 30.0203C264.403 31.5241 262.658 33.4364 261.247 35.7756C259.836 38.1148 258.76 40.6954 257.998 43.5173C257.237 46.3393 256.866 49.0869 256.866 51.7418C256.866 54.6936 257.497 57.0143 258.778 58.7594C260.059 60.5046 262.25 61.3586 265.35 61.3586Z"
          fill="#00E0E0"
        />
        <path
          d="M346.648 21.0903C349.191 21.0903 351.345 21.4802 353.09 22.2599C354.835 23.0397 356.265 24.0979 357.342 25.416C358.418 26.7527 359.18 28.2937 359.644 30.0574C360.108 31.8211 360.331 33.6776 360.331 35.6455C360.331 36.908 360.238 38.2818 360.034 39.7485C359.829 41.2151 359.551 42.6447 359.217 44.037L353.684 67.058H345.627L350.732 45.7636C351.029 44.4455 351.345 42.9603 351.735 41.308C352.106 39.6556 352.292 38.0405 352.292 36.4253C352.292 35.3299 352.162 34.2531 351.902 33.232C351.642 32.1924 351.215 31.3013 350.602 30.5401C349.99 29.7975 349.136 29.1848 348.04 28.7207C346.945 28.2565 345.59 28.0338 343.975 28.0338C342.118 28.0338 340.336 28.3865 338.609 29.1105C336.883 29.8346 335.435 30.5772 334.283 31.3198C334.395 32.1367 334.506 32.9164 334.581 33.6962C334.673 34.4759 334.71 35.2371 334.71 35.9983C334.71 37.3164 334.618 38.6717 334.413 40.0269C334.209 41.3822 333.968 42.7189 333.671 44.0556L328.138 67.0766H320.081L325.186 45.7822C325.484 44.4641 325.799 42.9603 326.133 41.2894C326.486 39.6185 326.653 37.9476 326.653 36.2768C326.653 35.1814 326.542 34.1232 326.3 33.1207C326.059 32.1181 325.688 31.227 325.131 30.4844C324.574 29.7418 323.85 29.1477 322.922 28.7021C321.993 28.2751 320.824 28.0523 319.376 28.0523C317.927 28.0523 316.535 28.1451 315.18 28.3122C313.825 28.4793 312.692 28.7207 311.764 28.9992L302.592 67.0952H294.535L304.932 23.8009C307.531 22.984 310.371 22.3342 313.453 21.8515C316.535 21.3688 319.301 21.1089 321.715 21.1089C324.128 21.1089 326.189 21.5359 327.86 22.3713C329.531 23.2068 330.867 24.3207 331.833 25.6945C332.408 25.2861 333.207 24.822 334.209 24.3021C335.212 23.7823 336.381 23.2996 337.681 22.8355C338.981 22.3713 340.391 21.9629 341.914 21.6287C343.436 21.276 345.014 21.1089 346.629 21.1089L346.648 21.0903Z"
          fill="#00E0E0"
        />
        <path
          d="M392.078 20.9234C394.9 20.9234 397.35 21.3318 399.392 22.1301C401.435 22.9284 403.124 24.0423 404.461 25.4533C405.779 26.8642 406.781 28.5351 407.45 30.4659C408.118 32.3967 408.452 34.5132 408.452 36.8338C408.452 40.8254 407.84 44.6684 406.633 48.4C405.426 52.1131 403.551 55.4363 401.045 58.3139C398.538 61.1915 395.364 63.5122 391.521 65.2387C387.678 66.9653 383.129 67.8379 377.894 67.8379C377.263 67.8379 376.576 67.8008 375.814 67.7451C375.072 67.6894 374.404 67.5965 373.828 67.4851L370.115 83.0614H362.058L376.26 23.9309C378.692 22.947 381.328 22.2044 384.187 21.6845C387.047 21.1647 389.683 20.9048 392.115 20.9048L392.078 20.9234ZM375.443 60.7459C376.019 60.8573 376.65 60.9316 377.3 60.9687C377.95 61.0058 378.618 61.0058 379.249 61.0058C382.535 61.0058 385.487 60.3746 388.086 59.0936C390.685 57.8312 392.895 56.1046 394.714 53.951C396.533 51.7975 397.926 49.3097 398.91 46.5063C399.894 43.703 400.376 40.7882 400.376 37.725C400.376 36.4625 400.228 35.2186 399.949 33.9933C399.652 32.7866 399.133 31.7098 398.353 30.8001C397.573 29.8718 396.515 29.1478 395.197 28.5908C393.879 28.0524 392.171 27.7739 390.091 27.7739C388.643 27.7739 387.325 27.9039 386.118 28.1638C384.911 28.4237 383.89 28.6651 383.092 28.9064L375.48 60.7645L375.443 60.7459Z"
          fill="#00E0E0"
        />
        <path
          d="M428.094 68.283C423.768 68.283 420.594 67.3361 418.57 65.4239C416.546 63.5117 415.544 60.7454 415.544 57.1066C415.544 54.7489 415.952 51.7041 416.751 48.0096L426.015 9.48664L434.406 8.09424L431.027 22.0368H446.344L444.691 28.7946H429.375L424.622 48.7151C423.935 51.4257 423.583 53.802 423.583 55.8071C423.583 57.7193 424.103 59.0931 425.142 59.9286C426.182 60.764 427.908 61.191 430.34 61.191C432.011 61.191 433.664 60.9125 435.279 60.3741C436.894 59.8357 438.138 59.3159 438.992 58.8518L439.605 65.6838C438.732 66.2036 437.265 66.7606 435.186 67.3733C433.107 67.9859 430.749 68.283 428.094 68.283Z"
          fill="#00E0E0"
        />
        <path
          d="M454.457 67.058H446.399L457.223 22.0557H465.28L454.457 67.058Z"
          fill="#00E0E0"
        />
        <path
          d="M463.795 13.9055C462.458 13.9055 461.363 13.497 460.453 12.6987C459.562 11.8818 459.116 10.7679 459.116 9.31983C459.116 7.4633 459.692 6.0152 460.843 4.93841C461.994 3.86163 463.293 3.3418 464.741 3.3418C466.06 3.3418 467.192 3.7688 468.12 4.60424C469.049 5.43968 469.513 6.60929 469.513 8.11308C469.513 9.83966 468.9 11.2506 467.693 12.3088C466.487 13.3856 465.187 13.9055 463.795 13.9055Z"
          fill="#00E0E0"
        />
        <path
          d="M475.843 67.058H467.786L478.183 23.7637C480.838 22.9469 483.715 22.2971 486.797 21.8144C489.879 21.3317 492.645 21.0718 495.059 21.0718C497.658 21.0718 499.886 21.4245 501.761 22.1486C503.636 22.8726 505.158 23.8566 506.309 25.1005C507.46 26.3443 508.314 27.8481 508.871 29.5933C509.41 31.357 509.688 33.2692 509.688 35.3485C509.688 36.6852 509.577 38.0776 509.335 39.5443C509.112 41.0109 508.815 42.4961 508.463 43.9999L502.93 67.0209H494.873L499.978 45.7265C500.387 44.1113 500.777 42.459 501.148 40.7881C501.519 39.1173 501.705 37.5206 501.705 36.0169C501.705 33.7148 501.074 31.784 499.793 30.2616C498.53 28.7392 496.154 27.9595 492.701 27.9595C491.253 27.9595 489.842 28.0523 488.449 28.2194C487.057 28.3865 485.906 28.6279 484.978 28.9063L475.806 67.0024L475.843 67.058Z"
          fill="#00E0E0"
        />
        <path
          d="M525.654 76.415C528.012 76.415 530.091 76.1179 531.892 75.5424C533.674 74.9669 535.215 74.1314 536.515 73.0361C537.815 71.9407 538.873 70.6411 539.671 69.1374C540.488 67.6336 541.119 65.9256 541.583 64.0319L541.936 62.3796C540.785 63.0108 539.225 63.642 537.258 64.2918C535.29 64.923 533.285 65.2386 531.205 65.2386C526.527 65.2386 522.944 64.069 520.419 61.7298C517.912 59.3906 516.65 56.1231 516.65 51.9087C516.65 48.0472 517.281 44.2598 518.562 40.5653C519.825 36.8708 521.774 33.5848 524.355 30.6886C526.954 27.811 530.184 25.4717 534.046 23.7266C537.907 21.9815 542.437 21.0903 547.636 21.0903C548.452 21.0903 549.344 21.146 550.365 21.2574C551.367 21.3688 552.407 21.5359 553.484 21.7401C554.56 21.9443 555.581 22.2228 556.603 22.557C557.624 22.8912 558.496 23.2996 559.239 23.7637L549.808 63.1593C549.065 66.2783 548.137 69.0817 547.041 71.5508C545.946 74.0386 544.442 76.1365 542.549 77.863C540.636 79.5896 538.186 80.9449 535.197 81.8917C532.208 82.8386 528.439 83.3212 523.946 83.3212C521.458 83.3212 519.008 82.9871 516.594 82.3187C514.181 81.6504 512.12 80.8335 510.449 79.8495L513.475 73.0918C514.794 74.0757 516.557 74.874 518.748 75.4681C520.939 76.0808 523.241 76.3778 525.673 76.3778L525.654 76.415ZM532.932 58.4994C535.234 58.4994 537.35 58.0724 539.3 57.237C541.231 56.4016 542.716 55.6032 543.755 54.8606L550.068 28.535C549.492 28.4236 548.824 28.3122 548.081 28.1823C547.338 28.0709 546.614 28.0152 545.909 28.0152C542.79 28.0152 539.931 28.6093 537.332 29.7975C534.733 30.9856 532.505 32.5637 530.63 34.5687C528.755 36.5552 527.306 38.8759 526.267 41.4936C525.227 44.1299 524.707 46.8404 524.707 49.6809C524.707 52.7999 525.394 55.0463 526.75 56.4387C528.105 57.8311 530.166 58.518 532.932 58.518V58.4994Z"
          fill="#00E0E0"
        />
        <path
          d="M573.794 61.4324C575.001 61.4324 576.227 61.321 577.47 61.0796C578.714 60.8569 579.81 60.467 580.756 59.9472C581.703 59.4273 582.483 58.7218 583.096 57.8307C583.708 56.9396 584.005 55.8257 584.005 54.489C584.005 53.6164 583.857 52.8552 583.578 52.1869C583.3 51.5185 582.836 50.9059 582.186 50.3303C581.555 49.7548 580.756 49.1978 579.81 48.6409C578.863 48.1025 577.712 47.4713 576.394 46.7844C575.057 46.1531 573.85 45.5219 572.755 44.8721C571.659 44.2409 570.694 43.4797 569.859 42.6257C569.023 41.7532 568.373 40.7321 567.909 39.5439C567.445 38.3557 567.222 36.9262 567.222 35.2553C567.222 30.9296 568.782 27.4579 571.901 24.8216C575.02 22.2039 579.55 20.8857 585.491 20.8857C588.257 20.8857 590.689 21.1642 592.805 21.7026C594.903 22.2596 596.37 22.7608 597.168 23.225L594.142 29.7971C593.325 29.333 592.026 28.8503 590.243 28.3304C588.461 27.8106 586.456 27.5507 584.265 27.5507C583.17 27.5507 582.075 27.6621 580.979 27.9034C579.884 28.1262 578.881 28.5161 577.99 29.073C577.099 29.63 576.375 30.3355 575.818 31.2452C575.261 32.1363 575.001 33.2317 575.001 34.4941C575.001 36.0536 575.614 37.3346 576.821 38.3557C578.027 39.3582 579.847 40.4165 582.279 41.5118C583.783 42.1987 585.119 42.9042 586.307 43.6282C587.496 44.3523 588.498 45.1506 589.334 46.0603C590.169 46.9514 590.8 48.0097 591.246 49.2164C591.673 50.4232 591.896 51.8712 591.896 53.5421C591.896 55.9742 591.432 58.1092 590.503 59.9472C589.575 61.8037 588.294 63.326 586.642 64.5328C584.989 65.7395 583.059 66.6492 580.794 67.2619C578.547 67.8745 576.097 68.1716 573.442 68.1716C571.474 68.1716 569.71 68.0416 568.114 67.7817C566.517 67.5218 565.143 67.2248 563.955 66.872C562.767 66.5193 561.783 66.1665 561.003 65.7952C560.223 65.4239 559.629 65.1083 559.221 64.8855L562.34 58.3134C562.748 58.5362 563.305 58.8332 564.029 59.186C564.753 59.5387 565.607 59.8729 566.628 60.2256C567.649 60.5784 568.745 60.8569 569.97 61.0982C571.177 61.321 572.458 61.4509 573.776 61.4509L573.794 61.4324Z"
          fill="#00E0E0"
        />
        <path
          d="M97.6698 4.36338C75.6142 13.6275 70.6387 39.2476 64.9948 59.9108C56.9004 60.672 49.8641 62.807 40.8785 67.1884L40.6929 67.4297C46.838 66.4458 57.086 66.947 62.4328 69.4719C59.0354 83.3402 56.2135 98.0811 48.8245 109.87C40.4515 124.258 18.8972 125.818 8.72341 112.488C-1.15333 100.142 5.92005 78.6989 20.5124 73.3521C4.2863 78.3833 -4.73644 100.643 4.60191 115.31C14.4787 131.462 39.9874 134.692 54.5797 123.237C68.5965 111.244 70.8243 91.7689 74.5931 74.9487C74.9273 73.4078 75.2615 71.8669 75.5956 70.3259C97.6883 69.3605 124.125 71.1614 139.386 51.4079C162.463 21.5363 130.178 -12.1783 97.6698 4.36338ZM122.269 50.6281C108.976 57.9243 92.8242 57.8686 77.9349 59.6509C80.3483 49.18 83.2817 38.7649 88.0344 29.3709C94.6808 16.1895 113.153 7.63087 126.576 15.1313C140.5 23.5599 134.875 43.7404 122.269 50.6281Z"
          fill="#00E0E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_7013_30211">
          <rect
            width={596.336}
            height={130.05}
            fill="white"
            transform="translate(0.832031)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
