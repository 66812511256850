const styles = {
  dashboardNavContainer: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    width: '200px',
    height: '100vh',
    background: '#fff',
    zIndex: 3000,
    transition: 'left 0.3s ease-in-out',
    '@media (max-width: 668px)': {
      left: '-200px',
    },
  },
  bottomNavWrapper: {
    top: 'initial',
    bottom: 0,
    height: 'auto',
    width: '100vw',
    textAlign: 'center' as const,
    zIndex: 100,
    display: 'flex',
    flexWrap: 'nowrap' as const,
  },
  bottomNav: {
    display: 'flex',
    a: {
      position: 'relative' as const,
      top: 0,
      left: 0,
      padding: 0,
      svg: {
        position: 'relative' as const,
        transform: 'translate(0, 0)',
        top: 0,
        left: 0,
      },
    },
  },
  rootNavWrapper: {
    zIndex: 200,
    '@media (min-width: 669px)': {
      display: 'none',
    },
  },
  dashboardWrapper: {
    width: '100%',
    height: '100vh',
    transition: 'left 0.3s ease-in-out',
    backgroundColor: '#fff',
    '@media (max-width: 668px)': {
      position: 'fixed' as const,
      top: 0,
      bottom: '65px',
      left: 0,
      right: 0,
      width: 'auto',
      height: 'auto',
      overflow: 'hidden',
    },
  },
  dashboardContainer: (isAccountPage: boolean) => ({
    position: 'absolute' as const,
    width: '100%',
    background: '#f5f6f7',
    height: '100%',
    borderRadius: '32px',
    border: '16px solid transparent',
    overflowY: 'auto' as const,
    '@media (max-width: 760px)': isAccountPage
      ? {
          height: 'calc(100vh - 125px)',
          width: '100%',
        }
      : {},
    // '@media (max-width: 668px)': {
    //   width: '100%',
    //   overflowY: 'scroll' as const,
    //   '-webkit-overflow-scrolling': 'touch',
    // },
  }),
}

export default styles
