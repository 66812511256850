import { PlanDescriptionFragment } from 'src/legacy_graphql'

class Detail {
  detail: string
  innerDetails: Detail[] | undefined
  isIncluded: boolean | undefined
  constructor(
    detail: string,
    innerDetails: Detail[] | undefined,
    isIncluded?: boolean,
  ) {
    this.detail = detail
    this.innerDetails = innerDetails
    this.isIncluded = isIncluded
  }

  static fromObj(obj: Detail): Detail {
    return new Detail(
      obj.detail,
      obj.innerDetails?.map(Detail.fromObj),
      obj.isIncluded,
    )
  }
}

export class PlanDescription {
  plan: string
  secondaryTitle: string | undefined
  description: string | undefined
  details: Detail[] | undefined
  disclaimer: string | undefined
  isFeatured: boolean | undefined
  isPromotional: boolean | undefined
  order: number | undefined
  purchaseButtonText: string | undefined
  shouldIgnore: boolean | undefined
  saleorVariantId: string | undefined

  constructor(
    plan: string,
    secondaryTitle?: string,
    description?: string,
    details?: Detail[],
    disclaimer?: string,
    isFeatured?: boolean,
    isPromotional?: boolean,
    order?: number,
    purchaseButtonText?: string,
    shouldIgnore?: boolean,
    saleorVariantId?: string,
  ) {
    this.plan = plan
    this.secondaryTitle = secondaryTitle
    this.description = description
    this.details = details
    this.disclaimer = disclaimer
    this.isFeatured = isFeatured
    this.isPromotional = isPromotional
    this.order = order
    this.purchaseButtonText = purchaseButtonText
    this.shouldIgnore = shouldIgnore
    this.saleorVariantId = saleorVariantId
  }

  static fromObj(obj: PlanDescription): PlanDescription {
    return new PlanDescription(
      obj.plan,
      obj.secondaryTitle,
      obj.description,
      obj.details?.map(Detail.fromObj),
      obj.disclaimer,
      obj.isFeatured,
      obj.isPromotional,
      obj.order,
      obj.purchaseButtonText,
      obj.shouldIgnore,
      obj.saleorVariantId,
    )
  }
}

export class PlansDescription {
  id: string
  description: PlanDescription

  constructor(id: string, description: PlanDescription) {
    this.id = id
    this.description = description
  }

  static fromPlansDescriptionFragment(
    fragment: PlanDescriptionFragment,
  ): PlansDescription {
    try {
      const descriptionObj = JSON.parse(fragment.description)
      return new PlansDescription(
        fragment.id,
        PlanDescription.fromObj(descriptionObj),
      )
    } catch (e) {
      return new PlansDescription(fragment.id, new PlanDescription('Failed'))
    }
  }
}
