import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type WidgetCardProps = {
  backgroundColor?: string
  borderRadius?: string
  paddingOverride?: string
  shouldHideShadow?: boolean
  alignment?: 'stretch' | 'start' | 'center'
  minWidth?: string
}

export const WidgetCard: React.FC<WidgetCardProps> = ({
  backgroundColor = '#FFFFFF',
  borderRadius = '24px',
  paddingOverride,
  shouldHideShadow,
  children,
  alignment = 'center',
  minWidth,
}) => {
  return (
    <Flex
      minWidth={minWidth}
      position="relative"
      width="100%"
      justifyContent="center"
      alignItems={
        alignment === 'center'
          ? 'center'
          : alignment === 'stretch'
          ? 'space-between'
          : 'start'
      }
      style={{
        background: backgroundColor,
        borderRadius: borderRadius,
        padding: paddingOverride ?? '8px 16px 24px 16px',
        boxShadow: shouldHideShadow
          ? 'none'
          : '0px 4px 32px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Flex width="100%" flexDirection="column">
        {children}
      </Flex>
    </Flex>
  )
}
