import {
  Button,
  Div,
  Flex,
  Icon as QIcon,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import {
  useActions,
  useCallback,
  useEffect,
  usePlansSubscriptions,
  useQueries,
  useQueryParams,
  useSelector,
  useSponsorFromQueryParam,
  useState,
} from 'src/hooks'
import {
  activePlans,
  legacyPlans,
  tableFeatures,
} from 'src/points_conversion/data/pointsConversionData'
import {
  ActivePlan,
  FeatureType,
  PlanData,
} from 'src/points_conversion/data/type'
import PlanUpsale from 'src/plan_upsale/PlanUpsale'
import { PricingRoute } from '../marketing/plans/routes/PricingRoute'
import { GreetingCardRibbon } from './components/GreetingCardRibbon'
import { GlassBackgroundContainer } from './components/GlassBackground'
import { PlanTiles } from 'src/pricing_page/components/pricingTile/PlanTiles'
import { ContentBlock } from './components/ContentBlock'
import { getPricingPage } from 'src/legacy_graphql'

import { FeatureItemChart } from './components/featureChart/FeatureChart'

import { DialogUpsaleCheckout } from 'src/upsale/components/checkout/DialogUpsaleCheckout'
import { AddOnTiles } from 'src/pricing_page/components/addOnTile/AddOnTiles'
import { LegacyPlanTile } from './components/legacyPlanTile/LegacyPlan'
import { PromoPlanTile } from './components/promoPlanTile/PromoPlanTile'
import { navigateAndScroll } from './utils'
import { BlobType, SectionContainer } from './components/SectionContainer'
import { ComparisonChart } from './ComparisonChart'
// import { useCurrentPlanSku } from 'src/hooks/useCurrentPlanSku'
import { FeatureCardGridSection } from './FeatureCardGridSection'
import { SubscriptionCheckout } from 'src/saleor/components/subscription_checkout/SubscriptionCheckout'
import { ShoppingCart } from 'src/saleor/components/shopping_cart/ShoppingCart'
import {
  SinglePlanTile,
  supportedAffiliateCountries,
} from './components/singlePlanTile/SinglePlanTile'
import { PRICING_CONTEXT } from 'src/context/types'
import useAddProductFromQueryParam from './hooks/useAddProductFromQueryParam'
import { browserHistory } from 'src/redux/browserHistory'
import { SubscriptionType } from 'src/user/fragments'
import { PlanFragment } from 'src/graphql/generated/graphql'
import { usePlans } from 'src/react_query'
import { AFFILIATE_BRANDING } from 'src/app/constants'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

const CURRENT_LEGACY_PLAN_IDS = [
  '4',
  '39',
  '40',
  '42',
  '43',
  '121',
  '122',
  '123',
  '124',
  '125',
  '126',
  '127',
  '128',
  '129',
]

const ribbonCards = [
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/Sd4KK5Qy_K8ms572AqSysfbhxtQ=/fit-in/700x0/system_images/2022/2/18/c7105cf9-fd46-46cb-a1aa-e2895150ce1a.png',
    isHorizontal: true,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/5sq55wkhFTnzMl_QAZERNaOCx-8=/fit-in/700x0/system_images/2021/3/4/3a793eba-5de2-439a-aa3a-14672c8aadcf.png',
    isHorizontal: false,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/TlBb2kE-MbND7F_4may4r3MN_DU=/fit-in/700x0/system_images/2019/12/4/29642b77-539b-45a6-bd76-356286c4ec45.png',
    isHorizontal: false,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/IC0l2qM2Ed_Jp7S8aTR0NmbgGo8=/fit-in/700x0/system_images/2020/3/27/f5632148-98d1-4294-81e8-182833fad0c1.png',
    isHorizontal: true,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/mahUlIov73duPktAmCgMOhq_oXo=/fit-in/700x0/high_res_catalog_image/2017/3/17/0/spring_front.png',
    isHorizontal: false,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/9Xy6UHNs8EoFaGp_xyBk5CzD_lk=/fit-in/700x0/system_images/2019/12/4/823e9cca-58a7-4d3a-978c-aee47d3a5c75.png',
    isHorizontal: false,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/0MNyTUIJ59tbLBw2n9ubQZKcM7s=/fit-in/700x0/system_images/2023/4/5/5c83f228-2278-4325-84c6-9999a9220704.png',
    isHorizontal: true,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/X0NVEbxpSdAmmNwVMS9ys2o4-2Y=/fit-in/700x0/system_images/2023/3/17/b58a1954-50e5-4c42-980e-474d37215844.png',
    isHorizontal: false,
  },
  {
    previewUrl:
      'https://www.sendoutcards.com/thumbnail/mXeM5PvgvIUfJ_jWCNo0_cQzPAM=/fit-in/700x0/system_images/2022/11/18/122f2557-5498-4f88-84cd-c47024a257b8.png',
    isHorizontal: false,
  },
]

type PricingPageProps = {
  route?: PricingRoute
}

export const PricingPage: React.FC<PricingPageProps> = ({ route }) => {
  const actions = useActions()
  const account = useSelector(state => state.user.account)
  const context = useSelector(state => state.context)
  const isCheckoutOpen =
    context.kind === PRICING_CONTEXT ? context.isCheckoutOpen : false
  const isInAffiliateCountry =
    account &&
    supportedAffiliateCountries.some(
      country => country.name === account.shippingAddress?.country,
    )

  const isInIncognitoCountry = account && !account.shippingAddress
  const shouldDisplayAffiliateBanner =
    !account || isInAffiliateCountry || isInIncognitoCountry
  const hasEverHadSamplePack = account && account.hasEverHadSamplePack

  const { selected: selectedPlanId, removeQueryParams } = useQueryParams()
  const { data: plans } = usePlans()
  const [pages] = useQueries(getPricingPage())
  useAddProductFromQueryParam()

  const allPlans = { ...legacyPlans, ...activePlans }
  const [shouldOpenPlanUpsale, setShouldOpenPlanUpsale] = useState(false)
  const [selPlan, setSelPlan] = useState<PlanFragment | undefined>(undefined)
  const [shouldShowComparisonChart, setShouldShowComparisonChart] = useState(
    false,
  )

  const currentPlanId = account?.plan.id
  const isPlanLegacy = CURRENT_LEGACY_PLAN_IDS.find(l => l === currentPlanId)

  const { getCurrentPlanSku } = usePlansSubscriptions()
  const currentPlanSku = getCurrentPlanSku()
  const affiliateSubscription = account?.subscriptions.find(
    (sub: SubscriptionType) => sub.sku === 'GS00001' || sub.sku === 'GS00012',
  )

  const hasAffiliateSubscription =
    (affiliateSubscription && !affiliateSubscription.isSetToCancel) ?? false
  // eslint-disable-next-line no-empty-pattern
  const [] = (Object.keys(allPlans) as Array<keyof typeof allPlans>)
    .filter(key => {
      const plan = allPlans[key]
      return plan.sku === currentPlanSku
    })
    .map(key => allPlans[key])
  const [selectedPlanData, setSelectedPlanData] = useState<
    PlanData<ActivePlan>
  >(activePlans[ActivePlan.HRFLT])

  const isSubscription =
    selectedPlanData.id === allPlans['Heartfelt Subscription'].id ||
    selectedPlanData.id === allPlans['Heartfelt Plus Gifts Subscription'].id ||
    selectedPlanData.id === allPlans['Heartfelt Plus Subscription'].id

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeSelected = useCallback(() => removeQueryParams(['selected']), [])
  const { sponsor } = useSponsorFromQueryParam()

  useEffect(() => {
    if (selectedPlanId && selectedPlanId !== currentPlanId) {
      const queryParamPlan = plans?.find(value => value.id === selectedPlanId)
      if (queryParamPlan) {
        setSelPlan(queryParamPlan)
        removeSelected()
      }
    }
  }, [
    selectedPlanId,
    currentPlanId,
    plans,
    setSelPlan,
    actions,
    removeSelected,
  ])

  const page = pages.pricingPage.find(page => page.id === '3')
  const isComparisonChartEnabled = page?.id === '1'

  const filteredPlanSections = page?.sections.filter(
    section => section.plantilesection,
  )

  useEffect(() => {
    const hash = window.location.hash.replace('#', '')
    const decodedHash = decodeURIComponent(hash)

    const expectedStore = 'pricing'

    const searchParams = new URLSearchParams(window.location.search)
    const store = searchParams.get('store')

    if (store !== expectedStore) {
      searchParams.delete('checkout')
      searchParams.delete('store')
      searchParams.append('store', expectedStore)
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`
      browserHistory.push(newUrl)
      // Window popstate event doesn't trigger when history state changes so we dispatch a custom event
      // See Checkout context for this event listener
      window.dispatchEvent(new Event('update_checkout'))
    }
    if (decodedHash) {
      navigateAndScroll(decodedHash)
    }
  }, [])

  const filteredGridSections = page?.sections.filter(
    section => section.pricinggrid,
  )

  const createBoldSentence = (sentence: string) => {
    const words = sentence.trim().split(' ')
    const lastWord = words[words.length - 1]
    const trimmedSentence = words.slice(0, -1).join(' ')
    return { title: trimmedSentence, bolded: lastWord }
  }

  const generateSectionId = (sectionOrder: number) => {
    return `${sectionOrder + 1}`
  }

  const isTablet = useSelector(state => state.window.width < 950)
  const isMobile = useSelector(state => state.window.width <= 520)
  const shouldStackPromoTile = useSelector(state => state.window.width <= 840)

  const heroPlanTiles = filteredPlanSections?.[0]
  const heroTitleStyle = {
    fontWeight: 400,
    fontSize: isMobile ? '16px' : isTablet ? '20px' : '24px',
    color: '#323232',
  }
  const heroSectionFeatureChart = heroPlanTiles?.plantilesection?.featureChart
    ? JSON.parse(heroPlanTiles.plantilesection.featureChart)
    : null

  const addOnTileIds = [{ id: '141' }, { id: '142' }, { id: '143' }]

  const [
    shouldShowEnterprisePlanTiles,
    setShouldShowEnterprisePlanTiles,
  ] = useState(false)

  const shouldShowSuggestedAffiliateInCart =
    hasAffiliateSubscription && shouldDisplayAffiliateBanner
  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      backgroundColor="#fcfcfc"
      rowGap="64px"
    >
      <Div
        position="fixed"
        zIndex={400}
        top={isMobile ? '56px' : '65px'}
        right={isTablet ? '8px' : '40px'}
      >
        <ShoppingCart
          onProceed={() => {
            actions.checkoutOpenState(true)
          }}
          planAddon={
            !shouldShowSuggestedAffiliateInCart
              ? {
                  sku: 'GS00017',
                  variantId: 'UHJvZHVjdFZhcmlhbnQ6MTk4',
                }
              : undefined
          }
        />
      </Div>
      <SubscriptionCheckout
        isOpen={!!isCheckoutOpen}
        setIsOpen={actions.checkoutOpenState}
      />
      {page && (
        <>
          <GlassBackgroundContainer
            blobStart="center"
            blobs={page?.uiBlobs ? JSON.parse(page?.uiBlobs) : []}
          >
            {/* -------- Hero Container | Main Logo & GreetingCardRibbon ---------- */}
            <Flex
              width="100%"
              rowGap="32px"
              flexDirection="column"
              maxWidth="1100px"
              justifyContent="center"
              alignItems="center"
              inset={{
                top: isMobile ? '16px' : isTablet ? '20px' : '32px',
                horizontal: isMobile ? 'x1_5' : isTablet ? 'x2' : 'x4',
              }}
            >
              <Flex flexDirection="column" width="inherit">
                <Text
                  type={isMobile ? 'caption' : 'body'}
                  content={page.heroTitle}
                  inset={{ left: isTablet ? 'x2' : undefined }}
                  style={heroTitleStyle}
                  whiteSpace="normal"
                  alignment={isMobile ? 'left' : 'center'}
                />
                <Flex
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  outset={{
                    vertical: isMobile ? 'x0' : 'x1',
                  }}
                >
                  <Icon name={'promptings_powered_by_soc_logo'} />
                </Flex>
                <Text
                  outset="x2"
                  type={isMobile ? 'caption' : 'largeBody'}
                  content={page.heroDescription}
                  style={{
                    fontSize: isTablet ? 14 : 16,
                    color: '#404040',
                  }}
                  alignment="center"
                />
              </Flex>
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              width="1100px"
              outset={{
                bottom: isMobile ? '4px' : isTablet ? '24px' : '46px',
                horizontal: '32px',
              }}
            >
              <GreetingCardRibbon
                cards={ribbonCards}
                cardWidth={'110px'}
                cardHeight={'154px'}
              />
            </Flex>
            {/* -------- Subscriptions ---------- */}
            {heroPlanTiles && heroPlanTiles.plantilesection && (
              <>
                <ContentBlock
                  caption={heroPlanTiles.plantilesection.title}
                  title={createBoldSentence(
                    heroPlanTiles.plantilesection?.subtitle,
                  )}
                  body={[heroPlanTiles.plantilesection.description]}
                />
                <Flex
                  justifyContent="center"
                  id={generateSectionId(heroPlanTiles.plantilesection.order)}
                  width="100%"
                  overflow="visible"
                  alignItems="center"
                  style={{
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#888 transparent',
                  }}
                  inset={{ vertical: isMobile ? '24px' : '44px' }}
                >
                  <PlanTiles
                    planIds={heroPlanTiles.plantilesection.planIds}
                    onSelect={(plan: PlanFragment) => setSelPlan(plan)}
                    onProceedToCheckout={() => actions.checkoutOpenState(true)}
                    layout="grid"
                    initialMobileElement={5}
                  />
                </Flex>
                {heroSectionFeatureChart && (
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    inset="x3"
                  >
                    <Flex
                      cursor="pointer"
                      onClick={() => {
                        if (typeof window !== 'undefined') {
                          const sponsorQuery = sponsor
                            ? `?sponsor=${sponsor.genealogyId.toString()}`
                            : ''
                          window.open(
                            `https://www.promptings.com/enterprisepacks${sponsorQuery}`,
                            '_blank',
                          )
                        }
                      }}
                    >
                      {/* <Text
                        style={{ textDecoration: 'underline' }}
                        type={isMobile ? 'largeBody' : 'title'}
                        weight="semiBold"
                        color="primaryHeading"
                        content="Need more cards?  Click here for our Enterprise Plans"
                      /> */}
                    </Flex>
                    {heroSectionFeatureChart.title && (
                      <Text
                        alignment="center"
                        type="largeBody"
                        weight="semiBold"
                        color="primaryHeading"
                        content={heroSectionFeatureChart.title}
                      />
                    )}
                    {heroSectionFeatureChart.featureValuesCol1 &&
                      heroSectionFeatureChart.featureValuesCol2 && (
                        <FeatureItemChart
                          columnOne={heroSectionFeatureChart.featureValuesCol1}
                          columnTwo={heroSectionFeatureChart.featureValuesCol2}
                        />
                      )}
                  </Flex>
                )}
                {heroPlanTiles.plantilesection.disclaimer && (
                  <Flex maxWidth="1100px">
                    <Text
                      alignment="center"
                      type="footnote"
                      content={heroPlanTiles.plantilesection.disclaimer}
                    />
                  </Flex>
                )}
              </>
            )}
            {/* ------- Subscription Comparison Chart ----- */}
            {isComparisonChartEnabled && (
              <Flex
                width="100%"
                inset={{
                  top: isMobile ? 'x1' : 'x3',
                }}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                cursor="pointer"
                outset={{ bottom: isMobile ? 'x1' : 'x5' }}
              >
                <Flex
                  cursor="not-allowed"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  outset={{ bottom: 'x5' }}
                >
                  <Flex
                    backgroundColor={
                      shouldShowComparisonChart ? 'transparent' : '#404040'
                    }
                    style={
                      shouldShowComparisonChart
                        ? { border: '2px solid #404040' }
                        : {}
                    }
                    inset={{ horizontal: 'x3', vertical: 'x1_5' }}
                    borderRadius="circle"
                    cursor="pointer"
                    onClick={() =>
                      setShouldShowComparisonChart(!shouldShowComparisonChart)
                    }
                  >
                    <Text
                      alignment="center"
                      content={`${
                        shouldShowComparisonChart ? 'Hide' : 'View'
                      } Comparison Chart`}
                      color={
                        shouldShowComparisonChart
                          ? 'primaryHeading'
                          : 'inverseHeading'
                      }
                      type="body"
                    />
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      style={{
                        transform: shouldShowComparisonChart
                          ? 'rotate(90deg)'
                          : 'rotate(-90deg)',
                      }}
                    >
                      <QIcon
                        size="small"
                        name="chevron"
                        primaryColor={
                          shouldShowComparisonChart
                            ? 'primaryHeading'
                            : 'inverseHeadingText'
                        }
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  overflowX="auto"
                  inset={{
                    bottom: `${shouldShowComparisonChart ? '20px' : 'x0'}`,
                  }}
                  height={shouldShowComparisonChart ? 'auto' : '0px'}
                >
                  <ComparisonChart
                    activePlans={activePlans}
                    tableFeature={Object.keys(tableFeatures) as FeatureType[]}
                    selectedPlanData={selectedPlanData}
                    setSelectedPlanData={setSelectedPlanData}
                  />
                </Flex>
              </Flex>
            )}

            {isPlanLegacy && (
              <>
                <LegacyPlanTile planId={currentPlanId} />
              </>
            )}
          </GlassBackgroundContainer>
        </>
      )}
      {filteredPlanSections?.slice(1).map((section, index) => {
        const promoSectionInsertIndex = 1 // Place Promo section under premium packs section
        const sectionUiBlobs = section.plantilesection?.uiBlobs
          ? JSON.parse(section.plantilesection.uiBlobs)
          : null
        const sectionFeatureChart = section.plantilesection?.featureChart
          ? JSON.parse(section.plantilesection.featureChart)
          : null
        const blobType = sectionUiBlobs
          ? { blobStart: 'center', blobs: sectionUiBlobs }
          : undefined

        //TODO Need to add a field on the backend that allows us to determine if a section should be collapsed
        const isEnterprisePlanTiles = /enterprise/i.test(
          section.plantilesection?.subtitle || '',
        )

        return (
          <React.Fragment key={index}>
            {section.plantilesection && (
              <SectionContainer key={index} blobType={blobType as BlobType}>
                <Flex
                  id={generateSectionId(section.plantilesection.order)}
                  flexDirection="column"
                  alignContent="center"
                  alignItems="center"
                  width="100%"
                >
                  {shouldOpenPlanUpsale && (
                    <PlanUpsale
                      selectedPlanId={selectedPlanData.id}
                      onClose={() => setShouldOpenPlanUpsale(false)}
                      isAddon={!isSubscription}
                    />
                  )}
                  <ContentBlock
                    caption={section.plantilesection.title ?? ''}
                    title={createBoldSentence(section.plantilesection.subtitle)}
                    body={[section.plantilesection.description ?? '']}
                  />
                  <Div outset="x3" width="100%">
                    <Div
                      style={{
                        display:
                          isEnterprisePlanTiles &&
                          !shouldShowEnterprisePlanTiles
                            ? 'none'
                            : 'block',
                      }}
                      width="100%"
                    >
                      <PlanTiles
                        planIds={section.plantilesection.planIds}
                        onSelect={plan => setSelPlan(plan)}
                        onProceedToCheckout={() =>
                          actions.checkoutOpenState(true)
                        }
                        layout="grid"
                      />
                    </Div>
                    {isEnterprisePlanTiles && (
                      <Flex
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          backgroundColorOverride="#404040"
                          onClick={() =>
                            setShouldShowEnterprisePlanTiles(
                              !shouldShowEnterprisePlanTiles,
                            )
                          }
                          title={
                            shouldShowEnterprisePlanTiles
                              ? 'Hide Enterprise Plans'
                              : 'Show Enterprise Plans'
                          }
                        />
                      </Flex>
                    )}
                  </Div>
                  {sectionFeatureChart && (
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      inset="x3"
                    >
                      <Flex
                        outset="x3"
                        rowGap="x1"
                        cursor="pointer"
                        onClick={() => {
                          if (typeof window !== 'undefined') {
                            const sponsorQuery = sponsor
                              ? `?sponsor=${sponsor.genealogyId.toString()}`
                              : ''
                            window.open(
                              `https://www.promptings.com/enterprisepacks${sponsorQuery}`,
                              '_blank',
                            )
                          }
                        }}
                      ></Flex>
                      {sectionFeatureChart.title && (
                        <Text
                          alignment="center"
                          type="largeBody"
                          weight="semiBold"
                          color="primaryHeading"
                          content={sectionFeatureChart.title}
                        />
                      )}
                      {sectionFeatureChart.featureValuesCol1 &&
                        sectionFeatureChart.featureValuesCol2 && (
                          <FeatureItemChart
                            columnOne={sectionFeatureChart.featureValuesCol1}
                            columnTwo={sectionFeatureChart.featureValuesCol2}
                          />
                        )}
                    </Flex>
                  )}
                  {section.plantilesection.disclaimer && (
                    <Flex maxWidth="1100px">
                      <Text
                        alignment="center"
                        type="footnote"
                        content={section.plantilesection.disclaimer}
                      />
                    </Flex>
                  )}
                </Flex>
              </SectionContainer>
            )}
            {promoSectionInsertIndex === index && (
              <Flex
                inset="64px"
                width="100%"
                backgroundColor="#F9FAFB"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                columnGap="x4"
                rowGap="x4"
                style={{
                  boxShadow: 'inset 0 0 32px rgba(18, 18, 18, 0.12)',
                }}
              >
                <Text
                  alignment="center"
                  type="body"
                  content="Or have a sneak peek"
                  style={{
                    fontSize: isTablet ? 32 : 48,
                    fontWeight: '700',
                    lineHeight: 1.2,
                    color: '#404040',
                  }}
                />
                <Text
                  style={{
                    fontSize: isTablet ? 14 : 16,
                    fontWeight: '400',
                    lineHeight: 1.4,
                    color: '#404040',
                  }}
                  alignment="center"
                  type="body"
                  content="These are the perfect way for you to try out the product before committing to a membership or package."
                />
                <Flex
                  flexDirection={shouldStackPromoTile ? 'column' : 'row'}
                  columnGap="x4"
                  rowGap="x4"
                >
                  {!currentPlanId && (
                    <PromoPlanTile
                      type="FreeCard"
                      title="First Card FREE"
                      description="US Stamps Included. (International $1.00)"
                      caption="One time use. Phone number Required"
                      onClick={() => {
                        const baseUrl =
                          'https://app.sendoutcards.com/dashboard/account?postcardPromo&verify'
                        const url = sponsor?.genealogyId
                          ? `${baseUrl}&sponsor=${sponsor.genealogyId}`
                          : baseUrl
                        window.open(url, '_blank', 'noopener,noreferrer')
                      }}
                    />
                  )}
                  {!hasEverHadSamplePack && (
                    <PromoPlanTile
                      type="CardSampler"
                      title="$5 For 3 Cards"
                      description="US Stamps Included. (International $1.00)"
                      caption="Cards good for 7 days from purchase."
                      item={{
                        variantId: 'UHJvZHVjdFZhcmlhbnQ6MjQ4',
                        sku: '15498',
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            )}
          </React.Fragment>
        )
      })}
      {shouldDisplayAffiliateBanner && (
        <SinglePlanTile
          action={{
            title: hasAffiliateSubscription
              ? `Congrats ${AFFILIATE_BRANDING.capitalized}!`
              : 'Join Now',
            onClick: () => console.log('Add product to cart'),
          }}
          isActivated={hasAffiliateSubscription}
        />
      )}
      {filteredGridSections && (
        <FeatureCardGridSection featureGrids={filteredGridSections} />
      )}
      {selPlan && (
        <DialogUpsaleCheckout
          selectedItem={selPlan}
          onClose={() => {
            setSelPlan(undefined)
          }}
        />
      )}
      <ContentBlock
        caption="Create genuine human connection with the"
        title={{ title: 'Store', bolded: 'Add-ons' }}
        body={[
          'Enhance your card sending creation by personalizing your account with your own handwriting font and signatures. ',
        ]}
      />
      <Div
        id="00"
        display="flex"
        width="100%"
        justifyContent="space-between"
        outset={{ top: 'x2', bottom: 'x9' }}
      >
        <AddOnTiles
          planIds={addOnTileIds}
          onPurchaseNow={() => actions.checkoutOpenState(true)}
        />
      </Div>
    </Flex>
  )
}
