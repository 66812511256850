import React from 'react'

import { Flex, Separator, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import { SummaryItem } from './SummaryItem'
import { IconType } from 'src/design_system/atoms/icons/types'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import {
  usePlans,
  useProductVariant,
  useProductVariants,
} from 'src/react_query/queries/hooks'
import { PlanFragment } from 'src/graphql/generated/graphql'

type StepConfirmationProps = {
  packagePlan: PlanFragment
  selectedPlan: PlanFragment
  selectedAddons?: string[]
  onAddToCartAndContinue: (selectedPlanVariantId: string) => void
  onContinue: (selectedPlanVariantId: string) => void
  isMobile: boolean
}

export const StepConfirmation: React.FC<StepConfirmationProps> = ({
  packagePlan,
  selectedPlan,
  selectedAddons,
  onAddToCartAndContinue,
  onContinue,
  isMobile,
}) => {
  const plansQuery = usePlans({ suspense: true })
  const plans = plansQuery.data!
  const planDescriptions = getPlanDescriptions(plans)
  const variantIdOnPlan = planDescriptions[selectedPlan.id]?.saleorVariantId

  const { data } = useProductVariant(
    { sku: selectedPlan.stripeId },
    !!!variantIdOnPlan,
  )

  const { data: addonData } = useProductVariants(
    {
      first: 10,
      ids: selectedAddons,
    },
    selectedAddons && selectedAddons.length > 0,
  )

  const selectedPlanVariantId = variantIdOnPlan ?? data?.id

  return (
    <>
      <Flex inset={{ vertical: 'x2' }}>
        <Flex flexDirection="column" width="100%">
          <Text
            type="caption"
            weight="bold"
            content="Your Package & Subscription:"
            style={{ color: '#D1D5DB' }}
          />
          <Spacer space="x1" />
          <SummaryItem
            title={packagePlan.title}
            icon={packagePlan.icon.toLowerCase() as IconType}
            itemColor={packagePlan.backgroundStartColor}
            price={packagePlan.price.description.split('.')[0]}
            perType="/pkg"
          />
          <Spacer space="x1" />
          {addonData?.pages.map(page =>
            page?.edges.map(edge => {
              const price = edge.node.pricing?.price?.gross?.amount ?? 0
              const formattedPrice = `$${price.toFixed(2)}`
              return (
                <SummaryItem
                  key={edge.node.id}
                  title={edge.node.name}
                  icon={'smile_card_w_heart'}
                  itemColor={'#F075D4'}
                  price={formattedPrice}
                  perType="/trial"
                />
              )
            }),
          )}
          <Spacer space="x2" />
          <Flex
            flexDirection="column"
            borderRadius="medium"
            width="100%"
            backgroundColor="rgba(255, 255, 255, 0.84)"
            inset="x2"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="baseline" columnGap="x_5">
                <Text
                  type="caption"
                  weight="extraBold"
                  content="Total Today"
                  style={{ color: '#404040' }}
                />
                <Text
                  type="footnote"
                  weight="bold"
                  content="(Package)"
                  style={{ color: '#D1D5DB', fontSize: '10px' }}
                />
              </Flex>
              <Text
                type="body"
                weight="semiBold"
                content={packagePlan.price.description}
              />
            </Flex>
            <Spacer space="x1" />
            <Separator orientation="horizontal" />
            <Spacer space="x1" />
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="baseline" columnGap="x_5">
                <Text
                  type="caption"
                  weight="extraBold"
                  content="Total Ongoing"
                  style={{ color: '#404040' }}
                />
                <Text
                  type="footnote"
                  weight="bold"
                  content="(Subscription)"
                  style={{ color: '#D1D5DB', fontSize: '10px' }}
                />
              </Flex>
              <Text
                type="body"
                weight="semiBold"
                content={selectedPlan.price.description}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        position="sticky"
        bottom={0}
        rowGap={'x1'}
        flexDirection={isMobile ? 'column' : 'row'}
        inset={{ horizontal: isMobile ? 'x0' : 'x4', bottom: 'x2' }}
        style={{
          position: 'sticky',
          bottom: 0,
          right: isMobile ? 20 : undefined,
          backgroundColor: 'transparent',
          backdropFilter: 'blur(20px)',
          WebkitBackdropFilter: 'blur(20px)',
          overflow: 'hidden',
          width: '100%',
          justifyContent: isMobile ? 'center' : 'flex-end',
          columnGap: '16px',
          paddingTop: '8px',
          marginInline: '2px',
        }}
      >
        {selectedPlanVariantId && (
          <div
            onClick={() => onAddToCartAndContinue(selectedPlanVariantId)}
            style={{ cursor: 'pointer' }}
          >
            <Text
              type="body"
              weight="semiBold"
              style={{
                textDecoration: 'underline',
                fontSize: 12,
                fontWeight: 700,
              }}
              content="Add to Cart & Continue Shopping"
            />
          </div>
        )}
        <Button
          title="Proceed to Checkout"
          backgroundColor="#404040"
          textColor="#FFF"
          textSize="14px"
          onClick={() => {
            if (selectedPlanVariantId) onContinue(selectedPlanVariantId)
          }}
          isDisabled={!selectedPlanVariantId}
        />
      </Flex>
    </>
  )
}
