import {
  Flex,
  IconProps,
  // LoadingSpinner,
  Text,
  Button as QuantumButton,
} from '@sendoutcards/quantum-design-ui'

import { AnimatePresence } from 'framer-motion'
import React, { CSSProperties } from 'react'
import { useState } from 'src/hooks'
import {
  useCheckout,
  useGetPlanMove,
  usePlans,
  useProductVariant,
} from 'src/react_query/queries/hooks'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { Transition } from 'src/chrome'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { Portal } from 'src/portal/portal'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import { PackageBundler } from '../../../packageBundler/PackageBundler'
import { PlanFragment } from 'src/graphql/generated/graphql'
import {
  applyOpacity,
  OptionType,
  SelectableOptions,
} from 'src/upsale/components/pricingTile/components/SelectableOptions'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { usePlanFeatures } from 'src/pricing_page/hooks/usePlanIncludes'
import { usePlanManagement } from 'src/saleor/utils/usePlanManagement'
import { FeatureIncludesList } from './FeatureIncludesList'

type TileProps = {
  onSelectTile?: () => void
  itemId: string
  icon: React.ReactNode
  title: string
  description?: string
  isDescriptionLarge?: boolean
  price?: string
  priceSubtext?: string
  textColor: string
  background: string
  accentColor?: string
  learnMoreAction?: {
    title: string
    onClick: () => void
  }
  pillTitle?: string
  isActive?: boolean
  minWidth?: number
  currentPlanId?: string
  onChangePlan: () => void
  onPurchaseNow: (variantId?: string) => void
  onRemoveFromCart: () => void
  isAdded?: boolean
  isCurrentPlan: boolean
  plan: PlanFragment
  canBundlePlan?: boolean
  hasOptions?: boolean
  customGradientTheme?: string[]
  pillColorOverride?: string
  mainContainerStyle?: CSSProperties | undefined
  headerIconOverride?: IconProps
  hasPremiumSubscription?: boolean
  isPromptingsSeminarButtonOverride?: boolean
  noAuthActionOverride?: () => void // Override the buttons action if no auth user
}

export const Tile = (props: TileProps) => {
  const {
    title,
    price,
    isActive,
    itemId,
    currentPlanId,
    onChangePlan,
    canBundlePlan,
    isCurrentPlan,
    plan,
    hasOptions,
    customGradientTheme,
    pillTitle,
    pillColorOverride,
    isPromptingsSeminarButtonOverride,
    mainContainerStyle,
    noAuthActionOverride,
  } = props

  /** State **/
  const [selectedSku, setSelectedSku] = useState(plan?.stripeId ?? '')
  const [packagePlan, setPackagePlan] = useState<PlanFragment | undefined>()
  const [packageVariantId, setPackageVariantId] = useState<string | undefined>()
  const [isHovered, setIsHovered] = useState(false)

  /** Hooks **/
  // Get plans
  const plansQuery = usePlans({ suspense: true })
  const plans = plansQuery.data!
  const saleorPlanDescriptions = getPlanDescriptions(plans)

  const { planDescriptions } = usePlanManagement([{ id: plan.id }])
  const features = usePlanFeatures(planDescriptions[plan.id])
  // Plan Move
  const shouldNotGetPlanMove = !!!currentPlanId
  const { data: planMoveData } = useGetPlanMove(
    {
      planIdFrom: Number(currentPlanId),
      planIdTo: Number(itemId),
    },
    shouldNotGetPlanMove,
  )

  // Product Variant
  const { data } = useProductVariant({ sku: plan?.stripeId ?? '' })
  const shouldGetKickStarter = !!plan.relatedPackage
  const { data: relatedPlanKickStarter } = useProductVariant(
    { id: plan.relatedPackage?.saleorProductId },
    shouldGetKickStarter,
  )

  // Product Actions
  const {
    addToCart,
    hasCheckoutError,
    checkIsInCart,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
  } = useHandleProductActions(selectedSku)

  // Checkout
  const { data: checkout } = useCheckout()

  const isKickStarterSkuOnCart = checkIsInCart(
    checkout,
    relatedPlanKickStarter?.sku ?? undefined,
  )
  const isPlanSkuOnCart = checkIsInCart(checkout, plan.stripeId)
  const isOnCart = isKickStarterSkuOnCart || isPlanSkuOnCart

  /** VARIABLES **/
  // Business Logic
  const variantIdOnPlan = plan
    ? saleorPlanDescriptions[plan?.id]?.saleorVariantId
    : ''
  const variantId = variantIdOnPlan ?? data?.id

  // State that handles the selected option or defaults to the plan.variantId if no options are selected/present
  const [selectedOptionVariantId, setSelectedOptionVariantId] = useState<
    string | undefined
  >(plan.relatedPackage?.saleorProductId ?? variantId)

  const premiumSubscriptionIds = data?.parsedAttributes?.premiumSubscriptionIds
  const addonVariantIds = data?.parsedAttributes?.affiliateTrial ?? []
  const premiumSubVariantIds =
    premiumSubscriptionIds?.filter(
      (id): id is string => id !== null && id !== undefined,
    ) ?? []
  const isPlanBasic = plan.id === '1'
  const hasNonBasicPlan = currentPlanId && currentPlanId !== '1'
  const isSelectedOptionKickStarter =
    relatedPlanKickStarter &&
    relatedPlanKickStarter.id === selectedOptionVariantId

  const isBundleCheckout = premiumSubVariantIds.length > 0 && canBundlePlan

  // Formatting and Styles
  const newPrimaryActionTitlePrefix =
    planMoveData && planMoveData.planMove.type === 'DOWN'
      ? 'Downgrade Subscription'
      : planMoveData && planMoveData.planMove.type === 'UP'
      ? 'Upgrade Subscription'
      : 'Purchase'

  const defaultGradient = 'linear-gradient(to right, #46C9DF, #55D09E)'

  const gradientStyle = {
    background: customGradientTheme
      ? `linear-gradient(to right, ${customGradientTheme[isHovered ? 1 : 0]}, ${
          customGradientTheme[isHovered ? 0 : 1]
        })`
      : defaultGradient,
    transition: 'background 0.3s ease-in-out',
  }
  const buttonColor = gradientStyle.background

  // const isLoading = isCreatingCheckout || isUpdatingCheckout

  // TODO: Remove/refactor busisness logic out of this tile so that it can re-used in other areas such as Addons/Packages.

  // ------------------------------------- PricingOptionCard Logic ------------------------------------- <=

  /** Helper Functions **/

  const handleStageSelectedOption = (optionId: string) => {
    setSelectedOptionVariantId(optionId) // this should be the addToCart value saleor.variantId
    // check if option is relatedPlanKickstarter set the sku
    const variantSku =
      relatedPlanKickStarter?.id && optionId === relatedPlanKickStarter.id
        ? relatedPlanKickStarter.sku
        : plan.stripeId
    // set the selectedOptionSku to useHandleProductActions
    if (!variantSku) return
    setSelectedSku(variantSku)
  }

  const dynamicTitle = () => (
    <Text
      outset={{
        top: isHovered ? '16px' : '0px',
        bottom: 'x1_5',
        horizontal: isHovered ? '24px' : '0px',
      }}
      type="largeBody"
      weight="bold"
      style={{
        fontSize: '20px',
        color: isHovered ? '#FFF' : '#333',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      {isSelectedOptionKickStarter ? relatedPlanKickStarter.name : title}
    </Text>
  )

  const selectableKickStartPlanOptionItems =
    hasOptions && relatedPlanKickStarter
      ? ([
          {
            title: 'One-time Purchase',
            price: `$${relatedPlanKickStarter?.pricing?.price?.gross?.amount.toFixed(
              2,
            )}`,
            optionId: relatedPlanKickStarter?.id,
            isMonthly: false,
            disabledReason: isKickStarterSkuOnCart && 'added to cart',
          },
          {
            isMonthly: true,
            title: 'Subscribe & Save',
            price: plan.price.description,
            optionId: variantIdOnPlan,
            disabledReason: isCurrentPlan
              ? 'current plan'
              : isPlanSkuOnCart
              ? 'added to cart'
              : undefined,
          },
        ] as OptionType[])
      : undefined

  const formatButtonDisplayState = () => {
    // Determine if the button should be disabled based on cart and plan conditions
    const isDisabledFromKickStarterInCart =
      isKickStarterSkuOnCart &&
      selectedOptionVariantId === relatedPlanKickStarter?.id

    const isDisabledFromPlanInCart =
      isPlanSkuOnCart && selectedOptionVariantId === variantId

    // The button is disabled if the option isn't selected or it's on the current plan
    const isButtonDisabled =
      !isSelectedOptionKickStarter &&
      (isDisabledFromKickStarterInCart ||
        isDisabledFromPlanInCart ||
        isCurrentPlan)
    const isPlanMoveButton =
      planMoveData && selectedSku === planMoveData.planMove.planTo.stripeId

    // Determine the button title
    const btnTitle =
      isPlanMoveButton && !isButtonDisabled
        ? newPrimaryActionTitlePrefix
        : isPlanBasic
        ? 'Sign up as Basic'
        : isBundleCheckout && canBundlePlan
        ? 'Configure Package'
        : isButtonDisabled
        ? currentPlanId && !isSelectedOptionKickStarter
          ? 'Current Plan'
          : isOnCart && !currentPlanId && !isSelectedOptionKickStarter
          ? 'Added to Cart'
          : 'Add to Cart'
        : 'Add to Cart'

    // Check if the "Purchase Now" button should be shown
    const shouldShowPurchaseNow =
      !isButtonDisabled &&
      !isOnCart &&
      selectedOptionVariantId &&
      !isCurrentPlan
    const handleOnPurchaseNow = () =>
      console.log('Purchase Now Fired', selectedOptionVariantId)
    const onClickHandler = isButtonDisabled
      ? undefined
      : () => {
          if (noAuthActionOverride) {
            noAuthActionOverride() // This will create the sparse user and create a checkout if there is no user
          } else if (isPromptingsSeminarButtonOverride) {
            window.open('https://promptingsseminar.com/')
          } else if (isBundleCheckout) {
            setPackagePlan(plan)
            setPackageVariantId(variantId)
          } else if (hasNonBasicPlan && !isSelectedOptionKickStarter) {
            onChangePlan()
          } else {
            addToCart(selectedOptionVariantId)
          }
        }

    return {
      btnTitle,
      isDisabled: isButtonDisabled,
      onClick: onClickHandler,
      shouldShowPurchaseNow,
      purchaseNowOnClick: shouldShowPurchaseNow ? handleOnPurchaseNow : null,
    }
  }

  return (
    <Flex>
      <Portal>
        {(isUpdatingCheckout || isCreatingCheckout) && (
          <Transition message={'Creating checkout...'} />
        )}
      </Portal>
      {packagePlan && packageVariantId && premiumSubVariantIds && (
        <PackageBundler
          packagePlan={packagePlan}
          packageVariantId={packageVariantId}
          premiumSubVariantIds={premiumSubVariantIds}
          addonVariantIds={addonVariantIds}
          onClose={() => {
            setPackagePlan(undefined)
            setPackageVariantId(undefined)
          }}
          addon={''}
        />
      )}
      {hasCheckoutError && checkoutErrorMessage && (
        <AnimatePresence>
          <ToasterNotification
            backgroundColor={{
              swatch: 'danger',
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: 'information',
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: checkoutErrorMessage,
            }}
          />
        </AnimatePresence>
      )}
      <div
        style={{
          ...mainContainerStyle,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          borderRadius: '20px',
          width: '300px',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: isHovered
            ? customGradientTheme
              ? `0 0 0 4px ${applyOpacity(customGradientTheme[1], 0.2)}`
              : `0 0 0 4px ${applyOpacity('#46C9DF', 0.3)}`
            : '0px 1px 3px rgba(0, 0, 0, 0.1)',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Flex
          style={{
            ...gradientStyle,
            transition: 'all 0.3s ease-in-out',
            flex: 1,
          }}
          borderRadius="inherit"
          flexDirection="column"
          overflow="hidden"
        >
          {/* ------------------------------------------ *  Header * ------------------------------------------ */}
          <Flex
            inset={{ horizontal: 'x3', vertical: 'x2' }}
            justifyContent="space-between"
            width="100%"
          >
            <Flex justifyContent="center" alignItems="center">
              <Icon name={'envelopes'} />
            </Flex>
            <Flex
              id="header-pill"
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: pillColorOverride
                  ? pillColorOverride
                  : customGradientTheme
                  ? isHovered
                    ? applyOpacity(customGradientTheme[1], 0.5)
                    : applyOpacity(customGradientTheme[0], 0.5)
                  : 'rgba(116, 216, 190, 0.8)',
                animation:
                  selectedOptionVariantId === 'monthly'
                    ? 'flashing 1s infinite'
                    : 'none',
              }}
              inset={{ horizontal: 'x2', vertical: 'x_5' }}
              borderRadius="circle"
            >
              <Text style={{ color: '#fff' }} weight="bold" type="footnote">
                {isSelectedOptionKickStarter ? '30 Day Access' : pillTitle}
              </Text>
            </Flex>
          </Flex>
          {isHovered && dynamicTitle()}
          <Flex
            backgroundColor="#fff"
            borderRadius={{ top: '20px', bottom: 'initial' }}
            flexGrow={1}
            flexDirection="column"
            inset={{ horizontal: '24px', top: '12px', bottom: '0' }}
            style={{ border: '2px solid #fff' }}
          >
            {!isHovered && dynamicTitle()}
            {!relatedPlanKickStarter && !isPlanBasic && (
              <Text
                type={'title'}
                style={{
                  fontSize: '16px',
                  color: '#6B7280',
                  fontWeight: 600,
                }}
                content={price}
              />
            )}
            <FeatureIncludesList features={features} />
            {/* ------------------------------------------ * Selectable pricing option * ------------------------------------------ */}
            {selectableKickStartPlanOptionItems && (
              <SelectableOptions
                customGradient={customGradientTheme}
                options={selectableKickStartPlanOptionItems}
                selectedOption={selectedOptionVariantId}
                onSelectOption={(optionId: string) => {
                  handleStageSelectedOption(optionId)
                }}
              />
            )}
            {premiumSubscriptionIds && (
              <Flex
                flexDirection="column"
                justifyContent={'center'}
                alignItems="center"
                cursor={'pointer'}
              >
                <Flex
                  width="95%"
                  justifyContent="center"
                  height="2px"
                  outset={{ bottom: '12px', top: '6px' }}
                  backgroundColor="#E7E9EA"
                  borderRadius="12px"
                />
                <Flex
                  width="100%"
                  justifyContent="center"
                  height="36px"
                  inset="8px"
                  backgroundColor={isActive ? '#fff' : '#FCF7EA'}
                  borderRadius="28px"
                  alignItems="center"
                >
                  <Icon name="award" size={18} color="#cf9220" />
                  <Text
                    weight="semiBold"
                    outset={{ left: 'x_5' }}
                    type={'body'}
                    content="Premium Subscriptions Available"
                    style={{
                      fontWeight: '600px',
                      fontSize: '12px',
                      color: '#303030',
                    }}
                  />
                </Flex>
              </Flex>
            )}
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              flexDirection="column"
              outset={{ top: 'x3' }}
              style={{ marginTop: 'auto' }}
            >
              <Flex outset={{ vertical: 'x2' }} width="100%">
                <QuantumButton
                  size="small"
                  backgroundColorOverride={buttonColor}
                  fullWidth
                  disabled={formatButtonDisplayState().isDisabled}
                  onClick={
                    isPromptingsSeminarButtonOverride
                      ? () => {
                          window.open('https://promptingsseminar.com/')
                        }
                      : formatButtonDisplayState().onClick
                  }
                >
                  <Text
                    lineHeight={1}
                    content={
                      isPromptingsSeminarButtonOverride
                        ? 'Purchase Seminar'
                        : formatButtonDisplayState().btnTitle
                    }
                    style={{ color: '#fff', fontSize: '12px', fontWeight: 700 }}
                    type={'body'}
                  />
                </QuantumButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </Flex>
  )
}
