import React from 'react'
import { Div, Flex, LoadingSpinner } from '@sendoutcards/quantum-design-ui'
import { WidgetCard } from 'src/dashboard/components/Widgets/WidgetCard'
import {
  WidgetHeader,
  WidgetHeaderProps,
} from 'src/dashboard/components/Widgets/WidgetHeader'
import {
  EmptyWidget,
  EmptyWidgetProps,
} from 'src/dashboard/components/Widgets/EmptyWidget'

type WidgetProps = {
  header?: WidgetHeaderProps
  emptyState: EmptyWidgetProps
  isEmpty: boolean
  isLoading?: boolean
  paddingOverride?: string
  shouldHideShadow?: boolean
  backgroundColor?: string
  alignment?: 'stretch' | 'start' | 'center'
  minWidth?: string
}

export const Widget: React.FC<WidgetProps> = ({
  header,
  emptyState,
  isEmpty,
  isLoading,
  paddingOverride,
  shouldHideShadow,
  backgroundColor,
  children,
  alignment = 'center',
  minWidth,
}) => {
  return (
    <WidgetCard
      minWidth={minWidth}
      backgroundColor={backgroundColor}
      shouldHideShadow={shouldHideShadow}
      paddingOverride={paddingOverride}
      alignment={alignment}
    >
      {header && (
        <WidgetHeader
          title={header.title}
          tooltip={header.tooltip}
          action={header.action}
        />
      )}

      {isLoading ? (
        <Div style={{ margin: 'auto 0' }}>
          <LoadingSpinner size="large" />
        </Div>
      ) : isEmpty ? (
        <EmptyWidget
          contentMaxWidth={emptyState.contentMaxWidth}
          icon={emptyState.icon}
          title={emptyState.title}
          description={emptyState.description}
          action={emptyState.action}
        />
      ) : (
        <Flex>{children}</Flex>
      )}
    </WidgetCard>
  )
}
