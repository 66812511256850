import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { FeatureType } from 'src/pricing_page/hooks/usePlanIncludes'
import { IconType } from 'src/design_system/atoms/icons/types'

type FeatureIncludesListProps = {
  features: FeatureType[]
  includedIconColor?: string
  disabledColor?: string
  textColorOverride?: string
}

export const FeatureIncludesList = ({
  features,
  includedIconColor = '#059669',
  disabledColor = '#D1D5DB',
  textColorOverride,
}: FeatureIncludesListProps) => {
  return (
    <Flex flexDirection="column" rowGap="12px" width="100%">
      {features.map((feature, index) => {
        const isNotIncludedFeature = !feature.isIncluded
        const icon = {
          size: isNotIncludedFeature ? 12 : 16,
          name: isNotIncludedFeature ? 'x' : 'check',
          fill: isNotIncludedFeature ? disabledColor : includedIconColor,
        }
        const textColor = textColorOverride
          ? textColorOverride
          : isNotIncludedFeature
          ? disabledColor
          : '#4b5563'
        return (
          <Flex key={index} alignItems="center" justifyContent="flex-start">
            <Icon
              size={icon.size}
              name={icon.name as IconType}
              color={icon.fill}
            />
            <Text
              style={{
                fontSize: '12px',
                color: textColor,
                lineHeight: '1.25rem',
                fontWeight: 500,
              }}
              outset={{ left: 'x1' }}
              type={'title'}
            >
              {feature.feature}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
