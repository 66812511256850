import {
  Button,
  Div,
  Flex,
  LoadingSpinner,
  Separator,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useGetCurrentWeekDays } from 'src/dashboard/hooks/useCurrentWeekDays'
import { ProgressBar } from 'src/design_system/components/ProgressBar/ProgressBar'
import { SentCardSendType } from 'src/graphql/generated/graphql'

import { useSentCardsCount } from 'src/react_query'

export const SendingMetricsWidget = () => {
  const currentWeek = useGetCurrentWeekDays()
  // Ensure the hook useQueries is called unconditionally
  const startDate = currentWeek.length > 0 ? currentWeek[0].isoDate : ''
  const endDate = currentWeek.length > 0 ? currentWeek[6].isoDate : ''

  const { data, isLoading } = useSentCardsCount(
    { startDate, endDate, sendType: SentCardSendType.Heartfelt },
    { enabled: startDate && endDate ? true : false },
  )

  // Early return if currentWeek is not populated yet
  if (currentWeek.length === 0) {
    return null // or a loading indicator
  }

  const resolveCardNumbers = (day: string): number => {
    const sentCard = data?.sentCards.sentCards.find(dateInfo => {
      // Normalize both dates to ISO string format
      const parsedDate1 = new Date(dateInfo.date).toISOString().split('T')[0]
      const parsedDate2 = new Date(day).toISOString().split('T')[0]
      return parsedDate1 === parsedDate2
    })
    return sentCard ? sentCard.cardsSent : 0
  }

  const weekTotals = data?.sentCards.count ?? 0
  const currentDay = currentWeek.find(day => day.isToday)
  const todayTotals = currentDay ? resolveCardNumbers(currentDay.isoDate) : 0

  return (
    <Flex
      inset="16px"
      width="100%"
      backgroundColor="#fff"
      borderRadius="24px"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Text
        type="body"
        content="Weekly Heartfelt Cards Sent"
        style={{ fontWeight: 600, fontSize: '16px', color: '#404040' }}
      />
      <Flex width="100%">
        {isLoading ? (
          <Div style={{ margin: 'auto 0' }}>
            <LoadingSpinner size="large" />
          </Div>
        ) : (
          <Div
            display="flex"
            width="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-evenly"
            height="100%"
          >
            <Flex
              outset={{ top: 'x2' }}
              id="chart_container"
              flexDirection="row"
              justifyContent="space-evenly"
              columnGap="x1"
              alignItems="center"
              height="100%"
              width="100%"
              inset={{ horizontal: 'x2' }}
            >
              {currentWeek.map(day => {
                const cardCount = resolveCardNumbers(day.isoDate)
                const cardCap = 10 // TODO: Wire this up to the account subscription card cap
                const percentage = (cardCount / cardCap) * 100
                return (
                  <Flex
                    key={day.dateString}
                    flexDirection="column"
                    height="220px"
                    alignItems="center"
                  >
                    <ProgressBar
                      metricCount={`${cardCount}`}
                      percentage={percentage}
                      isVertical={true}
                      barHeight="42px"
                      barColor="#F3F4F6"
                      fillColor="#bcd8fc"
                      border={day.isToday ? '2.5px solid #bcd8fc' : 'none'}
                    />
                    <Text
                      content={day.shortDayOfWeek}
                      type="caption"
                      weight={day.isToday ? 'bold' : 'regular'}
                      color={day.isToday ? 'primaryHeading' : 'primaryBody'}
                    />
                  </Flex>
                )
              })}
            </Flex>
            <Flex
              id="send_totals"
              alignItems="center"
              width="90%"
              backgroundColor={'#EFF6FF'}
              inset={{ vertical: 'x1' }}
              justifyContent="center"
              outset={{ vertical: 'x2', horizontal: 'x4' }}
              style={{
                borderRadius: 9,
              }}
            >
              <Flex outset={{ right: 'x1' }}>
                <Text
                  type="caption"
                  content="Today Totals:"
                  color="primaryBody"
                  outset={{ right: 'x_75' }}
                />
                <Text
                  type="caption"
                  content={`${todayTotals}`}
                  color="primaryHeading"
                  weight="bold"
                />
              </Flex>
              <Separator orientation="vertical" />
              <Flex outset={{ left: 'x1' }}>
                <Text
                  type="caption"
                  content="Week Totals:"
                  color="primaryBody"
                  outset={{ right: 'x_75' }}
                />
                <Text
                  type="caption"
                  content={`${weekTotals}`}
                  color="primaryHeading"
                  weight="bold"
                />
              </Flex>
            </Flex>
            <Flex width="80%" outset={{ top: 'x1', bottom: 'x1_5' }}>
              <Separator orientation="horizontal" />
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="flex-end"
              width="90%"
              inset={{ vertical: 'x1' }}
            >
              <Button
                backgroundColorOverride="linear-gradient(90deg,#E7F1F7,#FDFAF5)"
                textColorOverride="#404040"
                fullWidth
                onClick={() => {
                  window.location.href = `https://app.sendoutcards.com/catalog`
                }}
              >
                <Text
                  content={'Send a card today'}
                  weight="bold"
                  type="body"
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </Flex>
          </Div>
        )}
      </Flex>
    </Flex>
  )
}
