export const offeredAffiliateStripeSkus = [
  // Set up skus to be use with saleor checkout
  { id: 'GS00017' }, // Monthly affiliate Checkout Sku
  { id: 'GS00018' }, // Yearly Affiliate Checkout Sku
  { id: '15545' }, // 3-month trial Checkout Sku
  // Ongoing subscription sku's
  { id: 'GS00001' }, // Monthly affiliate subscription item
  { id: 'GS00012' }, // Yearly affiliate subscription item
  { id: 'GS00016' }, // Trial affiliate (90day)subscription item
  { id: '14757' }, // Legacy SOC affiliate subscription item
]

export const activeSaleAffiliateStripeSkus = 'GS00017'
export const basicPlanId = { id: '1' }

export const offeredSubscriptionPlanIds = {
  monthly: { id: '2' },
  monthlyPlus: { id: '161' },
  monthlyPremium: { id: '6' },
  monthlyPremiumPlus: { id: '156' },
}

export const offeredAffiliatePlanIds = {
  annualAffiliate: { id: '165' },
  monthlyAffiliate: { id: '112' },
  legacySocAffiliate: { id: '114' },
  trialAffiliate: { id: '113' },
}

export const defaultSubscriptionIds = [
  offeredSubscriptionPlanIds.monthly,
  offeredSubscriptionPlanIds.monthlyPlus,
]
export const premiereSubscriptionIds = [
  offeredSubscriptionPlanIds.monthlyPremium,
  offeredSubscriptionPlanIds.monthlyPremiumPlus,
]
export const affiliateUpgradePlanIds = [
  offeredAffiliatePlanIds.annualAffiliate,
  offeredAffiliatePlanIds.monthlyAffiliate,
]
// Saleor Variant and Bundle Items
export const affiliateTrialAffiliateAddonVariantId = 'UHJvZHVjdFZhcmlhbnQ6NTEy'
export const gratitudeAffiliateBundleVariantIds = [
  'UHJvZHVjdFZhcmlhbnQ6NTA1', // 99 Yearly Affiliate
  'UHJvZHVjdFZhcmlhbnQ6MTk1', // 37 monthly sub +
]
