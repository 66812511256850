import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Button } from 'src/design_system/components/Button/Button'
import { useAccount, useContainerWidth } from 'src/hooks'

type GratitudeMovementWidgetType = {
  title: string
  isMobile?: boolean
  isTablet?: boolean
}

export const GratitudeMovementWidget: React.FC<GratitudeMovementWidgetType> = ({
  title,
  isMobile,
  isTablet,
}) => {
  const [containerRef] = useContainerWidth()

  const account = useAccount()

  return (
    <Flex
      width="100%"
      flexDirection="column"
      backgroundColor="#fff"
      alignItems="center"
      borderRadius="20px"
      minWidth="220px"
      height="inherit"
      inset="x2"
    >
      <Div
        ref={containerRef}
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
      >
        <Text
          type="caption"
          content={title}
          style={{ fontSize: '16px', fontWeight: 600, color: '#404040' }}
          alignment="left"
        />
      </Div>
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        height="100%"
        outset={{ vertical: 'x2' }}
      >
        <Flex
          inset={{ horizontal: '44px' }}
          style={{
            backgroundRepeat: 'no-repeat',
            width: '95%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
            minHeight: '200px',
            backgroundImage:
              'url(https://www.sendoutcards.com/static/soc-website/images/GratitudeWorld-square.png)',
            position: 'relative',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: 8,
          }}
        />
      </Flex>
      <Flex alignItems="flex-end" width="100%" inset={{ vertical: 'x1' }}>
        <Button
          title={'Go To Presentation'}
          background="linear-gradient(90deg,#E7F1F7,#FDFAF5)"
          textColor="#404040"
          textSize={'14px'}
          width="100%"
          whiteSpace="nowrap"
          onClick={() =>
            window.open(
              `https://webinar.promptings.com/${account.genealogyId}`,
              '_blank',
            )
          }
        />
      </Flex>
    </Flex>
  )
}
