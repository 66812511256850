import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { getIconFromPlan } from '../pricingTile/getPlanIcon'
import { useSelector } from 'src/hooks'
import { usePlans } from 'src/react_query'
import getRecurrence from 'src/helpers/getRecurrence'
import { usePlanFeatures } from 'src/pricing_page/hooks/usePlanIncludes'
import { usePlanManagement } from 'src/saleor/utils/usePlanManagement'
import { FeatureIncludesList } from '../pricingTile/components/Tile/FeatureIncludesList'

type LegacyPlanType = {
  planId?: string
  maxWidth?: string
  minWidth?: string
}

export const LegacyPlanTile: FC<LegacyPlanType> = ({
  planId,
  minWidth = '60%',
  maxWidth = '775px',
}) => {
  const isTablet = useSelector(state => state.window.width <= 860)
  const plansQuery = usePlans({ suspense: true })
  const plans = plansQuery.data!
  const plan = plans.find(plan => plan.id === planId)
  const { planDescriptions } = usePlanManagement([{ id: planId ?? '1' }])
  const features = usePlanFeatures(planDescriptions[planId ?? '1'])

  if (!plan) return <></>
  return (
    <Flex
      flexDirection="column"
      backgroundColor="foreground"
      borderRadius={'medium'}
      style={{
        borderTop: `4px solid ${plan.backgroundStartColor}`,
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
      }}
      inset={{ horizontal: 'x4', vertical: 'x3' }}
      justifyContent="center"
      alignItems="flex-start"
      minWidth={isTablet ? '225px' : minWidth}
      maxWidth={isTablet ? '350px' : maxWidth}
      flexWrap="wrap"
    >
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isTablet ? 'center' : 'flex-start'}
        width="100%"
        flexWrap={isTablet ? 'wrap' : 'nowrap'}
      >
        <Flex
          alignItems="center"
          width="100%"
          rowGap="x1"
          columnGap="x1"
          order={isTablet ? 2 : 1}
        >
          {getIconFromPlan(plan, false)}
          <Text
            weight="bold"
            content="Your Current Subscription"
            type="body"
            style={{ color: plan.backgroundStartColor }}
          />
        </Flex>

        <Div
          order={isTablet ? 1 : 2}
          backgroundColor="#fff"
          borderRadius="circle"
          boxShadow="light"
          height="fit-content"
          inset={{ horizontal: 'x1_5' }}
          outset={{ bottom: 'auto' }}
          style={{ marginLeft: isTablet ? 0 : 'auto' }}
          minWidth="fit-content"
        >
          <Text
            type="body"
            content={'legacy plan'}
            lineHeight={2}
            style={{
              fontSize: '.625rem',
              fontWeight: 700,
              color: plan.backgroundStartColor,
            }}
          />
        </Div>
      </Flex>
      <Spacer space="x1" />
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        rowGap="x3"
        columnGap="x3"
        alignItems="center"
        flexWrap="wrap"
      >
        <Flex flexDirection="column">
          <Text content={plan.title} type="subtitle" weight="bold" />
          <Spacer space="x1" />
          <Text
            content={`$${(plan.price.amount / 100).toString()}${getRecurrence(
              plan.id,
              plan.isAddon,
            )}`}
            type="title"
            color="primaryBody"
            weight="semiBold"
          />
        </Flex>
        <Spacer space="x3" orientation="horizontal" />
        {features.length > 0 && (
          <FeatureIncludesList
            features={features}
            includedIconColor={plan.color ?? '#404040'}
          />
        )}
      </Flex>
    </Flex>
  )
}
