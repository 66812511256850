import React from 'react'
import {
  Flex,
  Text,
  Div,
  LoadingSpinner,
} from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import { useState } from 'src/hooks'
import { useProductVariants } from 'src/react_query/queries/hooks'
import { SelectionCard } from 'src/design_system/components/SelectionCard/SelectionCard'
import { ProductVariantFragment } from 'src/saleor_graphql/generated/graphql'

type StepAddOnProps = {
  addonVariantIds?: string[]
  isMobile: boolean
  onBack: () => void
  onSkip: () => void
  onContinue: (selectedAddons: string[]) => void
}

export const StepAddOn: React.FC<StepAddOnProps> = ({
  addonVariantIds = [],
  isMobile,
  onBack,
  onSkip,
  onContinue,
}) => {
  const [selectedAddons, setSelectedAddons] = useState<string[]>([])

  const { data: addonsQueryData } = useProductVariants(
    { first: 10, ids: addonVariantIds },
    addonVariantIds.length > 0,
  )

  const addons: ProductVariantFragment[] =
    addonsQueryData?.pages
      ?.flatMap(page => page?.edges.map(edge => edge.node))
      .filter((addon): addon is ProductVariantFragment => Boolean(addon)) ?? []

  const toggleAddonSelection = (addonId: string) => {
    setSelectedAddons(prev =>
      prev.includes(addonId)
        ? prev.filter(id => id !== addonId)
        : [...prev, addonId],
    )
  }

  return (
    <>
      <Flex inset={{ horizontal: '2px', vertical: 'x2' }}>
        <Flex flexDirection="column" width="100%">
          <Text type="body" />
          <Text
            style={{ fontWeight: 500, fontSize: '14px', color: '#333' }}
            alignment="left"
            type="body"
            content="Exclusive Offer:"
          />
          {addons.length > 0 ? (
            <Flex
              flexDirection="column"
              width="100%"
              rowGap="x1"
              inset={{ vertical: 'x1' }}
            >
              {addons.map(addon => (
                <Div
                  width="100%"
                  backgroundColor="#EDE8F2"
                  inset="12px"
                  borderRadius="16px"
                  key={addon.id}
                >
                  <SelectionCard
                    title={addon.name}
                    price={`$${addon.pricing?.price?.gross.amount.toFixed(2)}`}
                    pill={`$${addon.pricing?.discount?.gross.amount} value`}
                    hasSelect={true}
                    isSelected={selectedAddons.includes(addon.id)}
                    onSelect={() => toggleAddonSelection(addon.id)}
                    occurrence="mo"
                  />
                </Div>
              ))}
            </Flex>
          ) : addonsQueryData ? (
            <Text type="body" content="No add-ons available." />
          ) : (
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="center"
              inset="x6"
            >
              <LoadingSpinner size="xLarge" />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        position="sticky"
        bottom={0}
        rowGap={'x1'}
        flexDirection={isMobile ? 'column' : 'row'}
        inset={{ horizontal: isMobile ? 'x0' : 'x4', bottom: 'x2' }}
        style={{
          position: 'sticky',
          bottom: 0,
          right: isMobile ? 20 : undefined,
          backgroundColor: 'transparent',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          overflow: 'hidden',
          width: '100%',
          justifyContent: isMobile ? 'center' : 'flex-end',
          columnGap: '16px',
          paddingTop: '8px',
          marginInline: '2px',
        }}
      >
        <div onClick={onSkip} style={{ cursor: 'pointer' }}>
          <Text
            type="body"
            weight="semiBold"
            content="Skip this step"
            style={{
              textDecoration: 'underline',
              fontSize: 12,
              fontWeight: 700,
            }}
          />
        </div>
        <Button
          backgroundColor="#404040"
          title="Yes, I confirm"
          onClick={() => onContinue(selectedAddons)}
          isDisabled={selectedAddons.length === 0}
        />
      </Flex>
    </>
  )
}
