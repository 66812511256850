import React, { FC } from 'react'
import { SVGProps } from './types'

export const Chevron: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      transform="matrix(-1,0,0,1,0,0)"
      onClick={onClick}
    >
      <path
        d="M9.293 18.707a1 1 0 0 1 0-1.414L14.586 12 9.293 6.707a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0z"
        fill={color ?? '#0D0D0D'}
      />
    </svg>
  )
}
